import React, { Component } from "react";
import NavLogo from "../NavBar/NavLogo";
import { ReactComponent as LocationIcon } from "../../svgs/location.svg";
import { ReactComponent as PhoneIcon } from "../../svgs/phone.svg";
import { ReactComponent as EmailIcon } from "../../svgs/email.svg";
import { ReactComponent as FacebookIcon } from "../../svgs/facebook.svg";
import { ReactComponent as InstagramIcon } from "../../svgs/instagram.svg";
import { ReactComponent as LinkedinIcon } from "../../svgs/linkedin.svg";
import "./Footer.scss";

class Footer extends Component {
  render() {
    return (
      <footer
        id="footer"
        className={`${this.props.darkMode ? "dark-mode" : ""}`}
      >
        <div className="container">
          <div className="left-container">
            <a
              className="footer-link"
              href="https://www.google.com/maps?q=33.8437865,35.9087367&z=17&hl=en"
              target="_blank"
            >
              <div className="footer-icon">
                <LocationIcon />
              </div>
              <span className="footer-text">
                Fatouch Center-3rd Floor Zahle, Lebanon
              </span>
            </a>

            <a
              className="footer-link"
              href="https://wa.me/9613034767"
              target="_blank"
            >
              <div className="footer-icon">
                <i class="fa fa-whatsapp" aria-hidden="true" id="iconwhats"></i>
              </div>
              <span className="footer-text">+961 3 034767</span>
            </a>

            <a className="footer-link" href="tel:9613034767" target="_blank">
              <div className="footer-icon">
                <PhoneIcon />
              </div>
              <span className="footer-text">+961 3 034767</span>
            </a>

            <a
              className="footer-link"
              href="mailto:support@btekup.com"
              target="_blank"
            >
              <div className="footer-icon">
                <EmailIcon />
              </div>
              <span className="footer-text">support@btekup.com</span>
            </a>
          </div>
          <div className="middle-container">
            <NavLogo type={this.props.darkMode ? "dark" : "light"} />
            <span className="footer-copyright">
              Copyright &copy; 2021 BTekUp. All Rights reserved
            </span>
          </div>
          <div className="right-container">
            <a
              className="footer-link"
              href="https://www.facebook.com/btekup/"
              target="_blank"
            >
              <div className="footer-icon">
                <FacebookIcon />
              </div>
            </a>
            <a
              className="footer-link"
              href="https://www.instagram.com/btekup/"
              target="_blank"
            >
              <div className="footer-icon">
                <InstagramIcon />
              </div>
            </a>
            <a
              className="footer-link"
              href="https://www.linkedin.com/company/btekup"
              target="_blank"
            >
              <div className="footer-icon">
                <LinkedinIcon />
              </div>
            </a>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
