export const LEADER_CREATE_REQUEST = "LEADER_CREATE_REQUEST";
export const LEADER_CREATE_SUCCESS = "LEADER_CREATE_SUCCESS";
export const LEADER_CREATE_FAIL = "LEADER_CREATE_FAIL";

export const LEADER_UPDATE_REQUEST = "LEADER_UPDATE_REQUEST";
export const LEADER_UPDATE_SUCCESS = "LEADER_UPDATE_SUCCESS";
export const LEADER_UPDATE_FAIL = "LEADER_UPDATE_FAIL";
export const LEADER_UPDATE_RESET = "LEADER_UPDATE_RESET";

export const LEADER_GET_REQUEST = "LEADER_GET_REQUEST";
export const LEADER_GET_SUCCESS = "LEADER_GET_SUCCESS";
export const LEADER_GET_FAIL = "LEADER_GET_FAIL";
export const LEADER_GET_RESET = "LEADER_GET_RESET";
