export const START_YOUR_PROJECT_CREATE_REQUEST =
  "START_YOUR_PROJECT_CREATE_REQUEST";
export const START_YOUR_PROJECT_CREATE_SUCCESS =
  "START_YOUR_PROJECT_CREATE_SUCCESS";
export const START_YOUR_PROJECT_CREATE_FAIL = "START_YOUR_PROJECT_CREATE_FAIL";

export const START_YOUR_PROJECT_LIST_REQUEST =
  "START_YOUR_PROJECT_LIST_REQUEST";
export const START_YOUR_PROJECT_LIST_SUCCESS =
  "START_YOUR_PROJECT_LIST_SUCCESS";
export const START_YOUR_PROJECT_LIST_FAIL = "START_YOUR_PROJECT_LIST_FAIL";

export const START_YOUR_PROJECT_DETAIL_REQUEST =
  "START_YOUR_PROJECT_DETAIL_REQUEST";
export const START_YOUR_PROJECT_DETAIL_SUCCESS =
  "START_YOUR_PROJECT_DETAIL_SUCCESS";
export const START_YOUR_PROJECT_DETAIL_FAIL = "START_YOUR_PROJECT_DETAIL_FAIL";

export const START_YOUR_PROJECT_DELETE_REQUEST =
  "START_YOUR_PROJECT_DELETE_REQUEST";
export const START_YOUR_PROJECT_DELETE_SUCCESS =
  "START_YOUR_PROJECT_DELETE_SUCCESS";
export const START_YOUR_PROJECT_DELETE_FAIL = "START_YOUR_PROJECT_DELETE_FAIL";
