import * as React from "react";
import  Iconn from "./ContactInfo.js";

function Email(props) {
  return (
   
      <Iconn />
    
  );
}

export default Email;
