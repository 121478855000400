import React, { useEffect } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from "@mui/material";
import TableScreen from "./components/TableScreen";
import Message from "./components/Message";
import Loader from "./components/Loader";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import {
  listPortfolio,
  deletePortfolio,
} from "../state/actions/portfolioActions";

const PortfolioScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const portfolioDelete = useSelector((state) => state.portfolioDelete);
  const {
    // loading: loadingPortfolioDelete,
    error: errorPortfolioDelete,
    success: successPortfolioDelete,
  } = portfolioDelete;

  const portfolioList = useSelector((state) => state.portfolioList);
  const {
    loading: loadingPortfolio,
    error: errorPortfolio,
    portfolios,
  } = portfolioList;

  useEffect(() => {
    if (userInfo) {
      dispatch(listPortfolio());
    } else {
      history.push("/admin/login");
    }
  }, [dispatch, history, userInfo, successPortfolioDelete]);

  const createHandler = () => {
    history.push("/admin/portfolio/create");
  };

  const portfolioDeleteHandler = (id) => {
    dispatch(deletePortfolio(id));
  };

  const editHandler = (id) => {
    history.push(`/admin/portfolio/${id}/edit`);
  };

  return (
    <TableScreen>
      <Box my={5}>
        <Button variant="contained" onClick={createHandler}>
          Create a new portfolio
        </Button>
      </Box>
      <TableContainer component={Paper} sx={{ mb: 7 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Title</TableCell>
              {/* <TableCell>Description</TableCell> */}
              {/* <TableCell>Images</TableCell> */}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          {loadingPortfolio ? (
            <Loader />
          ) : errorPortfolio ? (
            <Message>{errorPortfolio}</Message>
          ) : (
            <TableBody>
              {portfolios.map((portfolio) => (
                <TableRow key={portfolio._id}>
                  <TableCell>{portfolio.name}</TableCell>
                  <TableCell>
                    {portfolio.category.map((c) => (
                      <span key={c.name}>{`${c.name}`} </span>
                    ))}
                  </TableCell>
                  <TableCell>{portfolio.title}</TableCell>
                  {/* <TableCell>{`${portfolio.description}...`}</TableCell> */}
                  {/* <TableCell>
                    {portfolio.images.map((image) => (
                      <Link href={image} underline="hover" key={image}>
                        {image} <br></br>{" "}
                      </Link>
                    ))}
                  </TableCell> */}
                  <TableCell>
                    <Button
                      onClick={() => portfolioDeleteHandler(portfolio._id)}
                      variant="outlined"
                      color="error"
                      sx={{ mr: 1 }}
                    >
                      Delete
                    </Button>
                    <Button
                      variant="outlined"
                      color="info"
                      onClick={() => editHandler(portfolio._id)}
                    >
                      Edit
                    </Button>
                    {errorPortfolioDelete && (
                      <Message>{errorPortfolioDelete}</Message>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </TableScreen>
  );
};

export default PortfolioScreen;
