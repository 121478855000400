import React, { Component } from "react";
import { ReactComponent as MenuIcon } from "../../svgs/menu.svg";

// The menu button on mobile version, sets isMenuVisible to true
class ShowNavBtn extends Component {
  state = {};
  render() {
    return (
      <li
        className="show-nav-btn"
        onClick={() => this.props.setIsMenuVisible(true)}
      >
        <MenuIcon />
      </li>
    );
  }
}

export default ShowNavBtn;
