import React, { useState, useRef, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import Footer from "../../components/Footer/Footer";
import Home from "../../components/Home/Home";
import JoinOurTeam from "../../components/JoinOurTeam/JoinOurTeam";
import Portfolio from "../../components/Portfolio/Portfolio";
import Sectors from "../../components/Sectors/Sectors";
import ContactUs from "../../components/SendMessage/SendMessage";
import Skills from "../../components/Skills/Skills";
import Whoweare from "../../components/WhoWeAre/Whoweare";
import NewMobileNavBar from "../components/NewMobileNavBar/NewMobileNavBar";
import NewNavBar from "../components/NewNavBar/NewNavBar";
import NewStickyNavBar from "../components/NewStickyNavBar/NewStickyNavBar";
import { useLocation } from "react-router-dom";
import Loading from "../../components/Loading/Loading";
import Whatwedoo from "../../components/WhatWeDo/WhatWeDo";

const HomePage = () => {
  const [darkMode, setDarkMode] = useState(false);
  const [visibleForm, setVisibleForm] = useState("Send a Message");
  const [isMenu, setIsMenu] = useState(false);
  const stickyNavBarRef = useRef(null);

  const [active, setActive] = useState(false);

  // const { search } = useLocation();
  // const searchParams = new URLSearchParams(search);
  const location = useLocation();

  const myRef = useRef(null);

  const setIsMenuVisible = (isVisible) => {
    setIsMenu(isVisible);
  };

  const toggleDarkMode = () => {
    setDarkMode((prevState) => !prevState);
  };

  useEffect(() => {
    document.body.style.backgroundColor = darkMode ? "#323b3f" : "#fff";
    const changeBackground = () => {
      if (window.scrollY >= 80) {
        setActive(true);
      } else {
        setActive(false);
      }
    };
    window.addEventListener("scroll", changeBackground);

    // if (searchParams.get("section")) {
    //   const element = document.getElementById(searchParams.get("section"));
    //   if (element) {
    //     element.scrollIntoView({ behavior: "smooth" });
    //   }
    // }

    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    }

    return () => {
      window.removeEventListener("scroll", changeBackground);
    };
  }, [darkMode, location]);

  return (
    <>
      <Helmet>
        <title>BtekUp</title>
        <meta
          name="description"
          content={
            "btekup is a technology, communication and multimedia agency. providing our clients with solutions that are 100% optimizable, scalable and measurable to meet their technological needs."
          }
        />
        <link rel="canonical" href={"/"} />
        <meta property="og:title" content="BtekUp" />
        <meta property="og:url" content="https://www.btekup.com/" />
      </Helmet>

      {!location.hash && <Loading />}
      <NewNavBar
        darkMode={darkMode}
        toggleDarkMode={toggleDarkMode}
        setIsMenuVisible={setIsMenuVisible}
      />
      {active && (
        <NewStickyNavBar
          darkMode={darkMode}
          toggleDarkMode={toggleDarkMode}
          setIsMenuVisible={setIsMenuVisible}
          stickyNavBarRef={stickyNavBarRef}
        />
      )}
      <NewMobileNavBar
        darkMode={darkMode}
        setVisibleForm={setVisibleForm}
        isMenuVisible={isMenu}
        setIsMenuVisible={setIsMenuVisible}
      />
      <div id="home">
        <Home darkMode={darkMode} />
      </div>
      <div id="who-we-are">
        <Whoweare darkMode={darkMode} />
      </div>
      <div id="what-we-do">
        <Whatwedoo darkMode={darkMode} />
      </div>
      <div id="skills">
        <Skills darkMode={darkMode} />
      </div>
      <div id="sectors">
        <Sectors darkMode={darkMode} />
      </div>
      <div id="portfolio">
        <Portfolio darkMode={darkMode} />
      </div>
      <div id="send-a-message">
        <ContactUs darkMode={darkMode} form={visibleForm} />
      </div>
      <div id="careers">
        <JoinOurTeam darkMode={darkMode} />
      </div>
      <Footer darkMode={darkMode} />
    </>
  );
};

export default HomePage;
