import React from "react";
import { useHistory } from "react-router";

const Button = ({ title, color }) => {
  const history = useHistory();
  return (
    <button
      className={`carousel-btn ${color === "aqua-dark" ? "aqua-dark" : ""}`}
      onClick={() => {
        if (color === "aqua-dark") {
          // this.props.setCurrentSection("CAREERS");
          history.push("/#careers");
        } else {
          history.push("/#send-a-message");
          // this.props.setCurrentSection("CONTACT US");
          // this.props.setVisibleForm("Start a Project");
        }
      }}
    >
      {title}
    </button>
  );
};

export default Button;
