import React, { useState } from "react";
import { createStartYourPorject } from "../../state/actions/startYourProjectActions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../admin/components/Loader";
import Message from "../../admin/components/Message";

const StartYourProjectForm = () => {
  const dispatch = useDispatch();

  const startYourProjectCreate = useSelector(
    (state) => state.startYourProjectCreate
  );
  const { loading, error, success } = startYourProjectCreate;

  const [formData, setFormData] = useState({
    name: "",
    companyname: "",
    email: "",
    phonenumber: "",
    budget: "",
    projecttype: "",
    timeframe: "",
    howdidyouhear: "",
    Tellusmore: "",
  });

  const handleChange = (event) => {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [event.target.name]: event.target.value,
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(createStartYourPorject(formData));
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        required
        className="col1"
        type="text"
        value={formData.name}
        onChange={handleChange}
        placeholder="FullName"
        name="name"
      />
      <br />
      <input
        required
        className="col1"
        type="text"
        value={formData.companyname}
        onChange={handleChange}
        placeholder="Company Name"
        name="companyname"
      />
      <br />
      <input
        required
        className="col1"
        type="Email"
        value={formData.email}
        onChange={handleChange}
        placeholder="Email"
        name="email"
      />
      <br />
      <input
        required
        className="col1"
        type="number"
        value={formData.phonenumber}
        onChange={handleChange}
        placeholder="Phone Number"
        name="phonenumber"
      />
      <br />
      <input
        required
        className="option"
        type="number"
        value={formData.budget}
        onChange={handleChange}
        placeholder="Budget"
        name="budget"
      />
      <br />
      <select
        required
        className="option"
        value={formData.projecttype}
        onChange={handleChange}
        name="projecttype"
      >
        <option className="option" value="">
          Project Type
        </option>
        <option className="option" value="Web Development">
          Web Development
        </option>
        <option className="option" value="App Development">
          App Development
        </option>
        <option className="option" value="Virtual Reality">
          Virtual Reality
        </option>
        <option className="option" value="Augmented Reality">
          Augmented Reality
        </option>
        <option className="option" value="Design">
          Design
        </option>
        <option className="option" value="Digital Marketing">
          Digital Marketing
        </option>
      </select>
      <br />
      <select
        required
        className="option"
        value={formData.timeframe}
        onChange={handleChange}
        name="timeframe"
      >
        <option className="option" value="">
          Time Frame
        </option>
        <option className="option" value="1-2 Months">
          1-2 Months
        </option>
        <option className="option" value="2-3 Months">
          2-3 Months
        </option>
        <option className="option" value=" 3+ Months">
          3+ Months
        </option>
      </select>
      <br />
      <select
        required
        className="option"
        value={formData.howdidyouhear}
        onChange={handleChange}
        name="howdidyouhear"
      >
        <option className="option" value="">
          How did you hear about us?
        </option>
        <option className="option" value=" Social Media">
          Social Media
        </option>
        <option className="option" value=" Associate">
          Associate
        </option>
        <option className="option" value="Linkedin">
          Linkedin
        </option>
        <option className="option" value="Others">
          Others
        </option>
      </select>
      <br />
      <textarea
        required
        className="textfield"
        type="textbox"
        rows="13"
        cols="50"
        value={formData.Tellusmore}
        onChange={handleChange}
        placeholder="Tell us more about the project..."
        name="Tellusmore"
      />
      <br />
      {loading ? (
        <Loader />
      ) : error ? (
        <Message>{error}</Message>
      ) : success ? (
        <button id="submit" disabled>
          Send
        </button>
      ) : (
        <input id="submit" type="submit" value="Send" />
      )}
    </form>
  );
};

export default StartYourProjectForm;
