import "./Sectors.scss";

import React, { Component } from "react";

class Sectors extends Component {
  render() {
    // console.log(`sectors : ${this.props.darkMode}`);
    return (
      <section
        id={`sectorsection`}
        className={`${this.props.darkMode ? "dark-mode" : ""}`}
      >
        <div id="divsectortitle">
          <span
            id="sectortitle"
            className={`${this.props.darkMode ? "dark-mode" : ""}`}
          >
            SECTORS
          </span>
        </div>
        <br></br>
        <br></br>
        <br></br>
        <div className="secdiv" data-aos-once="true">
          <div className="circle">
            <div className="newdivseccc">
              <i className="fa fa-fw fa-university fa-3x faff "> </i>

              <div className="fff">Banking</div>
            </div>
          </div>

          <div className="circle">
            <center>
              <div className="newdivseccc">
                <i className="fa fa-fw fa-graduation-cap fa-3x faff "> </i>

                <div className="fff">E-Learning</div>
              </div>
            </center>
          </div>

          <div className="circle">
            <center>
              <div className="newdivseccc">
                <i className="fa fa-fw fa-rss fa-3x faff"> </i>

                <div className="fff">Telecom </div>
              </div>
            </center>
          </div>

          <div className="circle">
            <center>
              <div className="newdivseccc">
                <i className="fa fa-fw fa-shopping-cart fa-3x faff"> </i>

                <div className="fff">Consumer Goods</div>
              </div>
            </center>
          </div>
        </div>

        <br></br>
        <div className="secdiv" data-aos="flip-up" data-aos-once="true">
          <div className="circle">
            <center>
              <div className="newdivseccc">
                <i className="fa fa-fw fa-futbol-o fa-3x faff  "> </i>

                <div className="fff">Sports</div>
              </div>
            </center>
          </div>

          <div className="circle">
            <center>
              <div className="newdivseccc">
                <i className="fa fa-fw fa-bus fa-3x faff "> </i>

                <div className="fff">Transportation</div>
              </div>
            </center>
          </div>

          <div className="circle">
            <center>
              <div className="newdivseccc">
                <i className="fa fa-fw fa-cutlery fa-3x faff"> </i>

                <div className="fff">Food</div>
              </div>
            </center>
          </div>

          <div className="circle">
            <center>
              <div className="newdivseccc">
                <i className="fa fa-fw fa-ambulance fa-3x faff"> </i>

                <div className="fff">Healthcare</div>
              </div>
            </center>
          </div>
        </div>
        <br></br>
        <div className="secdiv" data-aos="flip-up" data-aos-once="true">
          <div className="circle">
            <center>
              <div className="newdivseccc">
                <i className="fa fa-fw fa-plane fa-3x faff "> </i>

                <div className="fff">Tourism</div>
              </div>
            </center>
          </div>

          <div className="circle">
            <center>
              <div className="newdivseccc">
                <i className="fa fa-fw fa-home fa-3x faff "> </i>

                <div className="fff">Hospitality</div>
              </div>
            </center>
          </div>

          <div className="circle">
            <center>
              <div className="newdivseccc">
                <i className="fa fa-fw fa-tags fa-3x faff"> </i>

                <div className="fff">Sales</div>
              </div>
            </center>
          </div>

          <div className="circle">
            <center>
              <div className="newdivseccc">
                <i className="fa fa-fw fa-truck fa-3x faff"> </i>

                <div className="fff">Agriculture</div>
              </div>
            </center>
          </div>
        </div>

        <br></br>
        <div className="secdiv" data-aos="flip-up" data-aos-once="true">
          <div className="circle">
            <center>
              <div className="newdivseccc">
                <i className="fa fa-fw fa-barcode fa-3x faff "> </i>

                <div className="fff">Retail</div>
              </div>
            </center>
          </div>

          <div className="circle">
            <center>
              <div className="newdivseccc">
                <i className="fa fa-fw fa-building fa-3x faff "> </i>

                <div className="fff">Real Estate</div>
              </div>
            </center>
          </div>

          <div className="circle">
            <center>
              <div className="newdivseccc">
                <i className="fa fa-fw fa-flash fa-3x faff"> </i>

                <div className="fff">Energy</div>
              </div>
            </center>
          </div>

          <div className="circle">
            <center>
              <div className="newdivseccc">
                <i className="fa fa-fw fa-cart-plus fa-3x faff"> </i>

                <div className="fff">Shops</div>
              </div>
            </center>
          </div>
        </div>
      </section>
    );
  }
}
export default Sectors;
