import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { listContact, deleteContact } from "../state/actions/contactActions";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import TableScreen from "./components/TableScreen";
import Loader from "./components/Loader";
import Message from "./components/Message";

const ContactScreen = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const contactDelete = useSelector((state) => state.contactDelete);
  const { loading: loadingDelete, error: errorDelete, success } = contactDelete;

  const contactList = useSelector((state) => state.contactList);
  const {
    contacts,
    loading: contactLoading,
    error: contactError,
  } = contactList;

  useEffect(() => {
    if (!userInfo) {
      history.push("/admin/login");
    }
    dispatch(listContact());
  }, [history, userInfo, dispatch, success]);

  const deleteHandler = (id) => {
    dispatch(deleteContact(id));
  };

  return (
    <TableScreen>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Message</TableCell>
              <TableCell>Date</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contactLoading ? (
              <Loader />
            ) : contactError ? (
              <Message>{contactError}</Message>
            ) : (
              contacts.map((contact) => (
                <TableRow
                  key={contact._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{`${contact.fname} ${contact.lname}`}</TableCell>
                  <TableCell>{contact.email}</TableCell>
                  <TableCell>{contact.message}</TableCell>
                  <TableCell>{contact.date.substring(0, 25)}</TableCell>
                  <TableCell>
                    {loadingDelete ? (
                      <Loader />
                    ) : errorDelete ? (
                      <Message>{errorDelete}</Message>
                    ) : (
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => deleteHandler(contact._id)}
                      >
                        Delete
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </TableScreen>
  );
};

export default ContactScreen;
