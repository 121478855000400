import React, { useState, useEffect } from "react";
import TableScreen from "./components/TableScreen";
import Message from "./components/Message";
import Loader from "./components/Loader";
import { Button, TextField, Typography, Box } from "@mui/material";
import { useHistory, useParams } from "react-router-dom";
import { getCategoryById, updateCategory } from "../state/actions/blogActions";
import { useDispatch, useSelector } from "react-redux";
import { Image } from "cloudinary-react";
import axios from "axios";
import {
  BLOG_CATEGORY_DETAILS_RESET,
  BLOG_CATEGORY_UPDATE_RESET,
} from "../constants/blogConstants";

const BlogCategoryEditScreen = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  let { id } = useParams();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const blogCategoryDetails = useSelector((state) => state.blogCategoryDetails);
  const { loading, error, category } = blogCategoryDetails;

  const blogCategoryUpdate = useSelector((state) => state.blogCategoryUpdate);
  const {
    success,
    loading: loadingUpdate,
    error: errorUpdate,
  } = blogCategoryUpdate;

  const [name, setName] = useState();
  const [bgImage, setBgImage] = useState("");
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (userInfo) {
      if (success) {
        dispatch({ type: BLOG_CATEGORY_DETAILS_RESET });
        dispatch({ type: BLOG_CATEGORY_UPDATE_RESET });
        history.push("/admin/blog/categories");
      } else {
        if (!category || category._id !== id) {
          dispatch(getCategoryById(id));
        } else {
          setName(category.name);
          setBgImage(category.bgImage);
        }
      }
    } else {
      history.push("/admin/login");
    }
  }, [history, userInfo, id, category, dispatch, success]);

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "pd52bchw");
    setUploading(true);
    try {
      const { data } = await axios.post(
        "https://api.cloudinary.com/v1_1/detmdijki/image/upload",
        formData
      );
      setBgImage(data.url);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(updateCategory({ id, name, bgImage }));
  };

  return (
    <TableScreen>
      <Box display="flex" justifyContent="center">
        <form onSubmit={submitHandler}>
          {loading ? (
            <Loader />
          ) : error ? (
            <Message>{error}</Message>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              height="60vh"
              sx={{
                justifyContent: "space-between",
              }}
            >
              {error && <Message>{error}</Message>}
              <Typography variant="h5">Edit Category</Typography>
              <TextField
                type="text"
                id="outlined-basic"
                variant="outlined"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <Image
                style={{ width: 300 }}
                cloudName="bwiy0n2g"
                publicId={bgImage}
              />
              {uploading ? (
                <Loader />
              ) : (
                <>
                  <Button variant="contained" component="label">
                    Change Image
                    <input
                      type="file"
                      hidden
                      accept="image/*"
                      onChange={uploadFileHandler}
                    />
                  </Button>
                  {!loadingUpdate && (
                    <Button
                      type="submit"
                      size="large"
                      variant="contained"
                      color="primary"
                    >
                      Update
                    </Button>
                  )}
                </>
              )}
              {errorUpdate && <Message>{errorUpdate}</Message>}
            </Box>
          )}
        </form>
      </Box>
    </TableScreen>
  );
};

export default BlogCategoryEditScreen;
