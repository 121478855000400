import axios from "axios";
import {
  JOIN_OUR_TEAM_CREATE_FAIL,
  JOIN_OUR_TEAM_CREATE_REQUEST,
  JOIN_OUR_TEAM_CREATE_SUCCESS,
  JOIN_OUR_TEAM_DELETE_FAIL,
  JOIN_OUR_TEAM_DELETE_REQUEST,
  JOIN_OUR_TEAM_DELETE_SUCCESS,
  JOIN_OUR_TEAM_LIST_FAIL,
  JOIN_OUR_TEAM_LIST_REQUEST,
  JOIN_OUR_TEAM_LIST_SUCCESS,
} from "../../constants/joinOurTeamConstants";

export const createjoinOurTeam =
  (joinOurTeam) => async (dispatch, getState) => {
    try {
      dispatch({ type: JOIN_OUR_TEAM_CREATE_REQUEST });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      await axios.post("https://backend.btekup.com/api/join-our-team", joinOurTeam, config);

      dispatch({ type: JOIN_OUR_TEAM_CREATE_SUCCESS });
    } catch (error) {
      dispatch({
        type: JOIN_OUR_TEAM_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const joinTeamList = () => async (dispatch, getState) => {
  try {
    dispatch({ type: JOIN_OUR_TEAM_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get("https://backend.btekup.com/api/join-our-team", config);

    dispatch({ type: JOIN_OUR_TEAM_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: JOIN_OUR_TEAM_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteJoinTeam = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: JOIN_OUR_TEAM_DELETE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    await axios.delete(`https://backend.btekup.com/api/join-our-team/${id}`, config);

    dispatch({ type: JOIN_OUR_TEAM_DELETE_SUCCESS });
  } catch (error) {
    dispatch({
      type: JOIN_OUR_TEAM_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
