import React, { Component } from "react";
import { CarouselData } from "./CarouselData";
import { ReactComponent as LeftArrow } from "../../svgs/left-bracket.svg";
import { ReactComponent as RightArrow } from "../../svgs/right-bracket.svg";
import { ReactComponent as Up } from "../../svgs/Up.svg";
import Button from "./Button";

const n = 200;

let arr2 = [];
for (let i = 0; i < n; i++) {
  arr2.push(...CarouselData);
}

const length = arr2.length;
class Carousel extends Component {
  state = { currentSlide: 0 };

  prevSlide = () => {
    if (this.state.currentSlide > 0) {
      this.setState({ currentSlide: this.state.currentSlide - 1 });
    } else {
      this.setState({ currentSlide: length - 1 });
    }
  };
  nextSlide = () => {
    if (this.state.currentSlide < length - 1) {
      this.setState({ currentSlide: this.state.currentSlide + 1 });
    } else {
      this.setState({ currentSlide: 0 });
    }
  };
  componentDidMount() {
    this.interval = setInterval(this.nextSlide, 10000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  render() {
    return (
      <div className="carousel">
        <div className="left-arrow" onClick={this.prevSlide}>
          <LeftArrow />
        </div>
        <div className="right-arrow" onClick={this.nextSlide}>
          <RightArrow />
        </div>

        {arr2.map((image, index) => {
          var imageText = null;
          return (
            <div
              key={index}
              className={`carousel-slide 
              ${index < this.state.currentSlide ? "prev-slide" : ""}
              ${index === this.state.currentSlide ? "current-slide" : ""}
              ${index > this.state.currentSlide ? "next-slide" : ""}`}
            >
              <div className="carousel-image">
                <img
                  src={`${process.env.PUBLIC_URL}/images/${image.name}`}
                  alt={image.alt}
                />
                <div className="blur-filter"></div>
              </div>

              {image.index === 1 ? (
                <h1 className="carousel-text">
                  Where You Start
                  <Up className="up" />
                </h1>
              ) : null}

              {image.index === 2 ? (
                <h1 className="carousel-text">
                  Start Devel
                  <Up className="up" />
                  ment
                </h1>
              ) : (
                <h1 className="carousel-text">{image.text}</h1>
              )}
              {imageText}
            </div>
          );
        })}
        <div className="carousel-buttons">
          <Button
            title="Start a Project"
            color="aqua-mid"
            // setCurrentSection={this.props.setCurrentSection}
            // setVisibleForm={this.props.setVisibleForm}
          />
          <Button
            title="Join Our Team"
            color="aqua-dark"
            // setCurrentSection={this.props.setCurrentSection}
            // setVisibleForm={this.props.setVisibleForm}
          />
          {/* <a href="/meet-the-leader" className="meet-leader-link">
            Meet The Leader
          </a> */}
        </div>
      </div>
    );
  }
}

export default Carousel;
