import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import TableScreen from "./components/TableScreen";
import "react-quill/dist/quill.snow.css";
import { Button } from "@mui/material";
import Loader from "./components/Loader";
import Message from "./components/Message";
import { useSelector, useDispatch } from "react-redux";
import { getLeader, updateLeader } from "../state/actions/leaderActions";
import { useHistory } from "react-router-dom";
import { LEADER_UPDATE_RESET } from "../constants/leaderConstants";

export default function LeaderScreen() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [body, setBody] = useState("");

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const leaderEdit = useSelector((state) => state.leaderEdit);
  const { loading, error, success } = leaderEdit;

  const LeaderDetails = useSelector((state) => state.LeaderDetails);
  const { loading: loadingLeader, error: errorLeader, leader } = LeaderDetails;

  const id = "62fd4930f4311f97266f08f3";

  useEffect(() => {
    if (userInfo) {
      dispatch(getLeader("62fd4930f4311f97266f08f3"));
      setBody(leader.body);
      if (success) {
        dispatch({ type: LEADER_UPDATE_RESET });
      }
    } else {
      history.push("/admin/login");
    }
  }, [dispatch, history, userInfo, leader.body, success]);

  const clickHandler = (e) => {
    e.preventDefault();
    dispatch(updateLeader({ id, body }));
  };

  return (
    <TableScreen>
      <form>
        {loadingLeader ? (
          <Loader />
        ) : errorLeader ? (
          <Message>{errorLeader}</Message>
        ) : (
          <>
            <ReactQuill theme="snow" value={body} onChange={setBody} />
            <Button
              type="submit"
              variant="contained"
              onClick={clickHandler}
              sx={{ mt: 3, mb: 3 }}
            >
              Submit
            </Button>
          </>
        )}
      </form>
      {error && <Message>{error}</Message>}
      {loading && <Loader />}
    </TableScreen>
  );
}
