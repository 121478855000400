import axios from "axios";
import {
  LEADER_CREATE_FAIL,
  LEADER_CREATE_REQUEST,
  LEADER_CREATE_SUCCESS,
  LEADER_GET_FAIL,
  LEADER_GET_REQUEST,
  LEADER_GET_SUCCESS,
  LEADER_UPDATE_FAIL,
  LEADER_UPDATE_REQUEST,
  LEADER_UPDATE_SUCCESS,
} from "../../constants/leaderConstants";

export const createLeader = (leader) => async (dispatch, getState) => {
  try {
    dispatch({ type: LEADER_CREATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    await axios.post("https://backend.btekup.com/api/leader", leader, config);

    dispatch({ type: LEADER_CREATE_SUCCESS });
  } catch (error) {
    dispatch({
      type: LEADER_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getLeader = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: LEADER_GET_REQUEST });

    const { data } = await axios.get(`https://backend.btekup.com/api/leader/${id}`);

    dispatch({ type: LEADER_GET_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: LEADER_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateLeader = (leader) => async (dispatch, getState) => {
  try {
    dispatch({ type: LEADER_UPDATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
    };
    await axios.put(`https://backend.btekup.com/api/leader/${leader.id}`, leader, config);

    dispatch({ type: LEADER_UPDATE_SUCCESS });
  } catch (error) {
    dispatch({
      type: LEADER_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
