import React, { Component } from "react";
import "./Skillssection.scss";
import bracket1 from "../../svgs/bracket1.svg";
import Content from "./ContentDiv";
import isInViewPort from "../Functions/isInViewPort";

class Skills extends Component {
  render() {
    return (
      <section
        id="skillsSection"
        className={`${this.props.darkMode ? "dark-mode" : ""}`}
        // ref={
        //   this.props.currentSection === "SKILLS"
        //     ? this.props.currentSectionRef
        //     : ""
        // }
      >
        <div>
          <div className="divskillstitle">
            <span
              id="skillstitle"
              className={`${this.props.darkMode ? "dark-mode" : ""}`}
            >
              SKILLS
            </span>
          </div>

          <div className="Appp">
            <div className="div1">
              <img src={bracket1} alt="brackeet" />
            </div>

            <Content darkMode={this.props.darkMode}></Content>

            <div className="div1">
              <img src={bracket1} alt="bracket" className="img2" />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Skills;
