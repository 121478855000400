
export default function uploadButton() {

    window.onload=function(){

    const realFileBtn=document.getElementById("real-file");
    const customBtn=document.getElementById("upload-button");
    const customTxt=document.getElementById("custom-text");

if(customBtn){
        customBtn.addEventListener("click", function() {
            realFileBtn.click();
        });
        realFileBtn.addEventListener("change", function(){
            if(realFileBtn.value){
                customBtn.innerHTML='Uploaded!';
                customTxt.innerHTML='';
            }
            else{
                customTxt.innerHTML="No file is picked yet";
            }
        });
}
      } 




}





