import "./WhatWeDo.scss";
import WhatWeDid from "./WhatWeDid";
import React, { Component } from "react";

class Whatwedoo extends Component {
  render() {
    return (
      <section
        id="whatwedosection"
        className={`${this.props.darkMode ? "dark-mode" : ""}`}
        ref={
          this.props.currentSection === "WHAT WE DO"
            ? this.props.currentSectionRef
            : ""
        }
      >
        <WhatWeDid />
      </section>
    );
  }
}
export default Whatwedoo;
