import React from "react";
import Layout from "../Layout/Layout";
import { useSelector } from "react-redux";
import Leader from "../Leader";

const MeetLeader = () => {
  const mode = useSelector((state) => state.darkMode);
  const { darkMode } = mode;

  return (
    <Layout>
      <Leader darkMode={darkMode} />
    </Layout>
  );
};

export default MeetLeader;
