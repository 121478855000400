import React from "react";
import BlogSection from "../BlogSection";
import Layout from "../Layout/Layout";
import { useSelector } from "react-redux";

const BlogPage = () => {
  const mode = useSelector((state) => state.darkMode);
  const { darkMode } = mode;

  return (
    <>
      <Layout>
        <BlogSection darkMode={darkMode} />
      </Layout>
    </>
  );
};

export default BlogPage;
