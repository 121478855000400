import {
  LEADER_CREATE_FAIL,
  LEADER_CREATE_REQUEST,
  LEADER_CREATE_SUCCESS,
  LEADER_GET_FAIL,
  LEADER_GET_REQUEST,
  LEADER_GET_RESET,
  LEADER_GET_SUCCESS,
  LEADER_UPDATE_FAIL,
  LEADER_UPDATE_REQUEST,
  LEADER_UPDATE_RESET,
  LEADER_UPDATE_SUCCESS,
} from "../../constants/leaderConstants";

export const leaderCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case LEADER_CREATE_REQUEST:
      return { loading: true };
    case LEADER_CREATE_SUCCESS:
      return { loading: false, success: true };
    case LEADER_CREATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const leaderEditReducer = (state = {}, action) => {
  switch (action.type) {
    case LEADER_UPDATE_REQUEST:
      return { ...state, loading: true };
    case LEADER_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case LEADER_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case LEADER_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const LeaderDetailsReducer = (state = { leader: {} }, action) => {
  switch (action.type) {
    case LEADER_GET_REQUEST:
      return { ...state, loading: true };
    case LEADER_GET_SUCCESS:
      return { loading: false, leader: action.payload };
    case LEADER_GET_FAIL:
      return { loading: false, error: action.payload };
    case LEADER_GET_RESET:
      return { leader: {} };
    default:
      return state;
  }
};
