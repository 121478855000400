import axios from "axios";
import {
  PORTFOLIO_CREATE_FAIL,
  PORTFOLIO_CREATE_REQUEST,
  PORTFOLIO_CREATE_SUCCESS,
  PORTFOLIO_DELETE_FAIL,
  PORTFOLIO_DELETE_REQUEST,
  PORTFOLIO_DELETE_SUCCESS,
  PORTFOLIO_DETAILS_FAIL,
  PORTFOLIO_DETAILS_REQUEST,
  PORTFOLIO_DETAILS_SUCCESS,
  PORTFOLIO_LIST_BY_CATEGORY_FAIL,
  PORTFOLIO_LIST_BY_CATEGORY_REQUEST,
  PORTFOLIO_LIST_BY_CATEGORY_SUCCESS,
  PORTFOLIO_LIST_FAIL,
  PORTFOLIO_LIST_REQUEST,
  PORTFOLIO_LIST_SUCCESS,
  PORTFOLIO_UPDATE_FAIL,
  PORTFOLIO_UPDATE_REQUEST,
  PORTFOLIO_UPDATE_SUCCESS,
} from "../../constants/portfolioConstants";

export const createPortfolio = (portfolio) => async (dispatch, getState) => {
  try {
    dispatch({ type: PORTFOLIO_CREATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    await axios.post(
      "https://backend.btekup.com/api/portfolios",
      portfolio,
      config
    );

    dispatch({ type: PORTFOLIO_CREATE_SUCCESS });
  } catch (error) {
    dispatch({
      type: PORTFOLIO_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listPortfolio = () => async (dispatch, getState) => {
  try {
    dispatch({ type: PORTFOLIO_LIST_REQUEST });

    const { data } = await axios.get(
      "https://backend.btekup.com/api/portfolios"
    );

    dispatch({ type: PORTFOLIO_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PORTFOLIO_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deletePortfolio = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: PORTFOLIO_DELETE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(
      `https://backend.btekup.com/api/portfolios/${id}`,
      config
    );

    dispatch({ type: PORTFOLIO_DELETE_SUCCESS });
  } catch (error) {
    dispatch({
      type: PORTFOLIO_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getPortfolioDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: PORTFOLIO_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `https://backend.btekup.com/api/portfolios/${id}`,
      config
    );

    dispatch({ type: PORTFOLIO_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PORTFOLIO_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updatePortfolio = (portfolio) => async (dispatch, getState) => {
  try {
    dispatch({ type: PORTFOLIO_UPDATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.put(
      `https://backend.btekup.com/api/portfolios/${portfolio.id}`,
      portfolio,
      config
    );

    dispatch({ type: PORTFOLIO_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PORTFOLIO_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listPortfolioByCategory = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: PORTFOLIO_LIST_BY_CATEGORY_REQUEST });

    const { data } = await axios.get(
      `https://backend.btekup.com/api/portfolios/categories/${id}`
    );

    dispatch({ type: PORTFOLIO_LIST_BY_CATEGORY_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PORTFOLIO_LIST_BY_CATEGORY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
