import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  TextField,
  Grid,
  Link,
  TextareaAutosize,
} from "@mui/material";
import TableScreen from "./components/TableScreen";
import { useSelector, useDispatch } from "react-redux";
import {
  listWhatWeDo,
  createWhatWeDo,
  deleteWhatWeDo,
} from "../state/actions/whatWeDoactions";
import Loader from "./components/Loader";
import Message from "./components/Message";
import { WHAT_WE_DO_UPDATE_RESET } from "../constants/whatWeDoConstant";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const WhatWeDoScreen = () => {
  const history = useHistory();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedImage, setSelectedImage] = useState("");

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const whatWeDoCreate = useSelector((state) => state.whatWeDoCreate);
  const { success: successCreate, error: errorCreate } = whatWeDoCreate;

  const whatWeDoList = useSelector((state) => state.whatWeDoList);
  const { whatWeDos, loading, error } = whatWeDoList;

  const whatWeDoDelete = useSelector((state) => state.whatWeDoDelete);
  const { success: successDelete } = whatWeDoDelete;

  useEffect(() => {
    if (userInfo) {
      dispatch(listWhatWeDo());
    } else {
      history.push("/admin/login");
    }
  }, [dispatch, history, userInfo, successDelete, successCreate]);

  const uploadImage = async (img) => {
    const formData = new FormData();
    formData.append("file", img);
    formData.append("upload_preset", "bwiy0n2g");
    const { data } = await axios.post(
      "https://api.cloudinary.com/v1_1/detmdijki/image/upload",
      formData
    );
    return data.url;
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    let image = await uploadImage(selectedImage);
    dispatch(createWhatWeDo({ name, image, description }));
    setName("");
    setDescription("");
    setSelectedImage("");
  };

  const deleteHandler = (id) => {
    dispatch(deleteWhatWeDo(id));
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message>{error}</Message>
      ) : (
        <TableScreen>
          {errorCreate && <Message>{errorCreate}</Message>}
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <form onSubmit={submitHandler}>
                <Box
                  display="flex"
                  flexDirection="column"
                  // justifyContent="space-between"
                  height="70vh"
                >
                  <TextField
                    type="text"
                    variant="outlined"
                    required
                    label="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  {/* <TextareaAutosize
                    aria-label="minimum height"
                    minRows={18}
                    placeholder="Enter Description"
                    value={description}
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                    sx={{ mb: 2 }}
                  /> */}
                  <Box marginY={2}>
                    <ReactQuill
                      theme="snow"
                      value={description}
                      onChange={setDescription}
                    />
                  </Box>
                  <Button variant="contained" component="label" sx={{ mb: 2 }}>
                    UPLOAD IMAGE
                    <input
                      type="file"
                      hidden
                      accept="image/*"
                      onChange={(e) => setSelectedImage(e.target.files[0])}
                    />
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                  >
                    ADD
                  </Button>
                </Box>
              </form>
            </Grid>
            <Grid item xs={10}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Image Link</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {whatWeDos.map((whatWeDo) => (
                      <TableRow key={whatWeDo._id}>
                        <TableCell>{whatWeDo.name}</TableCell>
                        <TableCell>
                          <Link
                            href={whatWeDo.image}
                            underline="hover"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {whatWeDo.image}
                          </Link>
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="outlined"
                            onClick={() => {
                              dispatch({ type: WHAT_WE_DO_UPDATE_RESET });
                              history.push(
                                `/admin/what-we-do/${whatWeDo._id}/edit`
                              );
                            }}
                          >
                            Edit
                          </Button>
                          <Button
                            sx={{ mt: 1 }}
                            variant="outlined"
                            color="error"
                            onClick={() => deleteHandler(whatWeDo._id)}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </TableScreen>
      )}
    </>
  );
};

export default WhatWeDoScreen;
