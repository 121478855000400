import {
  START_YOUR_PROJECT_CREATE_FAIL,
  START_YOUR_PROJECT_CREATE_REQUEST,
  START_YOUR_PROJECT_CREATE_SUCCESS,
  START_YOUR_PROJECT_DELETE_FAIL,
  START_YOUR_PROJECT_DELETE_REQUEST,
  START_YOUR_PROJECT_DELETE_SUCCESS,
  START_YOUR_PROJECT_DETAIL_FAIL,
  START_YOUR_PROJECT_DETAIL_REQUEST,
  START_YOUR_PROJECT_DETAIL_SUCCESS,
  START_YOUR_PROJECT_LIST_FAIL,
  START_YOUR_PROJECT_LIST_REQUEST,
  START_YOUR_PROJECT_LIST_SUCCESS,
} from "../../constants/startYourProjectConstant";

export const startYourProjectCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case START_YOUR_PROJECT_CREATE_REQUEST:
      return { loading: true };
    case START_YOUR_PROJECT_CREATE_SUCCESS:
      return { loading: false, success: true };
    case START_YOUR_PROJECT_CREATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const startYourProjectDelteReducer = (state = {}, action) => {
  switch (action.type) {
    case START_YOUR_PROJECT_DELETE_REQUEST:
      return { loading: true };
    case START_YOUR_PROJECT_DELETE_SUCCESS:
      return { loading: false, success: true };
    case START_YOUR_PROJECT_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const startYourProjectDetailReducer = (
  state = { project: {} },
  action
) => {
  switch (action.type) {
    case START_YOUR_PROJECT_DETAIL_REQUEST:
      return { ...state, loading: true };
    case START_YOUR_PROJECT_DETAIL_SUCCESS:
      return { loading: false, project: action.payload };
    case START_YOUR_PROJECT_DETAIL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const startYourProjectListReducer = (
  state = { projects: [] },
  action
) => {
  switch (action.type) {
    case START_YOUR_PROJECT_LIST_REQUEST:
      return { ...state, loading: true };
    case START_YOUR_PROJECT_LIST_SUCCESS:
      return { loading: false, projects: action.payload };
    case START_YOUR_PROJECT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
