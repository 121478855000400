import React from "react";
import { useHistory } from "react-router-dom";
import { AppBar, Toolbar, Button, Container, Box } from "@mui/material";

const TableScreen = ({ children }) => {
  const history = useHistory();
  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Button
            size="large"
            variant="contained"
            onClick={() => history.goBack()}
          >
            Go Back
          </Button>
          <Button
            sx={{ ml: "auto" }}
            size="large"
            variant="contained"
            onClick={() => history.push("/admin")}
          >
            Admin HomePage
          </Button>
        </Toolbar>
      </AppBar>
      <Container>
        <Box
          sx={{
            mt: 5,
          }}
        >
          {children}
        </Box>
      </Container>
    </>
  );
};

export default TableScreen;
