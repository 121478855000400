import React, { Component } from "react";
import "./Portfoliostyle.scss";
import PortfolioCategory from "./PortfolioCategory";

class Portfolio extends Component {
  render() {
    return (
      <section
        id="PortfolioSection"
        className={`${this.props.darkMode ? "dark-mode" : ""}`}
        // ref={
        //   this.props.currentSection === "PORTFOLIO"
        //     ? this.props.currentSectionRef
        //     : ""
        // }
      >
        <div className="App">
          <div>
            <h2
              className={`${this.props.darkMode ? "dark-mode" : ""}`}
              id="class11"
            >
              PORTFOLIO
            </h2>
          </div>
          <br></br>
          <br></br>
          <PortfolioCategory darkMode={this.props.darkMode} />
        </div>
      </section>
    );
  }
}

export default Portfolio;
