import React, { useEffect } from "react";
import { logout } from "../state/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "./components/Navbar";
import AdminCard from "./components/AdminCard";
import { Box, Container } from "@mui/material";
import Loader from "./components/Loader";
import Message from "./components/Message";

const HomeScreen = ({ history }) => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo, loading, error } = userLogin;

  useEffect(() => {
    if (!userInfo) {
      history.push("/admin/login");
    }
  }, [history, userInfo]);

  const logoutHandler = () => {
    dispatch(logout());
  };
  return (
    <>
      <Navbar logout={logoutHandler} />
      {loading ? (
        <Loader />
      ) : error ? (
        <Message>{error}</Message>
      ) : (
        <Container>
          <Box
            sx={{
              mt: 5,
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <AdminCard title="contact" path="/admin/contact" />
            <AdminCard title="join our team" path="/admin/join-our-team" />
            <AdminCard
              title="start your project"
              path="/admin/start-your-project"
            />
            <AdminCard title="what we do" path="/admin/what-we-do" />
            <AdminCard
              title="portfolio categories"
              path="/admin/portfolio/categories"
            />
            <AdminCard title="portfolio" path="/admin/portfolio" />
            <AdminCard title="Blog" path="/admin/blog" />
            <AdminCard title="Blog categories" path="/admin/blog/categories" />
            <AdminCard title="Author" path="/admin/author" />
            <AdminCard title="Leader" path="/admin/leader" />
          </Box>
        </Container>
      )}
    </>
  );
};

export default HomeScreen;
