import React, { useState } from "react";
import { createContact } from "../../state/actions/contactActions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../admin/components/Loader";
import Message from "../../admin/components/Message";

const Form = () => {
  const dispatch = useDispatch();

  const contactCreate = useSelector((state) => state.contactCreate);
  const { loading, error, success } = contactCreate;

  const [formData, setFormData] = useState({
    fname: "",
    lname: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(createContact(formData));
  };

  return (
    <div id="form-container" className="col-lg-5">
      <form onSubmit={handleSubmit} className="mb-4 mb-lg-0">
        <div className="form-row">
          <div
            className="col-md-6 form-group"
            style={{ width: "45%", marginRight: "10%" }}
          >
            <input
              required
              maxLength="15"
              onChange={handleChange}
              type="text"
              name="fname"
              className="form-control"
              id="Fname"
              placeholder="First Name"
              style={{ height: "60px", marginBottom: "15%" }}
            />
          </div>
          <div className="col-md-6 form-group" style={{ width: "45%" }}>
            <input
              required
              maxLength="15"
              onChange={handleChange}
              type="text"
              className="form-control"
              name="lname"
              id="Lname"
              placeholder="Last Name"
              style={{ height: "60px", marginBottom: "15%" }}
            />
          </div>
        </div>
        <div className="form-group">
          <input
            required
            maxLength="30"
            onChange={handleChange}
            type="email"
            className="form-control"
            name="email"
            id="email"
            placeholder="Email"
            style={{ height: "60px" }}
          />
        </div>
        <div className="form-group">
          <textarea
            required
            maxLength="300"
            onChange={handleChange}
            className="form-control"
            name="message"
            placeholder="Message"
            style={{ marginTop: "30px" }}
          ></textarea>
        </div>
        {loading ? (
          <Loader />
        ) : error ? (
          <Message>{error}</Message>
        ) : success ? (
          <div className="section-title">
            <h2 style={{ fontSize: "25pt" }}>Message succefully sent !</h2>
          </div>
        ) : (
          <button type="submit" id="send-button" className="btn btn-light">
            Send
          </button>
        )}
      </form>
    </div>
  );
};

export default Form;
