import React, { Component } from "react";
import DesWrapper from "./Wrappers/DesWrapper";
import SmWrapper from "./Wrappers/MobWrapper";
import isInViewPort from "../Functions/isInViewPort";
import "../WhoWeAre/Whoweare.scss";

var wrapper = null;
class Whoweare extends Component {
  constructor(props) {
    super(props);
    this.state = { matches: window.matchMedia("(min-width: 768px)").matches };
  }

  componentDidMount() {
    const handler = (e) => this.setState({ matches: e.matches });
    window.matchMedia("(min-width: 768px)").addListener(handler);
  }

  render() {
    if (this.state.matches) {
      wrapper = <DesWrapper darkMode={this.props.darkMode} />;
    } else {
      wrapper = <SmWrapper darkMode={this.props.darkMode} />;
    }
    return (
      <section
        id="whoSection"
        className={`${this.props.darkMode ? "dark-mode" : ""}`}
      >
        {wrapper}
      </section>
    );
  }
}
export default Whoweare;
