import React from "react";
import polygD from "../../../../svgs/polygonD.svg";
import isInViewPort from "./isInViewPort";
import "./animation.scss";

class Polygonsvg extends React.Component {
  constructor(props) {
    super(props);
    this.circleRef = React.createRef(null);
    this.translateY = 0;
    this.lastTranslateY = 0;
    this.prevScroll = 0;
    this.curScroll = 0;
  }
  animateOnScroll = () => {
    const circle = this.circleRef.current;
    if (circle !== null) {
      this.curScroll = window.pageYOffset;
      var dScroll = this.curScroll - this.prevScroll;

      if (isInViewPort("Circ")) {
        this.translateY += dScroll * 1.4;
        if (dScroll > 0) {
          if (this.translateY >= 0 && this.translateY <= 270) {
            circle.style.transform = "translateY(" + this.translateY + "px)";
            this.lastTranslateY = this.translateY;
          } else {
            this.translateY = this.lastTranslateY;
          }
        } else {
          if (this.translateY >= 0 && this.translateY <= 270) {
            circle.style.transform = "translateY(" + this.translateY + "px)";
            this.lastTranslateY = this.translateY;
          } else {
            this.translateY = this.lastTranslateY;
          }
        }
      }
      this.prevScroll = this.curScroll;
    }
  };
  componentDidMount() {
    this.prevScroll = window.pageYOffset;
    this.curScroll = window.pageYOffset;
    window.addEventListener("scroll", () => {
      this.animateOnScroll();
    });
  }
  render() {
    return (
      <div>
        <img ref={this.circleRef} src={polygD} id="Polygon" alt="YOUR WAY UP" />
      </div>
    );
  }
}

export default Polygonsvg;
