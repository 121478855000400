import React, { Component } from "react";
import Carousel from "./Carousel";
import ScrollBtn from "./ScrollBtn";
import "./Home.scss";

class Home extends Component {
  render() {
    return (
      <section
        id="home-section"
        className={`${this.props.darkMode ? "dark-mode" : ""}`}
        // ref={
        //   this.props.currentSection === "HOME"
        //     ? this.props.currentSectionRef
        //     : ""
        // }
      >
        <Carousel
        // setCurrentSection={this.props.setCurrentSection}
        // setVisibleForm={this.props.setVisibleForm}
        />
        <ScrollBtn
        // setCurrentSection={this.props.setCurrentSection}
        />
      </section>
    );
  }
}

export default Home;
