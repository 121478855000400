import React, { Component } from "react";
import image4 from "../../Assets/Close-image.png";
import image01 from "../../Assets/Close-image-dark.png";
import image5 from "../../Assets/Back-image.png";
import image9 from "../../Assets/Back-image-dark.png";
import Aos from "aos";
import "aos/dist/aos.css";
class Modal2 extends Component {
  state = {
    darkMode: this.props.darkMode,
  };
  saySomething() {
    document.getElementById("id2").hidden = true;
  }

  componentDidMount() {
    Aos.init({
      duration: 3000,
    });
  }

  render() {
    var source1 = "";
    if (this.props.darkMode === true) {
      source1 = image9;
    } else {
      source1 = image5;
    }
    var source3 = "";
    if (this.props.darkMode === true) {
      source3 = image01;
    } else {
      source3 = image4;
    }

    return (
      <div id="id2" className="modal">
        <div className="overlay"></div>
        <div
          className={`${this.props.darkMode ? "dark-mode" : ""}`}
          id="modal-content"
        >
          <div onClick={this.saySomething} className="back-class">
            <img src={source1} alt="" />
          </div>
          <h2
            className={`${this.props.darkMode ? "dark-mode" : ""}`}
            id="class11"
          >
            {this.props.portfolio.name}
          </h2>
          {this.props.portfolio.title && (
            <>
              {" "}
              <br></br>
              <h3
                className={`${this.props.darkMode ? "dark-mode" : ""}`}
                id="classtitle2"
              >
                {this.props.portfolio.title}
              </h3>
            </>
          )}
          <div className="class6"></div>

          <React.Fragment>
            <p
              className={`${this.props.darkMode ? "dark-mode" : ""}`}
              id="textdummy"
            >
              <span className="key">Categories :</span>{" "}
              {this.props.portfolio.category.map((c) => (
                <span className="virgule">{c.name}</span>
              ))}{" "}
            </p>
            {this.props.portfolio.link && (
              <p
                className={`${this.props.darkMode ? "dark-mode" : ""}`}
                id="textdummy"
              >
                <span className="key">Website Link</span> :{" "}
                <a
                  href={this.props.portfolio.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {this.props.portfolio.link}
                </a>{" "}
              </p>
            )}
            {this.props.portfolio.description && (
              <p
                className={`${this.props.darkMode ? "dark-mode" : ""}`}
                id="textdummy"
              >
                <span className="key">Description</span> :{" "}
                {this.props.portfolio.description}{" "}
              </p>
            )}
            {this.props.portfolio.laptop && (
              <>
                <h3
                  id="center-text"
                  className={`${this.props.darkMode ? "dark-mode" : ""}`}
                  data-aos="zoom-out"
                >
                  Laptop
                </h3>
                <div className="bg-pc" data-aos="zoom-out">
                  <img
                    src={this.props.portfolio.laptop}
                    alt="image of a laptop"
                  />
                </div>
                <br />
                <div className="class6"></div>
                <br />
              </>
            )}
            {this.props.portfolio.tablet && (
              <>
                <h3
                  id="center-text"
                  className={`${this.props.darkMode ? "dark-mode" : ""}`}
                  data-aos="zoom-in"
                >
                  Tablet
                </h3>
                <div className="bg-tablet" data-aos="zoom-in">
                  <img
                    src={this.props.portfolio.tablet}
                    alt="image of a tablet"
                  />
                </div>
                <br />
                <div className="class6"></div>
                <br />
              </>
            )}
            {this.props.portfolio.mobile && (
              <>
                <h3
                  id="center-text"
                  className={`${this.props.darkMode ? "dark-mode" : ""}`}
                  data-aos="zoom-in"
                >
                  Mobile
                </h3>
                <div className="bg-phone" data-aos="zoom-in">
                  {/* <img
                    src={this.props.portfolio.mobile}
                    alt="image of a phone"
                  /> */}
                </div>
              </>
            )}
          </React.Fragment>

          <div onClick={this.saySomething} className="close-modal">
            <img src={source3} alt="" />
          </div>
          <br></br>
          <br></br>
        </div>
      </div>
    );
  }
}
export default Modal2;
