import React, { useState, useEffect } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  TextField,
  Grid,
  Link,
} from "@mui/material";
import TableScreen from "./components/TableScreen";
import Message from "./components/Message";
import Loader from "./components/Loader";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import {
  createCategory,
  listCategories,
  deleteCategory,
} from "../state/actions/categoryActions";
import axios from "axios";

const PortfolioCategoryScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const categoryCreate = useSelector((state) => state.categoryCreate);
  const { success, error: errorCreate } = categoryCreate;

  const categoryDelete = useSelector((state) => state.categoryDelete);
  const { success: successDelete, error: errorDelete } = categoryDelete;

  const categoryList = useSelector((state) => state.categoryList);
  const { loading, error, categories } = categoryList;

  useEffect(() => {
    if (userInfo) {
      dispatch(listCategories());
    } else {
      history.push("/admin/login");
    }
  }, [dispatch, history, userInfo, success, successDelete]);

  const [name, setName] = useState("");
  const [selectedImage, setSelectedImage] = useState();

  const deleteHandler = (id) => {
    dispatch(deleteCategory(id));
  };

  const editHandler = (id) => {
    history.push(`/admin/portfolio/categories/${id}/edit`);
  };

  const uploadImage = async (img) => {
    const formData = new FormData();
    formData.append("file", img);
    formData.append("upload_preset", "bwiy0n2g"); //change preset
    const { data } = await axios.post(
      "https://api.cloudinary.com/v1_1/detmdijki/image/upload",
      formData
    );
    return data.url;
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    let image = await uploadImage(selectedImage);
    setTimeout(() => {
      dispatch(createCategory({ name, image }));
      setName("");
      setSelectedImage();
    }, 3000);
  };

  return (
    <TableScreen>
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <form onSubmit={submitHandler}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              height="28vh"
            >
              <TextField
                type="text"
                variant="outlined"
                required
                label="Name"
                value={name}
                fullWidth={true}
                onChange={(e) => setName(e.target.value)}
              />
              <Button variant="contained" component="label">
                UPLOAD IMAGE
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={(e) => setSelectedImage(e.target.files[0])}
                />
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
              >
                ADD
              </Button>
              {errorCreate && <Message>{errorCreate}</Message>}
            </Box>
          </form>
        </Grid>
        <Grid item xs={8}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Category Name</TableCell>
                  <TableCell>Image Link</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>

              {loading ? (
                <Loader />
              ) : error ? (
                <Message>{error}</Message>
              ) : (
                <TableBody>
                  {categories.map((category) => (
                    <TableRow key={category._id}>
                      <TableCell>{category.name}</TableCell>
                      <TableCell>
                        <Link
                          href={category.bgImage}
                          target="_blank"
                          rel="noopener"
                          underline="hover"
                        >
                          link
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => deleteHandler(category._id)}
                        >
                          Delete
                        </Button>
                        {errorDelete && <Message>{errorDelete}</Message>}
                        <Button
                          sx={{ ml: 1 }}
                          variant="outlined"
                          onClick={() => editHandler(category._id)}
                        >
                          Edit
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      ;
    </TableScreen>
  );
};

export default PortfolioCategoryScreen;
