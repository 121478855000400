import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../admin/components/Loader";
import Message from "../admin/components/Message";
import styles from "./Blogdetails.module.scss";
import { getBlogDetails } from "../state/actions/blogActions";
import TweetEmbed from "react-tweet-embed";
import { Helmet } from "react-helmet-async";

import {
  TwitterEmbed,
  YouTubeEmbed,
  FacebookEmbed,
  InstagramEmbed,
  LinkedInEmbed,
  TikTokEmbed,
  PlaceholderEmbed,
} from "react-social-media-embed";

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

const BlogDetails = ({ id, darkMode }) => {
  const dispatch = useDispatch();
  const [fontSize, setFontSize] = useState(16);

  const blogDetails = useSelector((state) => state.blogDetails);
  const { loading, error, blog } = blogDetails;

  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [body, setBody] = useState([]);
  const [category, setCategory] = useState("");
  const [image, setImage] = useState("");
  const [time, setTime] = useState("");
  const [updatedTime, setUpdatedTime] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [link, setLink] = useState([]);

  const [content, setContent] = useState("");

  let twitter = "twitter";
  let facebook = "facebook";
  let linkedin = "linkedin";
  let instagram = "instagram";
  let tiktok = "tiktok";
  let youtube = "youtu";

  useEffect(() => {
    if (!blog || blog._id !== id) {
      dispatch(getBlogDetails(id));
    } else {
      setTitle(blog.title);
      setAuthor(blog.author);
      setBody(blog.body);
      setImage(blog.image);
      setCategory(blog.category.name);
      setTime(blog.createdAt);
      setUpdatedTime(blog.updatedAt);
      setKeywords(blog.keywords);
      setLink(blog.link);
      setContent(blog.body[0].substr(3, 170));
    }
  }, [dispatch, id, blog]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={content} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message>{error}</Message>
      ) : (
        <div className={`${styles.c} ${darkMode ? styles.darkMode : ""}`}>
          <div className={styles.container}>
            <h2>
              <span className={styles.time}>
                {!updatedTime ? time.slice(0, 10) : updatedTime.slice(0, 10)}
              </span>{" "}
              / <span className={styles.category}>{category}</span>
            </h2>
            <h1 className={styles.title}>{title}</h1>
            <h3 className={styles.share}>
              Share on:
              <FacebookShareButton
                url={window.location.href}
                style={{ marginLeft: "1rem", transform: "translateY(0.31rem)" }}
              >
                <FacebookIcon size={35} borderRadius={50} />
              </FacebookShareButton>
              <TwitterShareButton
                url={window.location.href}
                style={{
                  marginLeft: "0.7rem",
                  transform: "translateY(0.31rem)",
                }}
              >
                <TwitterIcon size={35} borderRadius={50} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={window.location.href}
                style={{
                  marginLeft: "0.7rem",
                  transform: "translateY(0.31rem)",
                }}
              >
                <LinkedinIcon size={35} borderRadius={50} />
              </LinkedinShareButton>
              <WhatsappShareButton
                url={window.location.href}
                style={{
                  marginLeft: "0.7rem",
                  transform: "translateY(0.31rem)",
                }}
              >
                <WhatsappIcon size={35} borderRadius={50} />
              </WhatsappShareButton>
            </h3>
          </div>
          <div className={styles.imgContainer}>
            <img src={image} alt="blog" className={styles.img} />
          </div>
          <div className={styles.container}>
            <div className={styles["container--subtitle"]}>
              <h2 className={styles.subtitle}>
                Blog created by <a href={author.link}>{author.name}</a>
              </h2>
              <div className={styles.btns}>
                <button onClick={() => setFontSize((prevSize) => prevSize + 2)}>
                  <i className="fa fa-plus" aria-hidden="true"></i>
                  {/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
                </button>
                <button onClick={() => setFontSize((prevSize) => prevSize - 2)}>
                  <i className="fa fa-minus" aria-hidden="true"></i>
                  {/* <i class="fa fa-minus-circle" aria-hidden="true"></i> */}
                </button>
              </div>
            </div>
            {keywords.length !== 0 ? (
              <div className={styles.keys}>
                <p>
                  <p className={styles.keyword}>Keywords:</p>
                  {keywords.map((k) => (
                    <p
                      className={styles.keywords}
                      dangerouslySetInnerHTML={{ __html: k }}
                    ></p>
                  ))}
                </p>
              </div>
            ) : (
              <></>
            )}
            {body.length !== 0 && body.length > link.length
              ? body.map((b, i) => {
                  return (
                    <div key={i}>
                      <p
                        style={{ fontSize: `${fontSize}px` }}
                        className={styles.b}
                        dangerouslySetInnerHTML={{ __html: b }}
                      ></p>
                      <div className={styles.embed}>
                        {link[i] && link[i].indexOf(twitter) !== -1 && (
                          <TwitterEmbed url={link[i]} width={550} />
                        )}
                        {link[i] && link[i].indexOf(facebook) !== -1 && (
                          <FacebookEmbed url={link[i]} />
                        )}
                        {link[i] && link[i].indexOf(linkedin) !== -1 && (
                          <LinkedInEmbed
                            url={link[i]}
                            width={550}
                            height={630}
                          />
                        )}
                        {link[i] && link[i].indexOf(instagram) !== -1 && (
                          <InstagramEmbed url={link[i]} />
                        )}
                        {link[i] && link[i].indexOf(tiktok) !== -1 && (
                          <TikTokEmbed url={link[i]} />
                        )}
                        {link[i] && link[i].indexOf(youtube) !== -1 && (
                          <YouTubeEmbed url={link[i]} />
                        )}
                      </div>
                    </div>
                  );
                })
              : link.map((l, i) => {
                  return (
                    <div key={i}>
                      <p
                        style={{ fontSize: `${fontSize}px` }}
                        className={styles.b}
                        dangerouslySetInnerHTML={{ __html: body[i] }}
                      ></p>
                      <div className={styles.embed}>
                        {l && l.indexOf(twitter) !== -1 && (
                          <TwitterEmbed url={l} width={550} />
                        )}
                        {l && l.indexOf(facebook) !== -1 && (
                          <FacebookEmbed url={l} />
                        )}
                        {l && l.indexOf(linkedin) !== -1 && (
                          <LinkedInEmbed url={l} width={550} height={630} />
                        )}
                        {l && l.indexOf(instagram) !== -1 && (
                          <InstagramEmbed url={l} />
                        )}
                        {l && l.indexOf(tiktok) !== -1 && (
                          <TikTokEmbed url={l} />
                        )}
                        {l && l.indexOf(youtube) !== -1 && (
                          <YouTubeEmbed url={l} />
                        )}
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>
      )}
    </>
  );
};

export default BlogDetails;
