import * as React from "react";
import {ReactComponent as LocationIcon} from "../../svgs/location-icon.svg";


function Location(props) {
  return (

      <LocationIcon className="location-icon"  />
   
      );
    }

export default Location
