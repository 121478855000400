import React, { Component } from "react";
import NavBtn from "./NavBtn";
import "./NavBar.scss";
import NavDropDownBtn from "./NavDropDownBtn";
import { ReactComponent as CloseBtn } from "../../svgs/close-btn.svg";
import BlogButton from "./BlogButton";

// This component is the navigation menu on mobile version
class MobileNavMenu extends Component {
  // Active Button
  state = {
    activeBtn: "",
  };
  constructor(props) {
    super(props);
    this.activeBtnRef = React.createRef(null); // Reference to the active button
  }
  // Set activeBtn to the currentSection passed as props
  static getDerivedStateFromProps(props, state) {
    return {
      activeBtn: props.currentSection,
    };
  }
  // Set the activeBtn
  setActiveBtn = (title) => {
    this.props.setCurrentSection(title);
  };
  // Change the color of the active button by applying a css class
  selectActiveBtn = () => {
    this.activeBtnRef.current.classList.add("active-menu-btn");
    // console.log(this.activeBtnRef.current);
    // let dropDown = this.activeBtnRef.current.nextElementSibling;
    // if (dropDown) {
    //   dropDown.style.display = "none";
    // }
  };
  // Call the previous function on first load
  componentDidMount() {
    this.selectActiveBtn();
  }
  // Called before every update to remove the css class from the previous active button
  shouldComponentUpdate() {
    this.activeBtnRef.current.classList.remove("active-menu-btn");
    if (this.props.isMenuVisible) {
      this.props.setIsMenuVisible(false); // When a button is clicked the menu closes
    }
    return true;
  }
  // Called after every update
  componentDidUpdate() {
    this.selectActiveBtn();
  }
  // Render this component to the DOM
  // It is built from NavBtn, NavDropDownBtn and CloseBtn
  render() {
    return (
      <nav
        className={`mobile-nav-menu ${
          this.props.isMenuVisible ? "visible-menu" : ""
        } ${this.props.darkMode ? "dark-mode" : ""}`}
      >
        <ul>
          <NavBtn
            title="HOME"
            setActiveBtn={this.setActiveBtn}
            activeBtn={this.state.activeBtn}
            activeBtnRef={this.activeBtnRef}
            toggleLeaderAndBlog={this.props.toggleLeaderAndBlog}
          />
          <NavBtn
            title="WHO WE ARE"
            setActiveBtn={this.setActiveBtn}
            activeBtn={this.state.activeBtn}
            activeBtnRef={this.activeBtnRef}
            toggleLeaderAndBlog={this.props.toggleLeaderAndBlog}
          />
          <NavBtn
            title="LEADER"
            setActiveBtn={this.setActiveBtn}
            activeBtn={this.state.activeBtn}
            activeBtnRef={this.activeBtnRef}
            toggleLeader={this.props.toggleLeader}
            toggleLeaderAndBlog={this.props.toggleLeaderAndBlog}
          />
          <NavBtn
            title="WHAT WE DO"
            setActiveBtn={this.setActiveBtn}
            activeBtn={this.state.activeBtn}
            activeBtnRef={this.activeBtnRef}
            toggleLeaderAndBlog={this.props.toggleLeaderAndBlog}
          />
          <NavBtn
            title="SKILLS"
            setActiveBtn={this.setActiveBtn}
            activeBtn={this.state.activeBtn}
            activeBtnRef={this.activeBtnRef}
            toggleLeaderAndBlog={this.props.toggleLeaderAndBlog}
          />
          <NavBtn
            title="PORTFOLIO"
            setActiveBtn={this.setActiveBtn}
            activeBtn={this.state.activeBtn}
            activeBtnRef={this.activeBtnRef}
            toggleLeaderAndBlog={this.props.toggleLeaderAndBlog}
          />
          <NavDropDownBtn
            title="CONTACT US"
            dropDown={["Send a Message", "Start a Project"]}
            setActiveBtn={this.setActiveBtn}
            activeBtn={this.state.activeBtn}
            activeBtnRef={this.activeBtnRef}
            setVisibleForm={this.props.setVisibleForm}
          />
          <NavBtn
            title="CAREERS"
            setActiveBtn={this.setActiveBtn}
            activeBtn={this.state.activeBtn}
            activeBtnRef={this.activeBtnRef}
            toggleLeaderAndBlog={this.props.toggleLeaderAndBlog}
          />
          <BlogButton sticky={true} toggleBlog={this.props.toggleBlog} />
          {/* <NavBtn
            title="BLOG"
            setActiveBtn={this.setActiveBtn}
            activeBtn={this.state.activeBtn}
            activeBtnRef={this.activeBtnRef}
            toggleBlog={this.props.toggleBlog}
            toggleLeaderAndBlog={this.props.toggleLeaderAndBlog}
          /> */}
          <li
            className="close-btn"
            onClick={() => this.props.setIsMenuVisible(false)}
          >
            <CloseBtn />
          </li>
        </ul>
      </nav>
    );
  }
}

export default MobileNavMenu;
