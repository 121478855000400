import React, { Component } from "react";
import { Link } from "react-router-dom";

// The Navigation Button component
class NavBtn extends Component {
  // Title of the button and active boolean
  state = {
    title: this.props.title,
    active: this.props.activeBtn === this.props.title,
  };
  // Set the state from props
  static getDerivedStateFromProps(props, state) {
    return { active: props.activeBtn === props.title };
  }
  // Set this button as active by using setActiveBtn passed as props
  activate = () => {
    if (this.props.title === "LEADER") {
      this.props.setActiveBtn(this.state.title);
      this.props.toggleLeaderAndBlog();
      this.props.toggleLeader();
    } else if (this.props.title === "BLOG") {
      this.props.setActiveBtn(this.state.title);
      this.props.toggleLeaderAndBlog();
      this.props.toggleBlog();
    } else {
      this.props.setActiveBtn(this.state.title);
      this.props.toggleLeaderAndBlog();
    }
  };
  // Render to the DOM
  render() {
    return (
      <li className="nav-btn">
        <Link to="/?loading=false">
          <button
            ref={this.state.active ? this.props.activeBtnRef : ""}
            onClick={this.activate}
          >
            {this.state.title}
          </button>
        </Link>
      </li>
    );
  }
}

export default NavBtn;
