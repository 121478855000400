import React, { Component } from "react";

// BTekUp Logo component
class NavLogo extends Component {
  // Type can be dark or light
  // dark returns the white logo, light returns the gray logo
  constructor(props) {
    super(props);
    this.state = { type: "" };
  }
  // Set the type based on props
  static getDerivedStateFromProps(props, state) {
    return { type: props.type };
  }
  // Render to the DOM
  render() {
    var source = "";
    if (this.state.type === "dark") {
      source = require("../../svgs/logo-dark.svg").default;
    } else {
      source = require("../../svgs/logo-white.svg").default;
    }
    return (
      <h1 className="nav-logo">
        <img src={source} alt="BTekUp Logo" />
      </h1>
    );
  }
}

export default NavLogo;
