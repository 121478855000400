function isInViewPort(elementId) {
  const elementRect = document
    .getElementById(elementId)
    .getBoundingClientRect();
  const viewPortHeight = document.documentElement.clientHeight;
  return (
    elementRect.top + elementRect.height < viewPortHeight &&
    elementRect.bottom < viewPortHeight
  );
}

export default isInViewPort;
