import React, { Component } from "react";
import Blog from "./Blog";

class BlogSection extends Component {
  render() {
    return (
      <section
        id="blog-section"
        className={`${this.props.darkMode ? "dark-mode" : ""}`}
        // ref={
        //   this.props.currentSection === "BLOG"
        //     ? this.props.currentSectionRef
        //     : ""
        // }
      >
        <Blog darkMode={this.props.darkMode} />
      </section>
    );
  }
}

export default BlogSection;
