import React, { useState, useEffect } from "react";
import TableScreen from "./components/TableScreen";
import Message from "./components/Message";
import Loader from "./components/Loader";
import { Button, TextField, Typography, Box } from "@mui/material";
import { useHistory, useParams } from "react-router-dom";
import { getAuthorById, updateAuthor } from "../state/actions/authorActions";
import { useDispatch, useSelector } from "react-redux";
import {
  AUTHOR_DETAILS_RESET,
  AUTHOR_UPDATE_RESET,
} from "../constants/authorConstants";

const AuthorEditScreen = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  let { id } = useParams();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const authorDetails = useSelector((state) => state.authorDetails);
  const { loading, error, author } = authorDetails;

  const authorUpdate = useSelector((state) => state.authorUpdate);
  const { success, loading: loadingUpdate, error: errorUpdate } = authorUpdate;

  const [name, setName] = useState();
  const [link, setLink] = useState("");

  useEffect(() => {
    if (userInfo) {
      if (success) {
        dispatch({ type: AUTHOR_DETAILS_RESET });
        dispatch({ type: AUTHOR_UPDATE_RESET });
        history.push("/admin/author");
      } else {
        if (!author || author._id !== id) {
          dispatch(getAuthorById(id));
        } else {
          setName(author.name);
          setLink(author.link);
        }
      }
    } else {
      history.push("/admin/login");
    }
  }, [history, userInfo, id, author, dispatch, success]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(updateAuthor({ id, name, link }));
  };

  return (
    <TableScreen>
      <Box display="flex" justifyContent="center">
        <form onSubmit={submitHandler}>
          {loading ? (
            <Loader />
          ) : error ? (
            <Message>{error}</Message>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              height="30vh"
              sx={{
                justifyContent: "space-between",
              }}
            >
              {error && <Message>{error}</Message>}
              <Typography variant="h5">Edit Author</Typography>
              <TextField
                type="text"
                id="outlined-basic"
                variant="outlined"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <TextField
                type="text"
                id="outlined-basic"
                variant="outlined"
                required
                value={link}
                onChange={(e) => setLink(e.target.value)}
              />
              <Button
                type="submit"
                size="large"
                variant="contained"
                color="primary"
              >
                Update
              </Button>
              {errorUpdate && <Message>{errorUpdate}</Message>}
            </Box>
          )}
        </form>
      </Box>
    </TableScreen>
  );
};

export default AuthorEditScreen;
