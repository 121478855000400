import React, { useRef, useEffect } from "react";
import { ReactComponent as LoadingSvg } from "../../svgs/loading.svg";
import "./Loading.scss";

const Loading = () => {
  const loadingScreenRef = useRef(null);

  useEffect(() => {
    const onLoadComplete = () => {
      loadingScreenRef.current.classList.add("load-end-phase-1");
      setTimeout(() => {
        loadingScreenRef.current.classList.add("load-end-phase-2");
        setTimeout(() => {
          loadingScreenRef.current.style.display = "none";
          document.getElementsByTagName("body")[0].style.overflow = "auto";
        }, 500);
      }, 3500);
    };

    document.getElementsByTagName("body")[0].style.overflow = "hidden";
    window.addEventListener("load", onLoadComplete);
    return () => {
      window.removeEventListener("load", onLoadComplete);
    };
  }, []);

  return (
    <section id="loading-screen" ref={loadingScreenRef}>
      <LoadingSvg />
    </section>
  );
};

export default Loading;
