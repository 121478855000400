import React, { useState } from "react";
import CircleDemo from "./CircleDemo.js";
import Modal1 from "./Modal1.js";

const Modal3 = ({ darkMode, categories }) => {
  const [visible, setVisible] = useState(false);
  const [category, setCategory] = useState();
  let cats = categories.slice(3);

  return (
    <div id="hide" className="class22">
      <div className="class3">
        <div className="c2">
          {cats.map((c) => {
            return (
              <div
                key={c._id}
                className="tst"
                onClick={() => {
                  setVisible((prevVisible) => !prevVisible);
                  setCategory(c);
                }}
              >
                <CircleDemo id="test" title={c.name} imagename={c.bgImage} />
              </div>
            );
          })}
        </div>
      </div>

      {visible ? (
        <Modal1 darkMode={darkMode} category={category}></Modal1>
      ) : null}
    </div>
  );
};

export default Modal3;
