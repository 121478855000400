import { useEffect } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from "@mui/material";
import TableScreen from "./components/TableScreen";
import Loader from "./components/Loader";
import Message from "./components/Message";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { listBlogs, deleteBlog } from "../state/actions/blogActions";
import {
  BLOG_CREATE_RESET,
  BLOG_UPDATE_RESET,
} from "../constants/blogConstants";

const BlogScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const blogList = useSelector((state) => state.blogList);
  const { loading, error, blogs } = blogList;

  const blogDelete = useSelector((state) => state.blogDelete);
  const { loading: loadingDelete, error: errorDelete, success } = blogDelete;

  useEffect(() => {
    if (userInfo) {
      dispatch(listBlogs());
    } else {
      history.push("/admin/login");
    }
  }, [dispatch, history, userInfo, success]);

  const createHandler = () => {
    dispatch({ type: BLOG_CREATE_RESET });
    history.push("/admin/blog/create");
  };

  const blogDeleteHandler = (id) => {
    dispatch(deleteBlog(id));
  };

  const blogEditHandler = (id) => {
    dispatch({ type: BLOG_UPDATE_RESET });
    history.push(`/admin/blog/${id}/edit`);
  };

  return (
    <TableScreen>
      <Box my={5}>
        <Button variant="contained" onClick={createHandler}>
          Create a new blog
        </Button>
      </Box>
      <TableContainer component={Paper} sx={{ mb: 7 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Author</TableCell>
              <TableCell>Category</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          {loading ? (
            <Loader />
          ) : error ? (
            <Message>{error}</Message>
          ) : (
            <TableBody>
              {blogs.map((blog) => (
                <TableRow key={blog._id}>
                  <TableCell>{blog.title}</TableCell>
                  <TableCell>
                    {blog.author ? blog.author.name : blog.subtitle}
                  </TableCell>
                  <TableCell>{blog.category.name}</TableCell>
                  <TableCell>
                    <Button
                      onClick={() => blogDeleteHandler(blog._id)}
                      variant="outlined"
                      color="error"
                    >
                      Delete
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      color="info"
                      onClick={() => blogEditHandler(blog._id)}
                    >
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </TableScreen>
  );
};

export default BlogScreen;
