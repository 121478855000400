import React, { useState, useRef, useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import NewMobileNavBar from "../components/NewMobileNavBar/NewMobileNavBar";
import NewStickyNavBar from "../components/NewStickyNavBar/NewStickyNavBar";
import { useSelector, useDispatch } from "react-redux";
import { handledarkMode } from "../../state/actions/userActions";

const Layout = ({ children }) => {
  const dispatch = useDispatch();
  const stickyNavBarRef = useRef(null);
  const [visibleForm, setVisibleForm] = useState("Send a Message");
  const [isMenu, setIsMenu] = useState(false);

  const mode = useSelector((state) => state.darkMode);
  const { darkMode } = mode;

  const switchDarkMode = () => {
    darkMode ? dispatch(handledarkMode(false)) : dispatch(handledarkMode(true));
  };

  const toggleDarkMode = () => {
    switchDarkMode();
  };

  const setIsMenuVisible = (isVisible) => {
    setIsMenu(isVisible);
  };

  useEffect(() => {
    document.body.style.backgroundColor = darkMode ? "#323b3f" : "#fff";
  }, [darkMode]);

  return (
    <>
      <NewStickyNavBar
        darkMode={darkMode}
        toggleDarkMode={toggleDarkMode}
        setIsMenuVisible={setIsMenuVisible}
        stickyNavBarRef={stickyNavBarRef}
        blog={true}
      />
      <NewMobileNavBar
        darkMode={darkMode}
        setVisibleForm={setVisibleForm}
        isMenuVisible={isMenu}
        setIsMenuVisible={setIsMenuVisible}
      />
      {children}
      <Footer darkMode={darkMode} />
    </>
  );
};

export default Layout;
