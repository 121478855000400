export const PORTFOLIO_CREATE_REQUEST = "PORTFOLIO_CREATE_REQUEST";
export const PORTFOLIO_CREATE_SUCCESS = "PORTFOLIO_CREATE_SUCCESS";
export const PORTFOLIO_CREATE_FAIL = "PORTFOLIO_CREATE_FAIL";
export const PORTFOLIO_CREATE_RESET = "PORTFOLIO_CREATE_RESET";

export const PORTFOLIO_LIST_REQUEST = "PORTFOLIO_LIST_REQUEST";
export const PORTFOLIO_LIST_SUCCESS = "PORTFOLIO_LIST_SUCCESS";
export const PORTFOLIO_LIST_FAIL = "PORTFOLIO_LIST_FAIL";

export const PORTFOLIO_UPDATE_REQUEST = "PORTFOLIO_UPDATE_REQUEST";
export const PORTFOLIO_UPDATE_SUCCESS = "PORTFOLIO_UPDATE_SUCCESS";
export const PORTFOLIO_UPDATE_FAIL = "PORTFOLIO_UPDATE_FAIL";
export const PORTFOLIO_UPDATE_RESET = "PORTFOLIO_UPDATE_RESET";

export const PORTFOLIO_DELETE_REQUEST = "PORTFOLIO_DELETE_REQUEST";
export const PORTFOLIO_DELETE_SUCCESS = "PORTFOLIO_DELETE_SUCCESS";
export const PORTFOLIO_DELETE_FAIL = "PORTFOLIO_DELETE_FAIL";

export const PORTFOLIO_DETAILS_REQUEST = "PORTFOLIO_DETAILS_REQUEST";
export const PORTFOLIO_DETAILS_SUCCESS = "PORTFOLIO_DETAILS_SUCCESS";
export const PORTFOLIO_DETAILS_FAIL = "PORTFOLIO_DETAILS_FAIL";

export const PORTFOLIO_LIST_BY_CATEGORY_REQUEST =
  "PORTFOLIO_LIST_BY_CATEGORY_REQUEST";
export const PORTFOLIO_LIST_BY_CATEGORY_SUCCESS =
  "PORTFOLIO_LIST_BY_CATEGORY_SUCCESS";
export const PORTFOLIO_LIST_BY_CATEGORY_FAIL =
  "PORTFOLIO_LIST_BY_CATEGORY_FAIL";
export const PORTFOLIO_LIST_BY_CATEGORY_RESET =
  "PORTFOLIO_LIST_BY_CATEGORY_RESET";
