function isInViewPort(elementId) {
  const elementRect = document
    .getElementById(elementId)
    .getBoundingClientRect();
  const viewPortHeight = document.documentElement.clientHeight;
  return (
    elementRect.top < viewPortHeight / 2 &&
    elementRect.bottom > viewPortHeight / 2
  );
}

export default isInViewPort;
