import React from "react";
import ReactDOM from "react-dom";
import Website from "./Website";
import "./index.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./state/store";
import LoginScreen from "./admin/LoginScreen";
import ContactScreen from "./admin/ContactScreen";
import JoinOurTeamScreen from "./admin/JoinOurTeamScreen";
import StartYourProjecScreen from "./admin/StartYourProjecScreen";
import ProjectDetailScreen from "./admin/ProjectDetailScreen";
import HomeScreen from "./admin/HomeScreen";
import WhatWeDoScreen from "./admin/WhatWeDoScreen";
import WhatWeDoEditScreen from "./admin/WhatWeDoEditScreen";
import PortfolioScreen from "./admin/PortfolioScreen";
import PortfolioCreateScreen from "./admin/PortfolioCreateScreen";
import PortfolioCategoryScreen from "./admin/PortfolioCategoryScreen";
import PortfolioEditScreen from "./admin/PortfolioEditScreen";
import PortfolioCategoryEdit from "./admin/PortfolioCategoryEdit";
// import Leader from "./screens/Leader";
import BlogScreen from "./admin/BlogScreen";
import BlogCreateScreen from "./admin/BlogCreateScreen";
import BlogEditScreen from "./admin/BlogEditScreen";
import BlogCategoryScreen from "./admin/BlogCategoryScreen";
import BlogCategoryEditScreen from "./admin/BlogCategoryEditScreen";
// import Blog from "./screens/Blog";
// import BlogDetails from "./screens/BlogDetails";
import LeaderScreen from "./admin/LeaderScreen";
import AuthorScreen from "./admin/AuthorScreen";
import AuthorEditScreen from "./admin/AuthorEditScreen";
import { HelmetProvider } from "react-helmet-async";
// Test is blog details
import Test from "./screens/new screen/Test";
import HomePage from "./screens/HomePage/HomePage";
import BlogPage from "./screens/BlogPage/BlogPage";
import MeetLeader from "./screens/MeetLeader/MeetLeader";
import { hydrate, render } from "react-dom";

/*
  IMPORTANT NOTES:
  In REACT, a component gets updated and rerendered whenever one or 
  more value of its states or props is changed.
  Functions can be passed as props to the children to enable them from
  controlling parent states.
  Parent control his children by passing values props.
 */
// This is the root component that contains all components
class BTekUp extends React.Component {
  // The Global variables of our website are defined here to be accessible for all child components
  // This practice will prevent collisions between components
  // For example, the 2 NavBars must always have the same selected section

  render() {
    return (
      <Provider store={store}>
        <Router>
          <Switch>
            {/* <Route exact path="/meet-the-leader" component={Leader} /> */}

            {/* <Route exact path="/" component={Website}></Route> */}
            <Route exact path="/" component={HomePage}></Route>
            <Route exact path="/blog" component={BlogPage}></Route>
            <Route exact path="/blog/:title" component={Test}></Route>
            <Route exact path="/leader" component={MeetLeader}></Route>

            <Route exact path="/admin" component={HomeScreen} />
            <Route exact path="/admin/login" component={LoginScreen} />
            <Route exact path="/admin/contact" component={ContactScreen} />
            <Route exact path="/admin/what-we-do" component={WhatWeDoScreen} />
            <Route
              exact
              path="/admin/what-we-do/:id/edit"
              component={WhatWeDoEditScreen}
            />
            <Route
              exact
              path="/admin/join-our-team"
              component={JoinOurTeamScreen}
            />
            <Route
              exact
              path="/admin/start-your-project"
              component={StartYourProjecScreen}
            />
            <Route
              exact
              path="/admin/start-your-project/:id"
              component={ProjectDetailScreen}
            />
            <Route
              exact
              path="/admin/portfolio/categories"
              component={PortfolioCategoryScreen}
            />
            <Route
              exact
              path="/admin/portfolio/categories/:id/edit"
              component={PortfolioCategoryEdit}
            />
            <Route exact path="/admin/portfolio" component={PortfolioScreen} />
            <Route
              exact
              path="/admin/portfolio/:id/edit"
              component={PortfolioEditScreen}
            />
            <Route
              exact
              path="/admin/portfolio/create"
              component={PortfolioCreateScreen}
            />
            <Route exact path="/admin/blog" component={BlogScreen} />
            <Route
              exact
              path="/admin/blog/create"
              component={BlogCreateScreen}
            />
            <Route
              exact
              path="/admin/blog/:id/edit"
              component={BlogEditScreen}
            />
            <Route
              exact
              path="/admin/blog/categories"
              component={BlogCategoryScreen}
            />
            <Route
              exact
              path="/admin/blog/categories/:id"
              component={BlogCategoryEditScreen}
            />
            {/* <Route exact path="/blog" component={Blog} /> */}
            {/* <Route exact path="/blog/:id" component={BlogDetails} /> */}
            <Route exact path="/admin/leader" component={LeaderScreen} />
            <Route exact path="/admin/author" component={AuthorScreen} />
            <Route
              exact
              path="/admin/author/:id"
              component={AuthorEditScreen}
            />
          </Switch>
        </Router>
      </Provider>
    );
  }
}

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(
    <HelmetProvider>
      <BTekUp />
    </HelmetProvider>,
    rootElement
  );
} else {
  render(
    <HelmetProvider>
      <BTekUp />
    </HelmetProvider>,
    rootElement
  );
}

// ReactDOM.render(
//   <HelmetProvider>
//     <BTekUp />
//   </HelmetProvider>,
//   document.getElementById("root")
// );
