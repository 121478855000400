import React, { Component } from "react";

class CircleDemo extends Component {
  render() {
    return (
      <div className="wrapper3 ">
        <div class="outherCircle3">
          <img src={this.props.imagename} alt="" />
          <div className="circleBorder3">
            <div className="infoCircle3">
              <p className="disable-text-selection"> {this.props.title}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CircleDemo;
