import {
  START_YOUR_PROJECT_CREATE_FAIL,
  START_YOUR_PROJECT_CREATE_REQUEST,
  START_YOUR_PROJECT_CREATE_SUCCESS,
  START_YOUR_PROJECT_LIST_FAIL,
  START_YOUR_PROJECT_LIST_REQUEST,
  START_YOUR_PROJECT_LIST_SUCCESS,
  START_YOUR_PROJECT_DETAIL_FAIL,
  START_YOUR_PROJECT_DETAIL_REQUEST,
  START_YOUR_PROJECT_DETAIL_SUCCESS,
  START_YOUR_PROJECT_DELETE_REQUEST,
  START_YOUR_PROJECT_DELETE_SUCCESS,
  START_YOUR_PROJECT_DELETE_FAIL,
} from "../../constants/startYourProjectConstant";
import axios from "axios";

export const createStartYourPorject =
  (project) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_YOUR_PROJECT_CREATE_REQUEST });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      await axios.post("https://backend.btekup.com/api/start-your-project", project, config);

      dispatch({ type: START_YOUR_PROJECT_CREATE_SUCCESS });
    } catch (error) {
      dispatch({
        type: START_YOUR_PROJECT_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getStartYourPorjectDetails =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_YOUR_PROJECT_DETAIL_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.get(`https://backend.btekup.com/api/start-your-project/${id}`, config);

      dispatch({ type: START_YOUR_PROJECT_DETAIL_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: START_YOUR_PROJECT_DETAIL_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const startProjectList = () => async (dispatch, getState) => {
  try {
    dispatch({ type: START_YOUR_PROJECT_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get("https://backend.btekup.com/api/start-your-project", config);

    dispatch({ type: START_YOUR_PROJECT_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: START_YOUR_PROJECT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteStartProject = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_YOUR_PROJECT_DELETE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    await axios.delete(`https://backend.btekup.com/api/start-your-project/${id}`, config);

    dispatch({ type: START_YOUR_PROJECT_DELETE_SUCCESS });
  } catch (error) {
    dispatch({
      type: START_YOUR_PROJECT_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
