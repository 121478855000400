import { useEffect, useState, useRef } from "react";
import axios from "axios";
import {
  Button,
  Box,
  Typography,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import TableScreen from "./components/TableScreen";
import Loader from "./components/Loader";
import Message from "./components/Message";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { createBlog, listCategories } from "../state/actions/blogActions";
import { BLOG_CREATE_RESET } from "../constants/blogConstants";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { listAuthors } from "../state/actions/authorActions";
import QuillEditor from "./components/QuillEditor";

const BlogCreateScreen = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const blogCreate = useSelector((state) => state.blogCreate);
  const { loading, error, success } = blogCreate;

  const blogCategoryList = useSelector((state) => state.blogCategoryList);
  const {
    categories,
    loading: loadingCategories,
    error: errorCategories,
  } = blogCategoryList;

  const authorList = useSelector((state) => state.authorList);
  const { authors } = authorList;

  const [title, setTitle] = useState();
  const [author, setAuthor] = useState();
  const [body, setBody] = useState([]);
  const [category, setCategory] = useState();
  const [image, setImage] = useState("");
  const [link, setLink] = useState([]);

  const [keyword, setKeyword] = useState("");
  const [keywords, setKeywords] = useState([]);

  const [showEditor, setShowEditor] = useState(true);
  const [bodi, setBodi] = useState("");
  const [linc, setLinc] = useState("");

  const [files, setFiles] = useState([]);

  // const [bodyy, setBodyy] = useState("");
  // const [bodies, setBodies] = useState([]);
  // const [posts, setPosts] = useState([]);

  const onEditorChange = (value) => {
    setBodi(value);
  };

  const onFilesChange = (files) => {
    setFiles(files);
  };

  const [uploading, setUploading] = useState(false);

  // const Editor = () => {
  //   return (
  //     <Box mt={4}>
  //       <ReactQuill value={bodyy} onChange={setBodyy} />
  //       <Button
  //         variant="contained"
  //         color="primary"
  //         size="large"
  //         sx={{ mt: 2 }}
  //         onClick={() => {
  //           setBody((prevBody) => [...prevBody, bodyy]);
  //           console.log(bodyy);
  //         }}
  //       >
  //         ADD
  //       </Button>
  //     </Box>
  //   );
  // };

  // const addBody = () => {
  //   setBodies((prevBodies) => [...prevBodies, <Editor key={bodies.length} />]);
  // };

  // const addPost = () => {
  //   setPosts((prevPosts) => [
  //     ...prevPosts,
  //     <TextField
  //       sx={{ mt: 4 }}
  //       type="text"
  //       id="outlined-basic"
  //       label="Social Media Link (Not required)"
  //       variant="outlined"
  //       fullWidth
  //       value={link}
  //       onChange={(e) => setLink((prevLink) => [...prevLink, e.target.value])}
  //     />,
  //   ]);
  // };

  useEffect(() => {
    dispatch(listAuthors());
    dispatch(listCategories());

    if (!userInfo) {
      history.push("/admin/login");
    } else if (success) {
      history.push("/admin/blog");
      dispatch({ type: BLOG_CREATE_RESET });
    }
  }, [history, userInfo, success, dispatch]);

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "pd52bchw");
    setUploading(true);
    try {
      const { data } = await axios.post(
        "https://api.cloudinary.com/v1_1/detmdijki/image/upload",
        formData
      );
      setImage(data.url);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setTimeout(() => {
      dispatch(
        createBlog({
          title,
          author,
          body,
          category,
          image,
          keywords,
          link,
        })
      );
    }, 2000);
  };

  return (
    <TableScreen>
      <Box>
        <Typography variant="h5" textAlign="center" sx={{ mb: 3 }}>
          Create a new Blog
        </Typography>
        <form onSubmit={submitHandler}>
          <Grid container spacing={4}>
            <Grid item xs={2} />
            <Grid item xs={4}>
              <TextField
                fullWidth={true}
                type="text"
                id="outlined-basic"
                label="Title"
                variant="outlined"
                required
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                sx={{ mb: 2 }}
              />
              <FormControl fullWidth={true}>
                <InputLabel id="demo-simple-select-label">Authors</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={author}
                  label="Author"
                  onChange={(e) => setAuthor(e.target.value)}
                  sx={{ mb: 2 }}
                >
                  {authors.map((a) => {
                    return (
                      <MenuItem key={a._id} value={a._id}>
                        {a.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl fullWidth={true}>
                <InputLabel id="demo-simple-select-label">Category</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={category}
                  label="Category"
                  onChange={(e) => setCategory(e.target.value)}
                  sx={{ mb: 2 }}
                >
                  {categories.map((c) => {
                    return (
                      <MenuItem key={c._id} value={c._id}>
                        {c.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {uploading ? (
                <Loader />
              ) : (
                <Button
                  fullWidth={true}
                  variant="contained"
                  component="label"
                  sx={{ my: 1 }}
                >
                  UPLOAD IMAGE
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={uploadFileHandler}
                  />
                </Button>
              )}
            </Grid>
            <Grid item xs={4}>
              <Typography sx={{ mb: 2 }}>Keywords:</Typography>
              {keywords.map((k) => {
                return <p dangerouslySetInnerHTML={{ __html: k }}></p>;
              })}
              <ReactQuill theme="snow" value={keyword} onChange={setKeyword} />
              <Button
                sx={{ mt: 3 }}
                variant="contained"
                color="primary"
                size="large"
                fullWidth="true"
                onClick={() => {
                  setKeywords((prevKeywords) => [...prevKeywords, keyword]);
                  setKeyword("");
                }}
              >
                ADD KEYWORD
              </Button>
            </Grid>
            <Grid item xs={2} />
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={2} />
            <Grid item xs={8}>
              <Typography sx={{ mb: 2 }}>Blog Body:</Typography>
              {showEditor && (
                <QuillEditor
                  placeholder={"Start Posting Something"}
                  onEditorChange={onEditorChange}
                  onFilesChange={onFilesChange}
                  value={bodi}
                />
              )}
              <TextField
                sx={{ mt: 4 }}
                type="text"
                id="outlined-basic"
                label="Social Media Link (Not required)"
                variant="outlined"
                fullWidth={true}
                value={linc}
                onChange={(e) => setLinc(e.target.value)}
              />
              {uploading ? (
                <Loader />
              ) : (
                <>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    mt={4}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth="true"
                      onClick={
                        showEditor
                          ? () => {
                              console.log(bodi);
                              bodi &&
                                setBody((prevBody) => [...prevBody, bodi]);
                              setBodi("");
                              setShowEditor(false);
                            }
                          : () => setShowEditor(true)
                      }
                    >
                      Add Body
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth="true"
                      sx={{ ml: 5 }}
                      onClick={() => {
                        linc && setLink((prevlink) => [...prevlink, linc]);
                        setLinc("");
                      }}
                    >
                      Add Embeded Post
                    </Button>
                  </Box>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                    fullWidth="true"
                    sx={{ my: 4 }}
                  >
                    Create Blog
                  </Button>
                </>
              )}
              {error && <Message>{error}</Message>}
            </Grid>
            <Grid item xs={2} />
          </Grid>
          <Box>
            <Typography variant="h5" textAlign="center" sx={{ mb: 3 }}>
              Preview
            </Typography>
            {body.map((b, i) => {
              return (
                <>
                  <h3>body {i + 1}</h3>
                  <p key={i} dangerouslySetInnerHTML={{ __html: b }}></p>
                </>
              );
            })}
            {link.map((l, i) => {
              return (
                <>
                  <h3>link {i + 1}</h3>
                  <p key={i} dangerouslySetInnerHTML={{ __html: l }}></p>
                </>
              );
            })}
          </Box>
        </form>
      </Box>
    </TableScreen>
  );
};

export default BlogCreateScreen;
