import React, { Component } from "react";
import SendMessage from "../SendMessage/SendMessage";
import LetsStartYourProject from "../LetsStartYourProject/LetsStartYourProject";

// var visibleForm = null;

class ContactUs extends Component {
  render() {
    // if (this.props.form === "Send a Message") {
    //   visibleForm = <SendMessage darkMode={this.props.darkMode} />;
    // } else if (this.props.form === "Start a Project") {
    //   visibleForm = <LetsStartYourProject darkMode={this.props.darkMode} />;
    // }
    return (
      <section id="contact-us-container">
        <SendMessage
          darkMode={this.props.darkMode}
          // currentSection={this.props.currentSection}
          // currentSectionRef={this.props.currentSectionRef}
          form={this.props.form}
        />
        <LetsStartYourProject
          darkMode={this.props.darkMode}
          // currentSection={this.props.currentSection}
          // currentSectionRef={this.props.currentSectionRef}
          form={this.props.form}
        />
        {/* {visibleForm} */}
      </section>
    );
  }
}

export default ContactUs;
