// import "./bootstrap.scss";
// import "bootstrap/dist/css/bootstrap.min.css";
import "./JoinOurTeam.scss";
import React, { Component } from "react";
import Form from "./Form.js";
import uploadButton from "./uploadButton";
uploadButton();

class JoinOurTeam extends Component {
  render() {
    return (
      <section
        id="join-us"
        className={`${this.props.darkMode ? "dark-mode" : ""}`}
        // ref={
        //   this.props.currentSection === "CAREERS"
        //     ? this.props.currentSectionRef
        //     : ""
        // }
      >
        <div
          // className="container"
          id="flex-container"
          // style={{
          //   display: "flex",
          //   flexDirection: "row",
          //   width: "90%",
          //   margin: "0 auto",
          // }}
        >
          <div className="joinTeam">
            <div id="image-and-title">
              <div className="section-title">
                <h2>Join Our Team</h2>
              </div>
              {/* <Image id="image-container" /> */}
              <h1>
                We're always on the lookout for new and diverse talents to join
                our ever-growing innovation team.
              </h1>
              <h1>
                At BtekUp, we strive to do what's best for our team, our
                clients, our industry, and the world.
              </h1>
            </div>
            <Form id="form-container" />
          </div>
        </div>
      </section>
    );
  }
}

export default JoinOurTeam;
