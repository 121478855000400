import React, { useEffect, useState } from "react";
import styles from "./Leader.module.scss";
import LeaderImg from "../Assets/leader.jpeg";
import { ReactComponent as FacebookIcon } from "../svgs/facebook.svg";
import { ReactComponent as InstagramIcon } from "../svgs/instagram.svg";
import { ReactComponent as LinkedinIcon } from "../svgs/linkedin.svg";
import { useSelector, useDispatch } from "react-redux";
import { getLeader } from "../state/actions/leaderActions";
import Loader from "../admin/components/Loader";
import Message from "../admin/components/Message";
import "../components/NavBar/NavBar.scss";
import { Helmet } from "react-helmet-async";

const Leader = ({ darkMode }) => {
  const dispatch = useDispatch();

  const [body, setBody] = useState("");
  const [fontSize, setFontSize] = useState(16);

  const [content, setContent] = useState("");

  const LeaderDetails = useSelector((state) => state.LeaderDetails);
  const { loading, error, leader } = LeaderDetails;

  useEffect(() => {
    dispatch(getLeader("62fd4930f4311f97266f08f3"));
    setBody(leader.body);
    setContent(leader.body);
  }, [dispatch, leader.body]);

  return (
    <>
      <Helmet>
        <title>Founder of BtekUp company</title>
        <meta
          name="description"
          content="Anthony Tannoury received his Ph.D degree in Wireless Multimedia Sensor Networks (WMSNs) and 3D scenes reconstruction in December 2018 from the Femto-St laboratory of the University of Franche-Comté (UFC), Belfort, France. He has an M.S degree in Multimedia and Networking Engineering from Université Antonine (UA), Hadat-Baabda, Lebanon since 2012. He holds a full time assistant professor position at UA and a researcher at the TICKET Lab since 2014. He currently serves as dean-delegate of the faculty of engineering at UA, Bekaa campus and head of graphics and advertising department, Baabda campus. His research is situated in the field of WMSNs, 3D scene reconstruction, GPS based mobiles applications with network optimization and 3D E-health Mixed Reality. Academic advisor and senior projects supervisor in the following fields: Augmented Reality, 2D/3D games, 3D simulations, Virtual Reality, Mixed Reality, interactive Internet of Things (IoT), Stereo Vision, E-Health, Mobile Applications and User Experience. He gives beginner and advanced courses on Mobile Applications, 3D Modeling, Web Design, Full Stack Web Development, User Experience (UX), User Interfacing (UI), Human Computer Interaction, Digital Lab, Infographics, Image Editing, Vector Arts, Multimedia Programming, Microsoft Office tool, and 2D/3D Animation. In parallel, in 2022, he joined Université Saint-Joseph (USJ) as part time lecturer for Big Data and Internet Programming. He has been involved as member and director in many collaborative research projects financed by Agence Universitaire de la Francophonie (AUF) (Francophonie University Association) and CEDRE. He is a part time trainer at Asher Center - USEK, Kaslik, Center of Continuing Education - UA, Baabda and Michel Daher Social Foundation (USAID) for: User Experience and User Interfacting (UX/UI), Product Design, Animation, Gaming and mobile."
        />
        <link rel="canonical" href={"/leader"} />
      </Helmet>

      <div className={`${styles.wrapper} ${darkMode ? styles.darkMode : ""}`}>
        <section className={styles.leader}>
          <img
            className={`${styles.leader__img} ${styles.display}`}
            src={LeaderImg}
            alt="the founder"
          />
          <div className={styles.leader__info}>
            <div className={styles.header}>
              <div className={styles.header__content}>
                <div className={styles.leader__title}>
                  <h1>
                    Meet <span>The Leader</span>
                  </h1>
                  <hr className={styles.line} />
                </div>
                <h2 className={styles.leader__subtitle}>Anthony Tannoury</h2>
                <h2 className={styles.leader__subtitleItalic}>
                  Founder of BtekUp
                </h2>
              </div>
              <div className={styles.header__icons}>
                <a
                  href="https://www.facebook.com/atannoury"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.header__icon}
                >
                  <div className={styles.icon}>
                    <FacebookIcon />
                  </div>
                </a>
                <a
                  href="https://instagram.com/anthonytannoury?igshid=YmMyMTA2M2Y="
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.header__icon}
                >
                  <div className={styles.icon}>
                    <InstagramIcon />
                  </div>
                </a>
                <a
                  href="https://lb.linkedin.com/in/anthony-tannoury-phd-eng-523a7a44"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.header__icon}
                >
                  <div className={styles.icon}>
                    <LinkedinIcon />
                  </div>
                </a>
              </div>
            </div>
            <div>
              <img
                className={`${styles.leader__img} ${styles.hide}`}
                src={LeaderImg}
                alt="founder of BtekUp"
              />
              <div className={styles.size__btn}>
                <div>
                  <button
                    onClick={() => setFontSize((prevSize) => prevSize + 2)}
                  >
                    <i className="fa fa-plus" aria-hidden="true"></i>
                  </button>
                </div>
                <div>
                  <button
                    onClick={() => setFontSize((prevSize) => prevSize - 2)}
                  >
                    <i className="fa fa-minus" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              {loading ? (
                <Loader />
              ) : error ? (
                <Message>{error}</Message>
              ) : (
                <p
                  style={{ fontSize: `${fontSize}px` }}
                  dangerouslySetInnerHTML={{ __html: body }}
                  className={styles.leader__text}
                ></p>
              )}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Leader;
