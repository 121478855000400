import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  Box,
  TextField,
  Typography,
  TextareaAutosize,
  FormControl,
  Grid,
  FormLabel,
  Fab,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import TableScreen from "./components/TableScreen";
import Message from "./components/Message";
import Loader from "./components/Loader";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { createPortfolio } from "../state/actions/portfolioActions";
import { listCategories } from "../state/actions/categoryActions";
import { PORTFOLIO_CREATE_RESET } from "../constants/portfolioConstants";

const PortfolioCreateScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const portfolioCreate = useSelector((state) => state.portfolioCreate);
  const { loading, error, success } = portfolioCreate;

  const categoryList = useSelector((state) => state.categoryList);
  const {
    categories,
    loading: loadingCategory,
    error: errorCategory,
  } = categoryList;

  const [category, setCategory] = useState([]);
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [laptop, setLaptop] = useState("");
  const [tablet, setTablet] = useState("");
  const [mobile, setMobile] = useState("");
  const [background, setBackground] = useState("");
  const [link, setLink] = useState("");
  const [uploadError, setUploadError] = useState("");

  const [firstImageSelected, setFirstImageSelected] = useState();
  const [secondImageSelected, setSecondImageSelected] = useState();
  const [thirdImageSelected, setThirdImageSelected] = useState();

  const uploadImage = async (selectedImage) => {
    const formData = new FormData();
    formData.append("file", selectedImage);
    formData.append("upload_preset", "bwiy0n2g");
    const { data } = await axios.post(
      "https://api.cloudinary.com/v1_1/detmdijki/image/upload",
      formData
    );
    return data.url;
    // images.push(data.url);
    // .then((res) =>
    //   setImages((prevState) => {
    //     return [...prevState, res.data.url];
    //   })
    // );
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    dispatch(
      createPortfolio({
        category,
        name,
        title,
        description,
        laptop,
        tablet,
        mobile,
        background,
        link,
      })
    );
  };

  const handleChange = (event, id) => {
    if (event.target.checked) {
      setCategory((prevState) => {
        return [...prevState, id];
      });
    } else {
      var filtered = category.filter(function (value, index, arr) {
        return value !== id;
      });
      setCategory(filtered);
    }
  };

  useEffect(() => {
    if (!userInfo) {
      history.push("/admin/login");
    } else if (success) {
      history.push("/admin/portfolio");
      dispatch({ type: PORTFOLIO_CREATE_RESET });
    } else {
      dispatch(listCategories());
    }
  }, [history, userInfo, success, dispatch]);

  const uploadLaptop = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "bwiy0n2g");
    try {
      const { data } = await axios.post(
        "https://api.cloudinary.com/v1_1/detmdijki/image/upload",
        formData
      );
      setLaptop(data.url);
    } catch (error) {
      setUploadError(error);
    }
  };

  const uploadTablet = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "bwiy0n2g");
    try {
      const { data } = await axios.post(
        "https://api.cloudinary.com/v1_1/detmdijki/image/upload",
        formData
      );
      setTablet(data.url);
    } catch (error) {
      setUploadError(error);
    }
  };

  const uploadMobile = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "bwiy0n2g");
    try {
      const { data } = await axios.post(
        "https://api.cloudinary.com/v1_1/detmdijki/image/upload",
        formData
      );
      setMobile(data.url);
    } catch (error) {
      setUploadError(error);
    }
  };

  const uploadBackground = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "bwiy0n2g");
    try {
      const { data } = await axios.post(
        "https://api.cloudinary.com/v1_1/detmdijki/image/upload",
        formData
      );
      setBackground(data.url);
    } catch (error) {
      setUploadError(error);
    }
  };

  return (
    <TableScreen>
      <Box height="60vh">
        <form onSubmit={submitHandler}>
          <Typography variant="h5" textAlign="center" sx={{ mb: 3 }}>
            Create new portfolio
          </Typography>

          <Grid container spacing={4}>
            <Grid item xs={3} />
            <Grid item xs={4}>
              <Box display="flex" flexWrap="wrap" justifyContent="start">
                <TextField
                  fullWidth
                  type="text"
                  id="outlined-basic"
                  label="Name"
                  variant="outlined"
                  // required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <TextField
                  sx={{ my: 2 }}
                  fullWidth
                  type="text"
                  id="outlined-basic"
                  label="Title"
                  variant="outlined"
                  // required
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <TextField
                  sx={{ my: 2 }}
                  fullWidth
                  type="text"
                  id="outlined-basic"
                  label="Link"
                  variant="outlined"
                  // required
                  value={link}
                  onChange={(e) => setLink(e.target.value)}
                />

                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={15}
                  style={{ width: 360 }}
                  placeholder="Enter description"
                  // required
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />

                <Fab
                  color="primary"
                  aria-label="add"
                  variant="extended"
                  sx={{ mt: 2 }}
                >
                  <AddIcon />
                  <label htmlFor="input1">
                    Background
                    <input
                      style={{ display: "none" }}
                      id="input1"
                      name="webGIF"
                      type="file"
                      accept="image/*"
                      onChange={uploadBackground}
                    />
                  </label>
                </Fab>

                <Fab
                  color="primary"
                  aria-label="add"
                  variant="extended"
                  sx={{ mt: 2, ml: 2 }}
                >
                  <AddIcon />
                  <label htmlFor="input1">
                    Web GIF
                    <input
                      style={{ display: "none" }}
                      id="input1"
                      name="webGIF"
                      type="file"
                      accept="image/gif"
                      onChange={uploadLaptop}
                    />
                  </label>
                </Fab>

                <Fab
                  color="primary"
                  aria-label="add"
                  variant="extended"
                  sx={{ mt: 2 }}
                >
                  <AddIcon />
                  <label htmlFor="input2">
                    Tablet GIF
                    <input
                      style={{ display: "none" }}
                      id="input2"
                      name="tabletGIF"
                      type="file"
                      accept="image/gif"
                      onChange={uploadTablet}
                    />
                  </label>
                </Fab>

                <Fab
                  color="primary"
                  aria-label="add"
                  variant="extended"
                  sx={{ mt: 2, ml: 2 }}
                >
                  <AddIcon />
                  <label htmlFor="input3">
                    Mobile GIF
                    <input
                      style={{ display: "none" }}
                      id="input3"
                      name="mobileGIF"
                      type="file"
                      accept="image/gif"
                      onChange={uploadMobile}
                    />
                  </label>
                </Fab>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <FormControl
                component="fieldset"
                variant="standard"
                sx={{ mb: 1 }}
              >
                <FormLabel component="legend">Categories</FormLabel>
                <FormGroup>
                  {errorCategory ? (
                    <Message>{errorCategory}</Message>
                  ) : loadingCategory ? (
                    <Loader />
                  ) : (
                    categories.map((category) => (
                      <FormControlLabel
                        key={category._id}
                        label={category.name}
                        control={
                          <Checkbox
                            name={category.name}
                            value={category.name}
                            onChange={(event) =>
                              handleChange(event, category._id)
                            }
                          />
                        }
                      />
                    ))
                  )}
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={2} />
          </Grid>

          <Box display="flex" justifyContent="center" mt={2}>
            {loading ? (
              <Loader />
            ) : error ? (
              <Message>{error}</Message>
            ) : (
              <Button
                type="submit"
                size="large"
                variant="contained"
                color="primary"
              >
                Create
              </Button>
            )}
          </Box>
        </form>
      </Box>
    </TableScreen>
  );
};

export default PortfolioCreateScreen;
