export const BLOG_CREATE_REQUEST = "BLOG_CREATE_REQUEST";
export const BLOG_CREATE_SUCCESS = "BLOG_CREATE_SUCCESS";
export const BLOG_CREATE_FAIL = "BLOG_CREATE_FAIL";
export const BLOG_CREATE_RESET = "BLOG_CREATE_RESET";

export const BLOG_LIST_REQUEST = "BLOG_LIST_REQUEST";
export const BLOG_LIST_SUCCESS = "BLOG_LIST_SUCCESS";
export const BLOG_LIST_FAIL = "BLOG_LIST_FAIL";

export const BLOG_UPDATE_REQUEST = "BLOG_UPDATE_REQUEST";
export const BLOG_UPDATE_SUCCESS = "BLOG_UPDATE_SUCCESS";
export const BLOG_UPDATE_FAIL = "BLOG_UPDATE_FAIL";
export const BLOG_UPDATE_RESET = "BLOG_UPDATE_RESET";

export const BLOG_DELETE_REQUEST = "BLOG_DELETE_REQUEST";
export const BLOG_DELETE_SUCCESS = "BLOG_DELETE_SUCCESS";
export const BLOG_DELETE_FAIL = "BLOG_DELETE_FAIL";

export const BLOG_DETAILS_REQUEST = "BLOG_DETAILS_REQUEST";
export const BLOG_DETAILS_SUCCESS = "BLOG_DETAILS_SUCCESS";
export const BLOG_DETAILS_FAIL = "BLOG_DETAILS_FAIL";
export const BLOG_DETAILS_RESET = "BLOG_DETAILS_RESET";

export const BLOGS_LIST_BY_CATEGORY_REQUEST = "BLOGS_LIST_BY_CATEGORY_REQUEST";
export const BLOGS_LIST_BY_CATEGORY_SUCCESS = "BLOGS_LIST_BY_CATEGORY_SUCCESS";
export const BLOGS_LIST_BY_CATEGORY_FAIL = "BLOGS_LIST_BY_CATEGORY_FAIL";
export const BLOGS_LIST_BY_CATEGORY_RESET = "BLOGS_LIST_BY_CATEGORY_RESET";

export const BLOG_CATEGORY_CREATE_REQUEST = "BLOG_CATEGORY_CREATE_REQUEST";
export const BLOG_CATEGORY_CREATE_SUCCESS = "BLOG_CATEGORY_CREATE_SUCCESS";
export const BLOG_CATEGORY_CREATE_FAIL = "BLOG_CATEGORY_CREATE_FAIL";

export const BLOG_CATEGORY_LIST_REQUEST = "BLOG_CATEGORY_LIST_REQUEST";
export const BLOG_CATEGORY_LIST_SUCCESS = "BLOG_CATEGORY_LIST_SUCCESS";
export const BLOG_CATEGORY_LIST_FAIL = "BLOG_CATEGORY_LIST_FAIL";

export const BLOG_CATEGORY_DELETE_REQUEST = "BLOG_CATEGORY_DELETE_REQUEST";
export const BLOG_CATEGORY_DELETE_SUCCESS = "BLOG_CATEGORY_DELETE_SUCCESS";
export const BLOG_CATEGORY_DELETE_FAIL = "BLOG_CATEGORY_DELETE_FAIL";

export const BLOG_CATEGORY_DETAILS_REQUEST = "BLOG_CATEGORY_DETAILS_REQUEST";
export const BLOG_CATEGORY_DETAILS_SUCCESS = "BLOG_CATEGORY_DETAILS_SUCCESS";
export const BLOG_CATEGORY_DETAILS_FAIL = "BLOG_CATEGORY_DETAILS_FAIL";
export const BLOG_CATEGORY_DETAILS_RESET = "BLOG_CATEGORY_DETAILS_RESET";

export const BLOG_CATEGORY_UPDATE_REQUEST = "BLOG_CATEGORY_UPDATE_REQUEST";
export const BLOG_CATEGORY_UPDATE_SUCCESS = "BLOG_CATEGORY_UPDATE_SUCCESS";
export const BLOG_CATEGORY_UPDATE_FAIL = "BLOG_CATEGORY_UPDATE_FAIL";
export const BLOG_CATEGORY_UPDATE_RESET = "BLOG_CATEGORY_UPDATE_RESET";
