import React, { Component } from "react";
import { ReactComponent as ArrowDown } from "../../svgs/down-bracket.svg";

// This is the component of CONTACT US
// The drop down menu is inside this button (check render())
class NavDropDownBtn extends Component {
  state = {
    title: this.props.title,
    dropDown: this.props.dropDown, // Array for the menu
    active: this.props.activeBtn === this.props.title,
  };
  constructor(props) {
    super(props);
    this.dropDownRef = React.createRef(); // Reference to the menu
  }
  // Set state based on props
  static getDerivedStateFromProps(props, state) {
    return { active: props.activeBtn === props.title };
  }
  // Set this button to active
  activate = (event, item) => {
    this.props.setActiveBtn(this.state.title);
    this.props.setVisibleForm(item);
    this.props.toggleLeaderAndBlog();
    const activeDropBtn =
      this.dropDownRef.current.querySelector(".active-drop-btn");
    if (activeDropBtn !== null) {
      activeDropBtn.classList.remove("active-drop-btn");
    }
    event.target.classList.add("active-drop-btn");
  };
  // Called whenever this component gets updated
  componentDidUpdate() {
    const activeDropBtn =
      this.dropDownRef.current.querySelector(".active-drop-btn");
    if (activeDropBtn !== null) {
      if (this.props.activeBtn !== this.state.title) {
        activeDropBtn.classList.remove("active-drop-btn");
      }
    }
  }
  // Render to the DOM
  render() {
    return (
      <li className="nav-btn nav-drop-btn">
        <button ref={this.state.active ? this.props.activeBtnRef : ""}>
          {this.state.title}
          <ArrowDown />
        </button>

        <ul className="drop-down-menu" ref={this.dropDownRef}>
          {this.state.dropDown.map((item) => {
            return (
              <li key={item}>
                <button
                  className="drop-down-btn"
                  onClick={(event) => this.activate(event, item)}
                >
                  {item}
                </button>
              </li>
            );
          })}
        </ul>
      </li>
    );
  }
}

export default NavDropDownBtn;
