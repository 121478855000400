import { createStore, applyMiddleware, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import {
  blogCategoryCreateReducer,
  blogCategoryDeleteReducer,
  blogCategoryDetailsReducer,
  blogCategoryListReducer,
  blogCategoryUpdateReducer,
  blogCreateReducer,
  blogDeleteReducer,
  blogDetailsReducer,
  blogEditReducer,
  blogListByCategoryReducer,
  blogListReducer,
} from "./reducers/blogReducer";
import {
  categoryCreateReducer,
  categoryDeleteReducer,
  categoryDetailsReducer,
  categoryListReducer,
  categoryUpdateReducer,
} from "./reducers/categoryReducers";
import {
  contactCreateReducer,
  contactDeleteReducer,
  contactListReducer,
} from "./reducers/contactReducers";
import {
  joinOurTeamCreateReducer,
  joinOurTeamDeleteReducer,
  joinOurTeamListReducer,
} from "./reducers/joinOurTeamReducer";
import {
  // getLeaderReducer,
  leaderCreateReducer,
  LeaderDetailsReducer,
  leaderEditReducer,
} from "./reducers/leaderReducer";
import {
  portfolioCreateReducer,
  portfolioDeleteReducer,
  portfolioDetailsReducer,
  portfolioEditReducer,
  portfolioListByCategoryReducer,
  portfolioListReducer,
} from "./reducers/portfolioReducers";
import {
  startYourProjectCreateReducer,
  startYourProjectDelteReducer,
  startYourProjectDetailReducer,
  startYourProjectListReducer,
} from "./reducers/startYourProjectReducer";
import { userLoginReducer, darkModeReducer } from "./reducers/userReducers";
import {
  whatWeDoCreateReducer,
  whatWeDoDetailsReducer,
  whatWeDoListReducer,
  WhatWeDoEditScreenReducer,
  whatWeDoDeleteReducer,
} from "./reducers/whatWeDoReducers";
import {
  authorCreateReducer,
  authorDeleteReducer,
  authorDetailsReducer,
  authorListReducer,
  authorUpdateReducer,
} from "./reducers/authorReducers";

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const reducer = combineReducers({
  userLogin: userLoginReducer,
  darkMode: darkModeReducer,
  //
  contactCreate: contactCreateReducer,
  contactList: contactListReducer,
  contactDelete: contactDeleteReducer,
  //
  startYourProjectCreate: startYourProjectCreateReducer,
  startYourProjectList: startYourProjectListReducer,
  startYourProjectDetail: startYourProjectDetailReducer,
  startYourProjectDelete: startYourProjectDelteReducer,
  //
  joinOurTeamCreate: joinOurTeamCreateReducer,
  joinOurTeamList: joinOurTeamListReducer,
  joinOurTeamDelete: joinOurTeamDeleteReducer,
  //
  whatWeDoCreate: whatWeDoCreateReducer,
  whatWeDoList: whatWeDoListReducer,
  WhatWeDoEditScreen: WhatWeDoEditScreenReducer,
  whatWeDoDetails: whatWeDoDetailsReducer,
  whatWeDoDelete: whatWeDoDeleteReducer,
  //
  portfolioCreate: portfolioCreateReducer,
  portfolioList: portfolioListReducer,
  portfolioUpdate: portfolioEditReducer,
  portfolioDelete: portfolioDeleteReducer,
  portfolioDetails: portfolioDetailsReducer,
  portfolioListByCategory: portfolioListByCategoryReducer,
  //
  categoryCreate: categoryCreateReducer,
  categoryList: categoryListReducer,
  categoryDelete: categoryDeleteReducer,
  categoryDetails: categoryDetailsReducer,
  categoryUpdate: categoryUpdateReducer,
  //
  blogCreate: blogCreateReducer,
  blogList: blogListReducer,
  blogEdit: blogEditReducer,
  blogDetails: blogDetailsReducer,
  blogDelete: blogDeleteReducer,
  //
  blogCategoryCreate: blogCategoryCreateReducer,
  blogCategoryList: blogCategoryListReducer,
  blogCategoryDelete: blogCategoryDeleteReducer,
  blogCategoryDetails: blogCategoryDetailsReducer,
  blogCategoryUpdate: blogCategoryUpdateReducer,
  blogListByCategory: blogListByCategoryReducer,
  //
  leaderCreate: leaderCreateReducer,
  leaderEdit: leaderEditReducer,
  LeaderDetails: LeaderDetailsReducer,
  //
  authorCreate: authorCreateReducer,
  authorList: authorListReducer,
  authorDelete: authorDeleteReducer,
  authorDetails: authorDetailsReducer,
  authorUpdate: authorUpdateReducer,
});

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
