import React, { Component } from "react";
import { ReactComponent as ToggleBtn } from "../../svgs/toggle-btn-off.svg";

// The toggle button in the navigation bar
class ModeBtn extends Component {
  render() {
    return (
      <li
        className={`toggle-btn ${
          this.props.darkMode ? "toggle-btn-on" : "toggle-btn-off"
        }`}
        onClick={this.props.toggleDarkMode}
      >
        <ToggleBtn />
      </li>
    );
  }
}

export default ModeBtn;
