import { ReactComponent as Mobwho } from "../../../svgs/whoMob.svg";
import { ReactComponent as MobwhoD } from "../../../svgs/whoMobD.svg";
import classes from "./mobile.module.scss";
import React, { Component } from "react";

class Mobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      darkMode: this.props.darkMode,
    };
  }
  render() {
    return (
      <>
        <div id="divsectortitle" >
            <span
              id="sectortitle" className={`${this.props.darkMode ? "dark-mode" : ""}`}
             
            >
              Who We Are
            </span>
          </div>
        <div className={classes.image}>
          {this.props.darkMode && <MobwhoD />}
          {!this.props.darkMode && <Mobwho />}
        </div>
      </>
    );
  }
}

export default Mobile;
