import React, { Component } from "react";
import "./LetsStartYourProject.scss";
import StartYourProjectForm from "./StartYourProjectForm";
import logo from "../../svgs/logo.svg";
class LetsStartYourProject extends Component {
  render() {
    return (
      <section
        id="LetsStartYourProject"
        className={`${this.props.darkMode ? "dark-mode" : ""}`}
        ref={
          this.props.currentSection === "CONTACT US" &&
          this.props.form === "Start a Project"
            ? this.props.currentSectionRef
            : ""
        }
      >
        <div className="form">
          <h1
            id="title"
            className={`${this.props.darkMode ? "dark-mode" : ""}`}
          >
            Let's Start Your Project
          </h1>
          <h4 className="title2">
            Your Way Up{" "}
            <img
              className="img"
              src={logo}
              alt=""
              height="120"
              width="120"
            ></img>{" "}
          </h4>
          <StartYourProjectForm />
        </div>
      </section>
    );
  }
}

export default LetsStartYourProject;
