import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getStartYourPorjectDetails } from "../state/actions/startYourProjectActions";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material";
import TableScreen from "./components/TableScreen";
import Loader from "./components/Loader";
import Message from "./components/Message";

const ProjectDetailScreen = () => {
  const history = useHistory();
  let { id } = useParams();
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const startYourProjectDetail = useSelector(
    (state) => state.startYourProjectDetail
  );
  const { project, loading, error } = startYourProjectDetail;
  console.log(project);

  const [fullName, setFullName] = useState("");
  const [compayName, setCompayName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [budget, setBudget] = useState("");
  const [projectType, setProjectType] = useState("");
  const [timeFrame, setTimeFrame] = useState("");
  const [hearAboutUs, setHearAboutUs] = useState("");
  const [details, setDetails] = useState("");

  useEffect(() => {
    if (userInfo) {
      if (!project.full_name || project._id !== id) {
        dispatch(getStartYourPorjectDetails(id));
      } else {
        setFullName(project.full_name);
        setCompayName(project.company_name);
        setEmail(project.email);
        setPhoneNumber(project.phone_number);
        setBudget(project.budget);
        setProjectType(project.project_type);
        setTimeFrame(project.time_frame);
        setHearAboutUs(project.hear_about_us);
        setDetails(project.details);
      }
    } else {
      history.push("/admin/login");
    }
  }, [history, userInfo, id, project, dispatch]);

  return (
    <TableScreen>
      <TableContainer component={Paper}>
        <Table>
          {loading ? (
            <Loader />
          ) : error ? (
            <Message>{error}</Message>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell>Project Name</TableCell>
                <TableCell>{fullName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Company Name</TableCell>
                <TableCell>{compayName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>{email}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Phone Number</TableCell>
                <TableCell>{phoneNumber}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Budget</TableCell>
                <TableCell>{budget}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Project Type</TableCell>
                <TableCell>{projectType}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Time Frame</TableCell>
                <TableCell>{timeFrame}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Heard About Us</TableCell>
                <TableCell>{hearAboutUs}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Details</TableCell>
                <TableCell>{details}</TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </TableScreen>
  );
};

export default ProjectDetailScreen;
