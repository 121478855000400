export const AUTHOR_CREATE_REQUEST = "AUTHOR_CREATE_REQUEST";
export const AUTHOR_CREATE_SUCCESS = "AUTHOR_CREATE_SUCCESS";
export const AUTHOR_CREATE_FAIL = "AUTHOR_CREATE_FAIL";

export const AUTHOR_LIST_REQUEST = "AUTHOR_LIST_REQUEST";
export const AUTHOR_LIST_SUCCESS = "AUTHOR_LIST_SUCCESS";
export const AUTHOR_LIST_FAIL = "AUTHOR_LIST_FAIL";

export const AUTHOR_DELETE_REQUEST = "AUTHOR_DELETE_REQUEST";
export const AUTHOR_DELETE_SUCCESS = "AUTHOR_DELETE_SUCCESS";
export const AUTHOR_DELETE_FAIL = "AUTHOR_DELETE_FAIL";

export const AUTHOR_DETAILS_REQUEST = "AUTHOR_DETAILS_REQUEST";
export const AUTHOR_DETAILS_SUCCESS = "AUTHOR_DETAILS_SUCCESS";
export const AUTHOR_DETAILS_FAIL = "AUTHOR_DETAILS_FAIL";
export const AUTHOR_DETAILS_RESET = "AUTHOR_DETAILS_RESET";

export const AUTHOR_UPDATE_REQUEST = "AUTHOR_UPDATE_REQUEST";
export const AUTHOR_UPDATE_SUCCESS = "AUTHOR_UPDATE_SUCCESS";
export const AUTHOR_UPDATE_FAIL = "AUTHOR_UPDATE_FAIL";
export const AUTHOR_UPDATE_RESET = "AUTHOR_UPDATE_RESET";
