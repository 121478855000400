import React, { Component } from "react";
import "./desktop.module.scss";
import classes from "./desktop.module.scss";
import Circlesvg from "./animations/circle";
import CirclesvgD from "./animations/circleD";
import Polygonsvg from "./animations/polygonD";
import PolygonsvgD from "./animations/polygon";
import { ReactComponent as WhoLap } from "../../../svgs/whoLap.svg";
import { ReactComponent as Wtext } from "../../../svgs/text.svg";
import { ReactComponent as Rec } from "../../../svgs/rec.svg";
import { ReactComponent as WhoLapD } from "../../../svgs/whoLapD.svg";
import { ReactComponent as WtextD } from "../../../svgs/textD.svg";

class Desktop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      darkMode: this.props.darkMode,
    };
  }
  render() {
    return (
      <section className={classes.Whowe}>
        {this.props.darkMode && (
          <div className={classes.sec1}>
            <div className={classes.parent}>
              <PolygonsvgD />
            </div>
            <div className={classes.child}>
              <WhoLapD />
            </div>
            <div className={classes.child}>
              <CirclesvgD />
            </div>
            <div className={classes.child}>
              <WtextD />
            </div>
            <Rec />
          </div>
        )}

        {!this.props.darkMode && (
          <div className={classes.sec1}>
            <div className={classes.parent}>
              <Polygonsvg />
            </div>
            <div className={classes.child}>
              <WhoLap />
            </div>
            <div className={classes.child}>
              <Circlesvg />
            </div>
            <div className={classes.child}>
              <Wtext />
            </div>
            <Rec />
          </div>
        )}
      </section>
    );
  }
}
export default Desktop;
