import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../state/actions/userActions";
import { Button, TextField, Typography, Box } from "@mui/material";
import Message from "./components/Message";
import Loader from "./components/Loader";

const LoginScreen = ({ history }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;

  useEffect(() => {
    if (userInfo) {
      history.push("/admin");
    }
  }, [history, userInfo]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(login(email, password));
  };
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <form onSubmit={submitHandler}>
        <Box
          display="flex"
          flexDirection="column"
          height="33vh"
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5">Please Sign In</Typography>
          <TextField
            type="email"
            id="outlined-basic"
            label="Email"
            variant="outlined"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            type="password"
            id="outlined-basic"
            label="password"
            variant="outlined"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            size="large"
            variant="contained"
            color="primary"
          >
            Login
          </Button>
        </Box>
        {error && <Message>{error}</Message>} {loading && <Loader />}
      </form>
    </Box>
    // <div className="form-wrapper">
    //   <h1 className="form__title">Sign In</h1>
    //   <form onSubmit={submitHandler}>
    //     <div className="form__item">
    //       <label for="email"></label>
    //       <input
    //         type="email"
    //         name="email"
    //         required="required"
    //         placeholder="Email Address"
    //         value={email}
    //         onChange={(e) => setEmail(e.target.value)}
    //       />
    //     </div>
    //     <div className="form__item">
    //       <label for="password"></label>
    //       <input
    //         type="password"
    //         name="password"
    //         required="required"
    //         placeholder="Password"
    //         value={password}
    //         onChange={(e) => setPassword(e.target.value)}
    //       />
    //     </div>
    //     <div className="form__button">
    //       <input
    //         type="submit"
    //         className="button"
    //         title="Sign In"
    //         value="Sign In"
    //       />
    //     </div>
    //   </form>
    //   <div className="form__footer"></div>
    // </div>
  );
};

export default LoginScreen;
