import React from "react";
import { ReactComponent as CloseBtn } from "../../../svgs/close-btn.svg";
import { ReactComponent as ArrowDown } from "../../../svgs/down-bracket.svg";
import { useHistory } from "react-router";
import "../../../components/NavBar/NavBar.scss";

const NewMobileNavBar = ({ darkMode, isMenuVisible, setIsMenuVisible }) => {
  const history = useHistory();
  return (
    <div
      className={`mobile-nav-menu ${
        isMenuVisible ? "visible-menu" : "invisible-menu"
      } ${darkMode ? "dark-mode" : ""}`}
    >
      <ul>
        <li className="nav-btn">
          <button
            onClick={() => {
              history.push("/#home");
              setIsMenuVisible(false);
            }}
          >
            HOME
          </button>
        </li>
        <li className="nav-btn">
          <button
            onClick={() => {
              history.push("/#-we-are");
              setIsMenuVisible(false);
            }}
          >
            WHO WE ARE
          </button>
        </li>
        <li className="nav-btn">
          <button
            onClick={() => {
              history.push("/leader");
              setIsMenuVisible(false);
            }}
          >
            LEADER
          </button>
        </li>
        <li className="nav-btn">
          <button
            onClick={() => {
              history.push("/#what-we-do");
              setIsMenuVisible(false);
            }}
          >
            WHAT WE DO
          </button>
        </li>
        <li className="nav-btn">
          <button
            onClick={() => {
              history.push("/#skills");
              setIsMenuVisible(false);
            }}
          >
            SKILLS
          </button>
        </li>
        <li
          className="nav-btn"
          onClick={() => {
            history.push("/#portfolio");
            setIsMenuVisible(false);
          }}
        >
          <button>PORTFOLIO</button>
        </li>
        {/* dropdown */}
        <li className="nav-btn nav-drop-btn">
          <button>
            CONTACT US <ArrowDown />
          </button>
          <ul className="drop-down-menu">
            <li>
              <button
                className="drop-down-btn"
                onClick={() => {
                  history.push("/#send-a-message");
                  setIsMenuVisible(false);
                }}
              >
                Send a Message
              </button>
            </li>
            <li>
              <button
                className="drop-down-btn"
                onClick={() => {
                  history.push("/#send-a-message");
                  setIsMenuVisible(false);
                }}
              >
                Start a Project
              </button>
            </li>
          </ul>
        </li>
        {/* end dropdown */}
        <li
          className="nav-btn"
          onClick={() => {
            history.push("/#careers");
            setIsMenuVisible(false);
          }}
        >
          <button>CAREERS</button>
        </li>
        <li
          className="nav-btn"
          onClick={() => {
            history.push("/blog");
            setIsMenuVisible(false);
          }}
        >
          <button>Blog</button>
        </li>
        <li className="close-btn" onClick={() => setIsMenuVisible(false)}>
          <CloseBtn />
        </li>
      </ul>
    </div>
  );
};

export default NewMobileNavBar;
