import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../admin/components/Loader";
import Message from "../../admin/components/Message";
import { listWhatWeDo } from "../../state/actions/whatWeDoactions";
import "./WhatWeDo.scss";
import image1 from "../../svgs/whatwedo.jpg";
import Popup from "./Popup";
import { Helmet } from "react-helmet-async";

export default function WhatWeDid() {
  const dispatch = useDispatch();

  const [array, setArray] = useState([]);

  const whatWeDoList = useSelector((state) => state.whatWeDoList);
  const { whatWeDos, loading, error } = whatWeDoList;

  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const [description, setDescription] = useState("");

  useEffect(() => {
    dispatch(listWhatWeDo());
  }, [dispatch]);
  return (
    <>
      <Helmet>
        <title>BtekUp</title>
        <meta
          name="description"
          content="services provided by BtekUp: Web Dev, App Dev, VR, AR, IOT, UI/UX, 2D/3D GAMES, MICRO, Database Design, Consultancy and Support, Graphic Design and Animation, Digital Marketing"
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <div className="back-image">
        <img src={image1} alt="" />
        <div className="blur-filter"></div>
      </div>
      <div className="div8">
        <p>WHAT WE DO</p>
      </div>
      <div className="divvv">
        {loading ? (
          <Loader />
        ) : error ? (
          <Message>{error}</Message>
        ) : (
          <>
            {whatWeDos.length !== 0 && (
              <>
                <div className="div22 " data-aos="fade-in" data-aos-once="true">
                  <div className="div55">
                    <div className="div11">
                      {" "}
                      <img
                        className={whatWeDos[0].description ? "img" : ""}
                        src={whatWeDos[0].image}
                        alt={whatWeDos[0].description}
                      />
                      {whatWeDos[0].description && (
                        <div className="text">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: whatWeDos[0].description,
                            }}
                          ></p>
                        </div>
                      )}
                    </div>
                    <div
                      className={`div33 ${
                        whatWeDos[0].description ? "title" : ""
                      }`}
                    >
                      {whatWeDos[0].name}
                    </div>
                  </div>
                  <div className="div55">
                    <div className="div11">
                      {" "}
                      <img
                        className={whatWeDos[1].description ? "img" : ""}
                        src={whatWeDos[1].image}
                        alt={whatWeDos[1].description}
                      />
                      {whatWeDos[1].description && (
                        <div className="text">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: whatWeDos[1].description,
                            }}
                          ></p>
                        </div>
                      )}
                    </div>
                    <div
                      className={`div33 ${
                        whatWeDos[1].description ? "title" : ""
                      }`}
                    >
                      {whatWeDos[1].name}
                    </div>
                  </div>
                  <div className="div55">
                    <div className="div11">
                      {" "}
                      <img
                        className={whatWeDos[2].description ? "img" : ""}
                        src={whatWeDos[2].image}
                        alt={whatWeDos[2].description}
                      />
                      {whatWeDos[2].description && (
                        <div className="text">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: whatWeDos[2].description,
                            }}
                          ></p>
                        </div>
                      )}
                    </div>
                    <div
                      className={`div33 ${
                        whatWeDos[2].description ? "title" : ""
                      }`}
                    >
                      {whatWeDos[2].name}
                    </div>
                  </div>
                  <div className="div55">
                    <div className="div11">
                      {" "}
                      <img
                        className={whatWeDos[3].description ? "img" : ""}
                        src={whatWeDos[3].image}
                        alt={whatWeDos[3].description}
                      />
                      {whatWeDos[3].description && (
                        <div className="text">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: whatWeDos[3].description,
                            }}
                          ></p>
                        </div>
                      )}
                    </div>
                    <div
                      className={`div33 ${
                        whatWeDos[3].description ? "title" : ""
                      }`}
                    >
                      {whatWeDos[3].name}
                    </div>
                  </div>
                </div>

                <div className="div22 " data-aos="fade-in" data-aos-once="true">
                  <div className="div55">
                    <div className="div11">
                      {" "}
                      <img
                        className={whatWeDos[4].description ? "img" : ""}
                        src={whatWeDos[4].image}
                        alt={whatWeDos[4].description}
                      />
                      {whatWeDos[4].description && (
                        <div className="text">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: whatWeDos[4].description,
                            }}
                          ></p>
                        </div>
                      )}
                    </div>
                    <div
                      className={`div33 ${
                        whatWeDos[4].description ? "title" : ""
                      }`}
                    >
                      {whatWeDos[4].name}
                    </div>
                  </div>

                  <div className="div55">
                    <div className="div11">
                      {" "}
                      <img
                        className={whatWeDos[5].description ? "img" : ""}
                        src={whatWeDos[5].image}
                        alt={whatWeDos[5].description}
                      />
                      {whatWeDos[5].description && (
                        <div className="text">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: whatWeDos[5].description,
                            }}
                          ></p>
                        </div>
                      )}
                    </div>
                    <div
                      className={`div33 ${
                        whatWeDos[5].description ? "title" : ""
                      }`}
                    >
                      {whatWeDos[5].name}
                    </div>
                  </div>
                  <div className="div55">
                    <div className="div11">
                      {" "}
                      <img
                        className={whatWeDos[6].description ? "img" : ""}
                        src={whatWeDos[6].image}
                        alt={whatWeDos[6].description}
                      />
                      {whatWeDos[6].description && (
                        <div className="text">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: whatWeDos[6].description,
                            }}
                          ></p>
                        </div>
                      )}
                    </div>
                    <div
                      className={`div33 ${
                        whatWeDos[6].description ? "title" : ""
                      }`}
                    >
                      {whatWeDos[6].name}
                    </div>
                  </div>
                  <div className="div55">
                    <div className="div11">
                      {" "}
                      <img
                        className={whatWeDos[7].description ? "img" : ""}
                        src={whatWeDos[7].image}
                        alt={whatWeDos[7].description}
                      />
                      {whatWeDos[7].description && (
                        <div className="text">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: whatWeDos[7].description,
                            }}
                          ></p>
                        </div>
                      )}
                    </div>
                    <div
                      className={`div33 ${
                        whatWeDos[7].description ? "title" : ""
                      }`}
                    >
                      {whatWeDos[7].name}
                    </div>
                  </div>
                </div>

                <div className="div22 " data-aos="fade-in" data-aos-once="true">
                  <div className="div55">
                    <div className="div11">
                      {" "}
                      <img
                        className={whatWeDos[8].description ? "img" : ""}
                        src={whatWeDos[8].image}
                        alt={whatWeDos[8].description}
                      />
                      {whatWeDos[8].description && (
                        <div className="text">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: whatWeDos[8].description,
                            }}
                          ></p>
                        </div>
                      )}
                    </div>
                    <div
                      className={`div33 ${
                        whatWeDos[8].description ? "title" : ""
                      }`}
                    >
                      {whatWeDos[8].name}
                    </div>
                  </div>

                  <div className="div55">
                    <div className="div11">
                      {" "}
                      <img
                        className={whatWeDos[9].description ? "img" : ""}
                        src={whatWeDos[9].image}
                        alt={whatWeDos[9].description}
                      />
                      {whatWeDos[9].description && (
                        <div className="text">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: whatWeDos[9].description,
                            }}
                          ></p>
                        </div>
                      )}
                    </div>
                    <div
                      className={`div33 ${
                        whatWeDos[9].description ? "title" : ""
                      }`}
                    >
                      {whatWeDos[9].name}
                    </div>
                  </div>
                  <div className="div55">
                    <div className="div11">
                      {" "}
                      <img
                        className={whatWeDos[10].description ? "img" : ""}
                        src={whatWeDos[10].image}
                        alt={whatWeDos[10].description}
                      />
                      {whatWeDos[10].description && (
                        <div className="text">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: whatWeDos[10].description,
                            }}
                          ></p>
                        </div>
                      )}
                    </div>
                    <div
                      className={`div33 ${
                        whatWeDos[10].description ? "title" : ""
                      }`}
                    >
                      {whatWeDos[10].name}
                    </div>
                  </div>
                  <div className="div55">
                    <div className="div11">
                      {" "}
                      <img
                        className={whatWeDos[11].description ? "img" : ""}
                        src={whatWeDos[11].image}
                        alt={whatWeDos[11].description}
                      />
                      {whatWeDos[11].description && (
                        <div className="text">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: whatWeDos[11].description,
                            }}
                          ></p>
                        </div>
                      )}
                    </div>
                    <div
                      className={`div33 ${
                        whatWeDos[11].description ? "title" : ""
                      }`}
                    >
                      {whatWeDos[11].name}
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>

      <div className="divvv2">
        {whatWeDos && whatWeDos.length !== 0 && (
          <>
            <div className="div222">
              <div
                className="div555"
                onClick={
                  !whatWeDos[0].description
                    ? () => undefined
                    : () => {
                        setDescription(whatWeDos[0].description);
                        togglePopup();
                      }
                }
              >
                <div className="div111">
                  {" "}
                  <img
                    src={whatWeDos[0].image}
                    alt={whatWeDos[0].description}
                  />
                </div>
                <div className="div333">{whatWeDos[0].name}</div>
              </div>
              <div
                className="div555"
                onClick={
                  !whatWeDos[1].description
                    ? () => undefined
                    : () => {
                        setDescription(whatWeDos[1].description);
                        togglePopup();
                      }
                }
              >
                <div className="div111">
                  {" "}
                  <img
                    src={whatWeDos[1].image}
                    alt={whatWeDos[1].description}
                  />
                </div>
                <div className="div333">{whatWeDos[1].name}</div>
              </div>
              <div
                className="div555"
                onClick={
                  !whatWeDos[2].description
                    ? () => undefined
                    : () => {
                        setDescription(whatWeDos[2].description);
                        togglePopup();
                      }
                }
              >
                <div className="div111">
                  {" "}
                  <img
                    src={whatWeDos[2].image}
                    alt={whatWeDos[2].description}
                  />
                </div>
                <div className="div333">{whatWeDos[2].name}</div>
              </div>
            </div>

            <div className="div222 " data-aos="fade-in" data-aos-once="true">
              <div
                className="div555"
                onClick={
                  !whatWeDos[3].description
                    ? () => undefined
                    : () => {
                        setDescription(whatWeDos[3].description);
                        togglePopup();
                      }
                }
              >
                <div className="div111">
                  {" "}
                  <img
                    src={whatWeDos[3].image}
                    alt={whatWeDos[3].description}
                  />
                </div>
                <div className="div333">{whatWeDos[3].name}</div>
              </div>

              <div
                className="div555"
                onClick={
                  !whatWeDos[4].description
                    ? () => undefined
                    : () => {
                        setDescription(whatWeDos[4].description);
                        togglePopup();
                      }
                }
              >
                <div className="div111">
                  {" "}
                  <img
                    src={whatWeDos[4].image}
                    alt={whatWeDos[4].description}
                  />
                </div>
                <div className="div333">{whatWeDos[4].name}</div>
              </div>
              <div
                className="div555"
                onClick={
                  !whatWeDos[5].description
                    ? () => undefined
                    : () => {
                        setDescription(whatWeDos[5].description);
                        togglePopup();
                      }
                }
              >
                <div className="div111">
                  {" "}
                  <img
                    src={whatWeDos[5].image}
                    alt={whatWeDos[5].description}
                  />
                </div>
                <div className="div333">{whatWeDos[5].name}</div>
              </div>
            </div>
            <div className="div222 " data-aos="fade-in" data-aos-once="true">
              <div
                className="div555"
                onClick={
                  !whatWeDos[6].description
                    ? () => undefined
                    : () => {
                        setDescription(whatWeDos[6].description);
                        togglePopup();
                      }
                }
              >
                <div className="div111">
                  {" "}
                  <img
                    src={whatWeDos[6].image}
                    alt={whatWeDos[6].description}
                  />
                </div>
                <div className="div333">{whatWeDos[6].name}</div>
              </div>
              <div
                className="div555"
                onClick={
                  !whatWeDos[7].description
                    ? () => undefined
                    : () => {
                        setDescription(whatWeDos[7].description);
                        togglePopup();
                      }
                }
              >
                <div className="div111">
                  {" "}
                  <img
                    src={whatWeDos[7].image}
                    alt={whatWeDos[7].description}
                  />
                </div>
                <div className="div333">{whatWeDos[7].name}</div>
              </div>

              <div
                className="div555"
                onClick={
                  !whatWeDos[8].description
                    ? () => undefined
                    : () => {
                        setDescription(whatWeDos[8].description);
                        togglePopup();
                      }
                }
              >
                <div className="div111">
                  {" "}
                  <img
                    src={whatWeDos[8].image}
                    alt={whatWeDos[8].description}
                  />
                </div>
                <div className="div333">{whatWeDos[8].name}</div>
              </div>
            </div>

            <div className="div222 " data-aos="fade-in" data-aos-once="true">
              <div
                className="div555"
                onClick={
                  !whatWeDos[9].description
                    ? () => undefined
                    : () => {
                        setDescription(whatWeDos[9].description);
                        togglePopup();
                      }
                }
              >
                <div className="div11">
                  {" "}
                  <img
                    src={whatWeDos[9].image}
                    alt={whatWeDos[9].description}
                  />
                </div>
                <div className="div333">{whatWeDos[9].name}</div>
              </div>
              <div
                className="div555"
                onClick={
                  !whatWeDos[10].description
                    ? () => undefined
                    : () => {
                        setDescription(whatWeDos[10].description);
                        togglePopup();
                      }
                }
              >
                <div className="div11">
                  {" "}
                  <img
                    src={whatWeDos[10].image}
                    alt={whatWeDos[10].description}
                  />
                </div>
                <div className="div333">{whatWeDos[10].name}</div>
              </div>
              <div
                className="div555"
                onClick={
                  !whatWeDos[11].description
                    ? () => undefined
                    : () => {
                        setDescription(whatWeDos[11].description);
                        togglePopup();
                      }
                }
              >
                <div className="div11">
                  {" "}
                  <img
                    src={whatWeDos[11].image}
                    alt={whatWeDos[11].description}
                  />
                </div>
                <div className="div333">{whatWeDos[11].name}</div>
              </div>
            </div>
          </>
        )}
        {isOpen && <Popup content={description} handleClose={togglePopup} />}
      </div>
    </>
  );
}
