import React, { Component } from "react";
import "./Skillssection.scss";
import Skillss from "./Skillsimage";
class ContentDiv extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "Software Engineering",
      submessage1: "Android",
      submessage2: "IOS",
      submessage3: "CROSS-PLATFORM",
      submessage4: "Python",
      submessage5: "React Native",
      submessage6: "Flutter",
      submessage7: ".NET",
      submessage8: "Data Analytics",
      submessage9: "Object-Oriented",
      submessage10: "JAVA",
      submessage11: "C++/C#",
      submessage12: "",
      darkMode: this.props.darkMode,
    };
  }

  updateContent1 = () => {
    this.setState({ message: "Software Engineering" });
    this.setState({ submessage1: "Android" });
    this.setState({ submessage2: "IOS" });
    this.setState({ submessage3: "CROSS-PLATFORM" });
    this.setState({ submessage4: "Python" });
    this.setState({ submessage5: "React Native" });
    this.setState({ submessage6: "Flutter" });
    this.setState({ submessage7: ".NET" });
    this.setState({ submessage8: "Data Analytics" });
    this.setState({ submessage9: "Object-Oriented" });
    this.setState({ submessage10: "JAVA" });
    this.setState({ submessage11: "C++/C#" });
    this.setState({ submessage12: "" });

    this.refs.child.updatecolor3();
  };
  updateContent2 = () => {
    this.setState({ message: "Multimedia Engineering" });

    this.setState({ submessage1: "HTML5, CSS, JS, PHP, REACT, Angular" });
    this.setState({ submessage2: "Website Enhancement" });
    this.setState({ submessage3: "Augmented and Virtual Reality" });
    this.setState({ submessage4: "Simulators" });
    this.setState({ submessage5: "Serious Games" });
    this.setState({ submessage6: "2D/3D Games" });
    this.setState({ submessage7: "3D Modeling" });
    this.setState({ submessage8: "Adobe Creative Cloud Collection" });
    this.setState({ submessage9: "SEO analysis & Strategies" });
    this.setState({ submessage10: "Content Creation" });
    this.setState({ submessage11: "Digital Marketing" });
    this.setState({ submessage12: "Social Media Management & Strategies" });

    this.refs.child.updatecolor4();
  };
  updateContent3 = () => {
    this.setState({ message: "System Engineering" });

    this.setState({ submessage1: "Database Design" });
    this.setState({ submessage2: "SQLite" });
    this.setState({ submessage3: "JSON" });
    this.setState({ submessage4: "Security" });
    this.setState({ submessage5: "Backend Development" });
    this.setState({ submessage6: "MongoDB" });
    this.setState({ submessage7: "Network Design" });
    this.setState({ submessage8: "Web Services" });
    this.setState({ submessage9: "" });
    this.setState({ submessage10: "" });
    this.setState({ submessage11: "" });
    this.setState({ submessage12: "" });

    this.refs.child.updatecolor1();
  };
  updateContent4 = () => {
    this.setState({ message: "Telecommunication Engineering" });

    this.setState({ submessage1: "IOT" });
    this.setState({ submessage2: "Sensor Networks" });
    this.setState({ submessage3: "Microcontrollers" });
    this.setState({ submessage4: "Arduino" });
    this.setState({ submessage5: "Raspberry Pi" });
    this.setState({ submessage6: "Image Processing" });
    this.setState({ submessage7: "Drone Programming" });
    this.setState({ submessage8: "Antennas and Satellites" });
    this.setState({ submessage9: "" });
    this.setState({ submessage10: "" });
    this.setState({ submessage11: "" });
    this.setState({ submessage12: "" });

    this.refs.child.updatecolor2();
  };

  render() {
    return (
      <div className="div2">
        <Skillss
          ref="child"
          change1={this.updateContent3}
          change2={this.updateContent4}
          change3={this.updateContent1}
          change4={this.updateContent2}
          darkMode={this.props.darkMode}
        ></Skillss>

        <div className="div3">
          <p id="h3" className={`${this.props.darkMode ? "dark-mode" : ""}`}>
            {" "}
            {this.state.message}
          </p>
          <p
            id="class2"
            className={`${this.props.darkMode ? "dark-mode" : ""}`}
          >
            {this.state.submessage1}
          </p>
          <p
            id="class2"
            className={`${this.props.darkMode ? "dark-mode" : ""}`}
          >
            {this.state.submessage2}
          </p>
          <p
            id="class2"
            className={`${this.props.darkMode ? "dark-mode" : ""}`}
          >
            {this.state.submessage3}
          </p>

          <p
            id="class2"
            className={`${this.props.darkMode ? "dark-mode" : ""}`}
          >
            {this.state.submessage4}
          </p>

          <p
            id="class2"
            className={`${this.props.darkMode ? "dark-mode" : ""}`}
          >
            {this.state.submessage5}
          </p>

          <p
            id="class2"
            className={`${this.props.darkMode ? "dark-mode" : ""}`}
          >
            {this.state.submessage6}
          </p>

          <p
            id="class2"
            className={`${this.props.darkMode ? "dark-mode" : ""}`}
          >
            {this.state.submessage7}
          </p>

          <p
            id="class2"
            className={`${this.props.darkMode ? "dark-mode" : ""}`}
          >
            {this.state.submessage8}
          </p>

          <p
            id="class2"
            className={`${this.props.darkMode ? "dark-mode" : ""}`}
          >
            {this.state.submessage9}
          </p>

          <p
            id="class2"
            className={`${this.props.darkMode ? "dark-mode" : ""}`}
          >
            {this.state.submessage10}
          </p>

          <p
            id="class2"
            className={`${this.props.darkMode ? "dark-mode" : ""}`}
          >
            {this.state.submessage11}
          </p>

          <p
            id="class2"
            className={`${this.props.darkMode ? "dark-mode" : ""}`}
          >
            {this.state.submessage12}
          </p>
        </div>
      </div>
    );
  }
}
export default ContentDiv;
