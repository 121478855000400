import axios from "axios";
import {
  WHAT_WE_DO_CREATE_FAIL,
  WHAT_WE_DO_CREATE_REQUEST,
  WHAT_WE_DO_CREATE_SUCCESS,
  WHAT_WE_DO_LIST_FAIL,
  WHAT_WE_DO_LIST_REQUEST,
  WHAT_WE_DO_LIST_SUCCESS,
  WHAT_WE_DO_UPDATE_REQUEST,
  WHAT_WE_DO_UPDATE_SUCCESS,
  WHAT_WE_DO_UPDATE_FAIL,
  WHAT_WE_DO_DETAIL_REQUEST,
  WHAT_WE_DO_DETAIL_SUCCESS,
  WHAT_WE_DO_DETAIL_FAIL,
  WHAT_WE_DO_DELETE_REQUEST,
  WHAT_WE_DO_DELETE_SUCCESS,
  WHAT_WE_DO_DELETE_FAIL,
} from "../../constants/whatWeDoConstant";

export const createWhatWeDo = (whatWeDo) => async (dispatch, getState) => {
  try {
    dispatch({ type: WHAT_WE_DO_CREATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    await axios.post("https://backend.btekup.com/api/what-we-do", whatWeDo, config);

    dispatch({ type: WHAT_WE_DO_CREATE_SUCCESS });
  } catch (error) {
    dispatch({
      type: WHAT_WE_DO_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listWhatWeDo = () => async (dispatch, getState) => {
  try {
    dispatch({ type: WHAT_WE_DO_LIST_REQUEST });

    const { data } = await axios.get("https://backend.btekup.com/api/what-we-do");

    dispatch({ type: WHAT_WE_DO_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: WHAT_WE_DO_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateWhatWeDo = (whatWeDo) => async (dispatch, getState) => {
  try {
    dispatch({ type: WHAT_WE_DO_UPDATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `https://backend.btekup.com/api/what-we-do/${whatWeDo._id}`,
      whatWeDo,
      config
    );

    dispatch({ type: WHAT_WE_DO_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: WHAT_WE_DO_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getWhatWeDoById = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: WHAT_WE_DO_DETAIL_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `https://backend.btekup.com/api/what-we-do/${id}`,
      config
    );

    dispatch({ type: WHAT_WE_DO_DETAIL_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: WHAT_WE_DO_DETAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteWhatWeDo = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: WHAT_WE_DO_DELETE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`https://backend.btekup.com/api/what-we-do/${id}`, config);

    dispatch({ type: WHAT_WE_DO_DELETE_SUCCESS });
  } catch (error) {
    dispatch({
      type: WHAT_WE_DO_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
