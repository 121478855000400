import React from "react";
import "../styles/admincard.css";
import { useHistory } from "react-router-dom";
import { Box, Button, Card, CardHeader, Typography } from "@mui/material";

const AdminCard = ({ title, path }) => {
  let history = useHistory();

  function handleClick() {
    history.push(path);
  }
  return (
    <Box sx={{ mb: 3, ml: 3 }}>
      <Card>
        <Box
          sx={{
            p: 6,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <CardHeader
            title={
              <Typography variant="h4" align="center">
                {title}
              </Typography>
            }
          />

          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={handleClick}
          >
            {`View ${title}s`}
          </Button>
        </Box>
      </Card>
    </Box>
  );
};

export default AdminCard;
