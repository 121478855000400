import React from "react";
import "./NavBar.scss";
import { useHistory } from "react-router-dom";

const BlogButton = ({ toggleBlog }) => {
  const history = useHistory();

  const clickHandler = () => {
    toggleBlog();
    history.push("/?blog=true&loading=false");
  };

  return (
    <li className="nav-btn">
      {/* <Link to="/blog" target="_blank" rel="noopener noreferrer">
        <button>BLOGS</button>
      </Link> */}
      <button onClick={clickHandler}>BLOGS</button>
      {/* <a href="/blog" className={sticky ? "sticky" : ""}>
        BLOGS
      </a> */}
    </li>
  );
};

export default BlogButton;
