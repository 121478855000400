export const JOIN_OUR_TEAM_CREATE_REQUEST = "JOIN_OUR_TEAM_CREATE_REQUEST";
export const JOIN_OUR_TEAM_CREATE_SUCCESS = "JOIN_OUR_TEAM_CREATE_SUCCESS";
export const JOIN_OUR_TEAM_CREATE_FAIL = "JOIN_OUR_TEAM_CREATE_FAIL";

export const JOIN_OUR_TEAM_DELETE_REQUEST = "JOIN_OUR_TEAM_DELETE_REQUEST";
export const JOIN_OUR_TEAM_DELETE_SUCCESS = "JOIN_OUR_TEAM_DELETE_SUCCESS";
export const JOIN_OUR_TEAM_DELETE_FAIL = "JOIN_OUR_TEAM_DELETE_FAIL";

export const JOIN_OUR_TEAM_LIST_REQUEST = "JOIN_OUR_TEAM_LIST_REQUEST";
export const JOIN_OUR_TEAM_LIST_SUCCESS = "JOIN_OUR_TEAM_LIST_SUCCESS";
export const JOIN_OUR_TEAM_LIST_FAIL = "JOIN_OUR_TEAM_LIST_FAIL";
export const JOIN_OUR_TEAM_LIST_RESET = "JOIN_OUR_TEAM_LIST_RESET";
