import {
  JOIN_OUR_TEAM_CREATE_FAIL,
  JOIN_OUR_TEAM_CREATE_REQUEST,
  JOIN_OUR_TEAM_CREATE_SUCCESS,
  JOIN_OUR_TEAM_DELETE_FAIL,
  JOIN_OUR_TEAM_DELETE_REQUEST,
  JOIN_OUR_TEAM_DELETE_SUCCESS,
  JOIN_OUR_TEAM_LIST_FAIL,
  JOIN_OUR_TEAM_LIST_REQUEST,
  JOIN_OUR_TEAM_LIST_SUCCESS,
} from "../../constants/joinOurTeamConstants";

export const joinOurTeamCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case JOIN_OUR_TEAM_CREATE_REQUEST:
      return { loading: true };
    case JOIN_OUR_TEAM_CREATE_SUCCESS:
      return { loading: false, success: true };
    case JOIN_OUR_TEAM_CREATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const joinOurTeamListReducer = (state = { joinTeams: [] }, action) => {
  switch (action.type) {
    case JOIN_OUR_TEAM_LIST_REQUEST:
      return { ...state, loading: true };
    case JOIN_OUR_TEAM_LIST_SUCCESS:
      return { loading: false, joinTeams: action.payload };
    case JOIN_OUR_TEAM_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const joinOurTeamDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case JOIN_OUR_TEAM_DELETE_REQUEST:
      return { loading: true };
    case JOIN_OUR_TEAM_DELETE_SUCCESS:
      return { loading: false, success: true };
    case JOIN_OUR_TEAM_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
