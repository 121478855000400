import React, { useEffect, useState } from "react";
import axios from "axios";
import { Image } from "cloudinary-react";
import { useHistory, useParams } from "react-router-dom";
import {
  getPortfolioDetails,
  updatePortfolio,
} from "../state/actions/portfolioActions";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Box,
  TextField,
  Typography,
  TextareaAutosize,
  FormControl,
  Grid,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import TableScreen from "./components/TableScreen";
import Loader from "./components/Loader";
import Message from "./components/Message";
import { PORTFOLIO_UPDATE_RESET } from "../constants/portfolioConstants";
import { listCategories } from "../state/actions/categoryActions";

const PortfolioEditScreen = () => {
  const history = useHistory();
  let { id } = useParams();
  const dispatch = useDispatch();

  const [categoryObj, setCategoryObj] = useState([]);
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [laptop, setLaptop] = useState("");
  const [tablet, setTablet] = useState("");
  const [mobile, setMobile] = useState("");
  const [background, setBackground] = useState("");
  const [link, setLink] = useState("");
  const [uploadError, setUploadError] = useState("");

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const categoryList = useSelector((state) => state.categoryList);
  const {
    categories,
    // loading: loadingCategory,
    error: errorCategory,
  } = categoryList;

  const portfolioDetails = useSelector((state) => state.portfolioDetails);
  const { loading, error, portfolio } = portfolioDetails;

  const portfolioUpdate = useSelector((state) => state.portfolioUpdate);
  const {
    // loading: loadingUpdate,
    success: successUpdate,
    error: errroUpdate,
  } = portfolioUpdate;

  useEffect(() => {
    if (userInfo) {
      if (successUpdate) {
        dispatch({ type: PORTFOLIO_UPDATE_RESET });
        history.push("/admin/portfolio");
      } else {
        if (!portfolio || portfolio._id !== id) {
          dispatch(listCategories());
          dispatch(getPortfolioDetails(id));
        } else {
          setName(portfolio.name);
          setTitle(portfolio.title);
          setDescription(portfolio.description);
          setCategoryObj(portfolio.category);
          setLaptop(portfolio.laptop);
          setTablet(portfolio.tablet);
          setMobile(portfolio.mobile);
          setBackground(portfolio.background);
          setLink(portfolio.link);
        }
      }
    } else {
      history.push("/admin/login");
    }
  }, [history, userInfo, id, portfolio, dispatch, successUpdate]);

  const handleChange = (event, cc) => {
    if (event.target.checked) {
      setCategoryObj((prevState) => {
        return [...prevState, cc];
      });
    } else {
      var filtered = categoryObj.filter(function (value, index, arr) {
        return value._id != cc._id;
      });
      setCategoryObj(filtered);
    }
  };

  let category = [];

  const submitHandler = async (e) => {
    e.preventDefault();
    category = categoryObj.map((c) => c._id);
    dispatch(
      updatePortfolio({
        id,
        category,
        name,
        title,
        description,
        laptop,
        tablet,
        mobile,
        background,
        link,
      })
    );
  };

  const uploadLaptop = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "bwiy0n2g");
    try {
      const { data } = await axios.post(
        "https://api.cloudinary.com/v1_1/detmdijki/image/upload",
        formData
      );
      setLaptop(data.url);
    } catch (error) {
      setUploadError(error);
    }
  };

  const uploadTablet = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "bwiy0n2g");
    try {
      const { data } = await axios.post(
        "https://api.cloudinary.com/v1_1/detmdijki/image/upload",
        formData
      );
      setTablet(data.url);
    } catch (error) {
      setUploadError(error);
    }
  };

  const uploadMobile = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "bwiy0n2g");
    try {
      const { data } = await axios.post(
        "https://api.cloudinary.com/v1_1/detmdijki/image/upload",
        formData
      );
      setMobile(data.url);
    } catch (error) {
      setUploadError(error);
    }
  };

  const uploadBackground = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "bwiy0n2g");
    try {
      const { data } = await axios.post(
        "https://api.cloudinary.com/v1_1/detmdijki/image/upload",
        formData
      );
      setBackground(data.url);
    } catch (error) {
      setUploadError(error);
    }
  };

  return (
    <TableScreen>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message>{error}</Message>
      ) : (
        <form onSubmit={submitHandler}>
          <Typography variant="h5" textAlign="center" sx={{ mb: 3 }}>
            Update portfolio
          </Typography>

          <Grid container spacing={4}>
            <Grid item xs={2} />
            <Grid item xs={3}>
              <Box display="flex" flexWrap="wrap" justifyContent="start">
                <TextField
                  fullWidth
                  type="text"
                  id="outlined-basic"
                  label="Name"
                  variant="outlined"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <TextField
                  sx={{ mt: 2 }}
                  fullWidth
                  type="text"
                  id="outlined-basic"
                  label="Title"
                  variant="outlined"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <TextField
                  sx={{ my: 2 }}
                  fullWidth
                  type="text"
                  id="outlined-basic"
                  label="Link"
                  variant="outlined"
                  value={link}
                  onChange={(e) => setLink(e.target.value)}
                />

                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={15}
                  style={{ width: 360 }}
                  placeholder="Enter description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
                <Box sx={{ mt: 2 }}>
                  <Image
                    style={{ width: 150 }}
                    cloudName="bwiy0n2g"
                    publicId={background}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <FormControl
                component="fieldset"
                variant="standard"
                sx={{ mb: 1 }}
              >
                <FormLabel component="legend">Categories</FormLabel>
                {errorCategory && <Message>{errorCategory}</Message>}
                <FormGroup>
                  {categories &&
                    categories.map((c) => (
                      <FormControlLabel
                        key={c._id}
                        label={c.name}
                        control={
                          <Checkbox
                            name={c.name}
                            value={c.name}
                            onChange={(event) => handleChange(event, c)}
                            checked={categoryObj.some(
                              (cat) => cat._id === c._id
                            )}
                          />
                        }
                      />
                    ))}
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <Box display="flex" flexDirection="column">
                <Typography variant="h6">Web GIF</Typography>
                <Image
                  style={{ width: 150 }}
                  cloudName="bwiy0n2g"
                  publicId={laptop}
                />
                <Typography variant="h6">Tablet GIF</Typography>
                <Image
                  style={{ width: 150 }}
                  cloudName="bwiy0n2g"
                  publicId={tablet}
                />

                <Typography variant="h6">Mobile GIF</Typography>

                <Image
                  style={{ width: 150 }}
                  cloudName="bwiy0n2g"
                  publicId={mobile}
                />
              </Box>
            </Grid>
          </Grid>

          <Box
            display="flex"
            justifyContent="center"
            sx={{ marginTop: 5, marginBottom: 5 }}
          >
            <Button
              type="submit"
              size="large"
              variant="contained"
              color="primary"
              onClick={() => history.push("/admin/portfolio")}
            >
              Cancel
            </Button>
            <Button variant="contained" component="label" sx={{ marginX: 3 }}>
              Change Background
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={uploadBackground}
              />
            </Button>
            <Button variant="contained" component="label" sx={{ marginX: 2 }}>
              Change Laptop GIF
              <input
                type="file"
                hidden
                accept="image/gif"
                onChange={uploadLaptop}
              />
            </Button>

            <Button variant="contained" component="label">
              Change Tablet GIF
              <input
                type="file"
                hidden
                accept="image/gif"
                onChange={uploadTablet}
              />
            </Button>
            <Button variant="contained" component="label" sx={{ marginX: 3 }}>
              Change Mobile GIF
              <input
                type="file"
                hidden
                accept="image/gif"
                onChange={uploadMobile}
              />
            </Button>
            <Button
              type="submit"
              size="large"
              variant="contained"
              color="primary"
            >
              Update Portfolio
            </Button>
          </Box>
          {uploadError && <Message>{uploadError}</Message>}
          {errroUpdate && <Message>{errroUpdate}</Message>}
        </form>
      )}
    </TableScreen>
  );
};

export default PortfolioEditScreen;
