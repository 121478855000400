import React, { useState } from "react";
import axios from "axios";
import { createjoinOurTeam } from "../../state/actions/joinOurTeamActions";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../admin/components/Loader";
import Message from "../../admin/components/Message";

const Form = () => {
  const dispatch = useDispatch();

  const [file, setFile] = useState("");
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    message: "",
  });

  const joinOurTeamCreate = useSelector((state) => state.joinOurTeamCreate);
  const { success, loading, error } = joinOurTeamCreate;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "bwiy0n2g");
    try {
      const { data } = await axios.post(
        "https://api.cloudinary.com/v1_1/detmdijki/image/upload",
        formData
      );
      return data.secure_url;
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let cv_link = await uploadFile(file);
    setTimeout(() => {
      dispatch(createjoinOurTeam({ ...formData, cv_link }));
    }, 3000);
  };

  return (
    <div className="col-lg-6" id="form-container">
      <form
        onSubmit={handleSubmit}
        className="mb-4 mb-lg-0" /*style={{marginLeft:'4%'}}*/
      >
        <div className="form-row" style={{ display: "flex", width: "100%" }}>
          <div style={{ width: "45%" }}>
            <input
              required
              value={formData.firstname}
              onChange={handleChange}
              type="text"
              name="firstname"
              id="Fname"
              placeholder="First Name"
              style={{
                height: "60px",
                width: "100%",
                fontFamily: '  "BTekUp-Font", Arial, Helvetica, sans-serif',
              }}
            />
          </div>
          <div style={{ width: "45%", marginLeft: "9%" }}>
            <input
              required
              value={formData.lastname}
              type="text"
              onChange={handleChange}
              name="lastname"
              id="Lname"
              placeholder="Last Name"
              style={{
                height: "60px",
                width: "100%",
                fontFamily: '  "BTekUp-Font", Arial, Helvetica, sans-serif',
              }}
            />
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <input
            required
            type="email"
            value={formData.email}
            onChange={handleChange}
            name="email"
            id="email"
            placeholder="Email"
            style={{
              marginTop: "30px",
              height: "60px",
              width: "99.4%",
              fontFamily: '  "BTekUp-Font", Arial, Helvetica, sans-serif',
            }}
          />
        </div>
        <div style={{ width: "100%" }}>
          <textarea
            required
            value={formData.message}
            onChange={handleChange}
            name="message"
            placeholder="Tell us more about yourself"
            style={{ width: "98.4%" }}
          ></textarea>
        </div>

        <div className="btn-container">
          {!success && (
            <div className="attach-cv">
              <label htmlFor="contained-button-file" className="cv">
                Attach your CV
                <input
                  accept=".doc, .docx, .pdf"
                  id="contained-button-file"
                  type="file"
                  style={{ display: "none" }}
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </label>
            </div>
          )}

          {loading && <Loader />}
          {success ? (
            <button
              disabled
              className="btn btn-light"
              id="submit-button-disabled"
            >
              Submit
            </button>
          ) : (
            <button type="submit" className="btn btn-light" id="submit-button">
              Submit
            </button>
          )}
        </div>
        <br />
        {error && <Message>{error}</Message>}
      </form>
    </div>
  );
};

export default Form;
