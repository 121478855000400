import React from "react";
import styles from "./styles.module.scss";

const Popup = (props) => {
  return (
    <div className={styles["popup-box"]}>
      <div className={styles.box}>
        <span className={styles["close-icon"]} onClick={props.handleClose}>
          x
        </span>
        {/* {props.content} */}
        <p
          dangerouslySetInnerHTML={{
            __html: props.content,
          }}
        ></p>
      </div>
    </div>
  );
};

export default Popup;
