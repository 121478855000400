import React, { Component } from "react";
import Leader from "./Leader";

class LeaderSection extends Component {
  render() {
    return (
      <section
        id="leader-section"
        className={`${this.props.darkMode ? "dark-mode" : ""}`}
        ref={
          this.props.currentSection === "LEADER"
            ? this.props.currentSectionRef
            : ""
        }
      >
        <Leader darkMode={this.props.darkMode} />
      </section>
    );
  }
}

export default LeaderSection;
