import React, { useState, useEffect } from "react";
import CircleDemo from "./CircleDemo.js";
import Modal1 from "./Modal1.js";
import Modal3 from "./Modal3.js";
import image2 from "../../Assets/showimage.png";
import image3 from "../../Assets/showimage2.png";
import { listCategories } from "../../state/actions/categoryActions";
import { useDispatch, useSelector } from "react-redux";

const PortfolioCategory = ({ darkMode }) => {
  const dispatch = useDispatch();

  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [categoryname, setCategoryname] = useState();
  const [category, setCategory] = useState();

  const categoryList = useSelector((state) => state.categoryList);
  const { loading, error, categories } = categoryList;

  let threeCat = categories ? categories.slice(0, 3) : [];

  useEffect(() => {
    dispatch(listCategories());
  }, [dispatch]);

  let source = "";
  if (darkMode === true) {
    source = image3;
  } else {
    source = image2;
  }

  return (
    <>
      <div id="classhow" className="class22">
        <div className="class3">
          <div className="c2">
            {threeCat.map((c) => {
              return (
                <div
                  key={c._id}
                  className="tst"
                  onClick={() => {
                    // if visible = true mn chuf modal 1
                    setVisible((prevVisible) => !prevVisible);
                    setCategoryname(c.name);
                    setCategory(c);
                  }}
                >
                  <CircleDemo id="test" title={c.name} imagename={c.bgImage} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <br></br>
      {/* visible 2 btetghayar when show less or show more is clicked  */}
      {/* Modal 3 huwe ba2iyit categories  */}
      {visible2 ? <Modal3 darkMode={darkMode} categories={categories} /> : null}

      <div id="showdiv">
        <div className={`${darkMode ? "dark-mode" : ""}`} id="class12">
          {" "}
          {visible2 ? "Show Less" : "Show All"}
        </div>
        <div
          className="class5"
          onClick={() => {
            setVisible2((prevVisible2) => !prevVisible2);
          }}
        >
          {visible2 ? (
            <img id="imge2" className="show-all-style" src={source} alt="" />
          ) : (
            <img className="show-all-style" src={source} alt="" />
          )}
        </div>
      </div>

      {visible ? <Modal1 darkMode={darkMode} category={category} /> : null}
    </>
  );
};

export default PortfolioCategory;
