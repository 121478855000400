import react, { Component } from 'react';
import "./ContactUs.scss";
//import {ReactComponent as whatsappIcon} from "../../svgs/whatsapp1.png";

class ContactInfo extends Component {

	constructor(props) {
		super(props);
		console.log(this.state);
		this.state = {
			callIconColor: "#2bb8cb",
			MailIconColor: "#2bb8cb"
		};
	}

	updateCallHoverColor = () => {
		this.setState({ callIconColor: "rgba(43,184,203,0.5)" });
	}

	updateCallLeaveColor = () => {
		this.setState({ callIconColor: "#2bb8cb" });
	}

	updateMailHoverColor = () => {
		this.setState({ MailIconColor: "rgba(43,184,203,0.5)" });
	}

	updateMailLeaveColor = () => {
		this.setState({ MailIconColor: "#2bb8cb" });
	}

	render() {
		const main = {
			display: 'flex',
			flexWrap: 'wrap',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'space-between',
			marginTop: '-2rem',
		};
		const sub = {

		};
		const stext = {
			paddingBottom: 15,
			fontFamily: "BTekUp-Font",
			fontWeight: 'normal',
			fontStyle: 'normal',
			fontSize: '1.2rem',
			'@media (max-width: 1400px) and (min-width:990px)': {
				fontSize: '1.2rem',
			},
		};
		return (
			<></>
		);
	}

}

export default ContactInfo;
