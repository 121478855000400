import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import {
  Box,
  TextField,
  Button,
  Typography,
  TextareaAutosize,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  getWhatWeDoById,
  updateWhatWeDo,
} from "../state/actions/whatWeDoactions";
import Loader from "./components/Loader";
import Message from "./components/Message";
import TableScreen from "./components/TableScreen";
import { WHAT_WE_DO_UPDATE_RESET } from "../constants/whatWeDoConstant";
import { Image } from "cloudinary-react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const WhatWeDoEditScreen = ({ match }) => {
  const dispatch = useDispatch();
  const id = match.params.id;
  const history = useHistory();

  const [name, setName] = useState("");
  const [oldImage, setOldImage] = useState("");
  const [newImage, setNewImage] = useState("");
  const [description, setDescription] = useState("");
  let image;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const WhatWeDoEditScreen = useSelector((state) => state.WhatWeDoEditScreen);
  const {
    success,
    error: updateError,
    loading: updateLoading,
  } = WhatWeDoEditScreen;

  const whatWeDoDetails = useSelector((state) => state.whatWeDoDetails);
  const { whatWeDo, loading, error } = whatWeDoDetails;

  useEffect(() => {
    if (userInfo) {
      if (success) {
        dispatch({ type: WHAT_WE_DO_UPDATE_RESET });
        history.push("/admin/what-we-do");
      } else {
        if (!whatWeDo || whatWeDo._id !== id) {
          dispatch(getWhatWeDoById(id));
        } else {
          setName(whatWeDo.name);
          setDescription(whatWeDo.description);
          setOldImage(whatWeDo.image);
        }
      }
    } else {
      history.push("/admin/login");
    }
  }, [id, whatWeDo, dispatch, history, success, userInfo]);

  const uploadImage = async (img) => {
    const formData = new FormData();
    formData.append("file", img);
    formData.append("upload_preset", "bwiy0n2g");
    const { data } = await axios.post(
      "https://api.cloudinary.com/v1_1/detmdijki/image/upload",
      formData
    );
    return data.url;
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (newImage) {
      image = await uploadImage(newImage);
      dispatch(updateWhatWeDo({ _id: id, name, image, description }));
    } else {
      image = oldImage;
      dispatch(updateWhatWeDo({ _id: id, name, image, description }));
    }
  };

  return (
    <TableScreen>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message>{error}</Message>
      ) : (
        <>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="60vh"
          >
            <form onSubmit={submitHandler}>
              <Box
                display="flex"
                flexDirection="column"
                height="100vh"
                // sx={{
                //   justifyContent: "space-between",
                // }}
              >
                <Typography variant="h5">Edit Fields to update</Typography>
                <TextField
                  sx={{ mt: 2 }}
                  type="text"
                  id="outlined-basic"
                  label="Name"
                  variant="outlined"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                {/* <TextareaAutosize
                  aria-label="minimum height"
                  minRows={15}
                  style={{ width: 400 }}
                  placeholder="Enter Body"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  sx={{ mb: 2 }}
                /> */}
                <Box marginY={2}>
                  <ReactQuill
                    theme="snow"
                    value={description}
                    onChange={setDescription}
                  />
                </Box>
                <Image
                  style={{ width: 300, height: 200 }}
                  cloudName="bwiy0n2g"
                  publicId={oldImage}
                />
                <Button variant="contained" component="label" sx={{ my: 2 }}>
                  CHANGE IMAGE
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={(e) => setNewImage(e.target.files[0])}
                  />
                </Button>
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  color="primary"
                >
                  Update
                </Button>
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    dispatch({ type: WHAT_WE_DO_UPDATE_RESET });
                    history.push("/admin/what-we-do");
                  }}
                  sx={{ my: 2 }}
                >
                  Cancel
                </Button>
              </Box>
              {updateError && <Message>{updateError}</Message>}{" "}
              {updateLoading && <Loader />}
            </form>
          </Box>
        </>
      )}
    </TableScreen>
  );
};

export default WhatWeDoEditScreen;
