import React, { Component } from "react";
import NavBtn from "./NavBtn";
import NavDropDownBtn from "./NavDropDownBtn";
import NavLogo from "./NavLogo";
import ModeBtn from "./ModeBtn";
import ShowNavBtn from "./ShowNavBtn";
import "./NavBar.scss";
import BlogButton from "./BlogButton";

// This is the Welcome NavBar, it does not change on dark mode
class NavBar extends Component {
  // Active Button
  state = { activeBtn: "" };
  constructor(props) {
    super(props);
    this.activeBtnRef = React.createRef(null); // Reference to the active button
    this.activeSliderRef = React.createRef(null); // Reference to the active slider (hover effect on Desktop)
  }
  // Set activeBtn to the currentSection passed as props
  static getDerivedStateFromProps(props, state) {
    return { activeBtn: props.currentSection };
  }
  // Set the activeBtn
  setActiveBtn = (title) => {
    this.props.setCurrentSection(title);
  };
  // Move the active slider to the selected button using activeBtnRef

  // moveActiveSlider = () => {
  //   const activeBtnRect = this.activeBtnRef.current.getBoundingClientRect();
  //   this.activeSliderRef.current.style.left = activeBtnRect.x + "px";
  //   this.activeSliderRef.current.style.width = activeBtnRect.width + "px";
  // };

  // Called one time when this component loads the first time

  componentDidMount() {
    // window.addEventListener("resize", this.moveActiveSlider);
    // window.addEventListener("load", this.moveActiveSlider);
  }

  // Called whenever this component gets updated

  componentDidUpdate() {
    // this.moveActiveSlider();
  }

  // Render this component to the DOM.
  // NavBar is built from NavBtn, NavLogo, ModeBtn and ShowNavBtn components
  render() {
    // const { history } = this.props;
    return (
      <nav className="nav-bar welcome-nav-bar">
        <ul>
          <NavBtn
            title="HOME"
            setActiveBtn={this.setActiveBtn}
            activeBtn={this.state.activeBtn}
            activeBtnRef={this.activeBtnRef}
            toggleLeaderAndBlog={this.props.toggleLeaderAndBlog}
          />
          <NavBtn
            title="WHO WE ARE"
            setActiveBtn={this.setActiveBtn}
            activeBtn={this.state.activeBtn}
            activeBtnRef={this.activeBtnRef}
            toggleLeaderAndBlog={this.props.toggleLeaderAndBlog}
          />
          <NavBtn
            title="LEADER"
            setActiveBtn={this.setActiveBtn}
            activeBtn={this.state.activeBtn}
            activeBtnRef={this.activeBtnRef}
            toggleLeader={this.props.toggleLeader}
            toggleLeaderAndBlog={this.props.toggleLeaderAndBlog}
          />
          <NavBtn
            title="WHAT WE DO"
            setActiveBtn={this.setActiveBtn}
            activeBtn={this.state.activeBtn}
            activeBtnRef={this.activeBtnRef}
            toggleLeaderAndBlog={this.props.toggleLeaderAndBlog}
          />
          <NavBtn
            title="SKILLS"
            setActiveBtn={this.setActiveBtn}
            activeBtn={this.state.activeBtn}
            activeBtnRef={this.activeBtnRef}
            toggleLeaderAndBlog={this.props.toggleLeaderAndBlog}
          />
          <li>
            <NavLogo type="dark" />
          </li>
          <NavBtn
            title="PORTFOLIO"
            setActiveBtn={this.setActiveBtn}
            activeBtn={this.state.activeBtn}
            activeBtnRef={this.activeBtnRef}
            toggleLeaderAndBlog={this.props.toggleLeaderAndBlog}
          />
          <NavDropDownBtn
            title="CONTACT US"
            dropDown={["Send a Message", "Start a Project"]}
            setActiveBtn={this.setActiveBtn}
            activeBtn={this.state.activeBtn}
            activeBtnRef={this.activeBtnRef}
            setVisibleForm={this.props.setVisibleForm}
            toggleLeaderAndBlog={this.props.toggleLeaderAndBlog}
          />
          <NavBtn
            title="CAREERS"
            setActiveBtn={this.setActiveBtn}
            activeBtn={this.state.activeBtn}
            activeBtnRef={this.activeBtnRef}
            toggleLeaderAndBlog={this.props.toggleLeaderAndBlog}
          />
          <BlogButton sticky={true} toggleBlog={this.props.toggleBlog} />
          {/* <NavBtn
            title="BLOG"
            setActiveBtn={this.setActiveBtn}
            activeBtn={this.state.activeBtn}
            activeBtnRef={this.activeBtnRef}
            toggleBlog={this.props.toggleBlog}
            toggleLeaderAndBlog={this.props.toggleLeaderAndBlog}
          /> */}
          <li ref={this.activeSliderRef} className="active-slider"></li>
          <div className="right-container">
            <ModeBtn
              darkMode={this.props.darkMode}
              toggleDarkMode={this.props.toggleDarkMode}
            />
            <ShowNavBtn setIsMenuVisible={this.props.setIsMenuVisible} />
          </div>
        </ul>
      </nav>
    );
  }
}

export default NavBar;
