import React, { useState, useEffect } from "react";
import { listPortfolioByCategory } from "../../state/actions/portfolioActions";
import { useDispatch, useSelector } from "react-redux";
import CircleDemo from "./CircleDemo.js";
import image4 from "../../Assets/Close-image.png";
import image0 from "../../Assets/Close-image-dark.png";
import Modal2 from "./Modal2.js";
import "aos/dist/aos.css";
import Aos from "aos";

const Modal1 = (props) => {
  const dispatch = useDispatch();

  const [visible1, setVisible1] = useState(false);

  const [selectedPortfolio, setSelectedPortfolio] = useState();

  let source2 = props.darkMode ? image0 : image4;

  function saySomething() {
    document.getElementById("id1").hidden = true;
  }

  const portfolioListByCategory = useSelector(
    (state) => state.portfolioListByCategory
  );
  const { loading, error, portfolios } = portfolioListByCategory;

  useEffect(() => {
    dispatch(listPortfolioByCategory(props.category._id));
    Aos.init({ duration: 2100 });
  }, [dispatch, props.category._id]);

  return (
    <div id="id1" className="modal">
      <div className="overlay"></div>
      <div
        className={`${props.darkMode ? "dark-mode" : ""}`}
        id="modal-content"
      >
        <h2 className={`${props.darkMode ? "dark-mode" : ""}`} id="class11">
          PORTFOLIO
        </h2>
        <br></br>
        <h3 className={`${props.darkMode ? "dark-mode" : ""}`} id="classtitle2">
          {props.category.name}
        </h3>

        <div className="class6"></div>
        <br></br>
        <br></br>
        <br></br>

        <div className="class2">
          <div className="class3">
            <div className="c2">
              {loading ? (
                <h1>Loading...</h1>
              ) : error ? (
                <h1>{error}</h1>
              ) : (
                portfolios.map((portfolio) => (
                  <div
                    data-aos="zoom-in"
                    key={portfolio._id}
                    className="tst"
                    onClick={() => {
                      setVisible1((prevVisible1) => !prevVisible1);
                      setSelectedPortfolio(portfolio);
                    }}
                  >
                    <CircleDemo
                      id="test"
                      title={portfolio.name}
                      color={"#05949b"}
                      imagename={portfolio.background}
                      color2={"#05949b"}
                    />
                  </div>
                ))
              )}
            </div>
          </div>
        </div>

        <div onClick={saySomething} className="close-modal">
          <img src={source2} alt="" />
        </div>
      </div>
      {visible1 ? (
        <Modal2
          darkMode={props.darkMode}
          portfolio={selectedPortfolio}
        ></Modal2>
      ) : null}
    </div>
  );
};

export default Modal1;
