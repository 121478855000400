import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Loader from "./components/Loader";
import Message from "./components/Message";
import TableScreen from "./components/TableScreen";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  TextField,
  Grid,
  Link,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  listAuthors,
  createAuthor,
  deleteAuthor,
} from "../state/actions/authorActions";

const AuthorScreen = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [link, setLink] = useState("");

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const authorList = useSelector((state) => state.authorList);
  const { authors, loading, error } = authorList;

  const authorDelete = useSelector((state) => state.authorDelete);
  const {
    success: successDelete,
    loading: loadingDelete,
    error: errorDelete,
  } = authorDelete;

  const authorCreate = useSelector((state) => state.authorCreate);
  const { success, loading: loadingCreate, error: errorCreate } = authorCreate;

  useEffect(() => {
    if (userInfo) {
      dispatch(listAuthors());
    } else {
      history.push("/admin/login");
    }
  }, [dispatch, history, userInfo, successDelete, success]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(createAuthor({ name, link }));
    setName("");
    setLink("");
  };

  const deleteHandler = (id) => {
    dispatch(deleteAuthor(id));
  };

  const editHandler = (id) => {
    history.push(`/admin/author/${id}`);
  };

  return (
    <TableScreen>
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <form onSubmit={submitHandler}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              height="28vh"
            >
              <TextField
                type="text"
                variant="outlined"
                required
                label="Name"
                value={name}
                fullWidth={true}
                onChange={(e) => setName(e.target.value)}
              />
              <TextField
                type="text"
                variant="outlined"
                required
                label="Link"
                value={link}
                fullWidth={true}
                onChange={(e) => setLink(e.target.value)}
              />
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
              >
                ADD
              </Button>
              {errorCreate && <Message>{errorCreate}</Message>}
            </Box>
          </form>
        </Grid>
        <Grid item xs={8}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Link</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>

              {loading ? (
                <Loader />
              ) : error ? (
                <Message>{error}</Message>
              ) : (
                <TableBody>
                  {authors.map((author) => (
                    <TableRow key={author._id}>
                      <TableCell>{author.name}</TableCell>
                      <TableCell>
                        <Link
                          href={author.link}
                          target="_blank"
                          rel="noopener"
                          underline="hover"
                        >
                          {author.link}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => deleteHandler(author._id)}
                        >
                          Delete
                        </Button>
                        {errorDelete && <Message>{errorDelete}</Message>}
                        <Button
                          sx={{ ml: 1 }}
                          variant="outlined"
                          onClick={() => editHandler(author._id)}
                        >
                          Edit
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </TableScreen>
  );
};

export default AuthorScreen;
