import axios from "axios";
import {
  BLOGS_LIST_BY_CATEGORY_FAIL,
  BLOGS_LIST_BY_CATEGORY_REQUEST,
  BLOGS_LIST_BY_CATEGORY_SUCCESS,
  BLOG_CATEGORY_CREATE_FAIL,
  BLOG_CATEGORY_CREATE_REQUEST,
  BLOG_CATEGORY_CREATE_SUCCESS,
  BLOG_CATEGORY_DELETE_FAIL,
  BLOG_CATEGORY_DELETE_REQUEST,
  BLOG_CATEGORY_DELETE_SUCCESS,
  BLOG_CATEGORY_DETAILS_FAIL,
  BLOG_CATEGORY_DETAILS_REQUEST,
  BLOG_CATEGORY_DETAILS_SUCCESS,
  BLOG_CATEGORY_LIST_FAIL,
  BLOG_CATEGORY_LIST_REQUEST,
  BLOG_CATEGORY_LIST_SUCCESS,
  BLOG_CATEGORY_UPDATE_FAIL,
  BLOG_CATEGORY_UPDATE_REQUEST,
  BLOG_CATEGORY_UPDATE_SUCCESS,
  BLOG_CREATE_FAIL,
  BLOG_CREATE_REQUEST,
  BLOG_CREATE_SUCCESS,
  BLOG_DELETE_FAIL,
  BLOG_DELETE_REQUEST,
  BLOG_DELETE_SUCCESS,
  BLOG_DETAILS_FAIL,
  BLOG_DETAILS_REQUEST,
  BLOG_DETAILS_SUCCESS,
  BLOG_LIST_FAIL,
  BLOG_LIST_REQUEST,
  BLOG_LIST_SUCCESS,
  BLOG_UPDATE_FAIL,
  BLOG_UPDATE_REQUEST,
  BLOG_UPDATE_SUCCESS,
} from "../../constants/blogConstants";

export const createBlog = (blog) => async (dispatch, getState) => {
  try {
    dispatch({ type: BLOG_CREATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    await axios.post("https://backend.btekup.com/api/blogs", blog, config);

    dispatch({ type: BLOG_CREATE_SUCCESS });
  } catch (error) {
    dispatch({
      type: BLOG_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listBlogs = () => async (dispatch, getState) => {
  try {
    dispatch({ type: BLOG_LIST_REQUEST });

    const { data } = await axios.get("https://backend.btekup.com/api/blogs");

    dispatch({ type: BLOG_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: BLOG_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteBlog = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: BLOG_DELETE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`https://backend.btekup.com/api/BLOGS/${id}`, config);

    dispatch({ type: BLOG_DELETE_SUCCESS });
  } catch (error) {
    dispatch({
      type: BLOG_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getBlogDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: BLOG_DETAILS_REQUEST });

    const { data } = await axios.get(`https://backend.btekup.com/api/blogs/${id}`);

    dispatch({ type: BLOG_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: BLOG_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateBlog = (blog) => async (dispatch, getState) => {
  try {
    dispatch({ type: BLOG_UPDATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
    };
    await axios.put(`https://backend.btekup.com/api/blogs/${blog.id}`, blog, config);

    dispatch({ type: BLOG_UPDATE_SUCCESS });
  } catch (error) {
    dispatch({
      type: BLOG_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// CATEGORIES

export const createCategory = (category) => async (dispatch, getState) => {
  try {
    dispatch({ type: BLOG_CATEGORY_CREATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    await axios.post(
      "https://backend.btekup.com/api/blogs/categories",
      category,
      config
    );

    dispatch({ type: BLOG_CATEGORY_CREATE_SUCCESS });
  } catch (error) {
    dispatch({
      type: BLOG_CATEGORY_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listCategories = () => async (dispatch, getState) => {
  try {
    dispatch({ type: BLOG_CATEGORY_LIST_REQUEST });

    const { data } = await axios.get(
      "https://backend.btekup.com/api/blogs/categories"
    );

    dispatch({ type: BLOG_CATEGORY_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: BLOG_CATEGORY_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteCategory = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: BLOG_CATEGORY_DELETE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    await axios.delete(
      `https://backend.btekup.com/api/blogs/categories/${id}`,
      config
    );

    dispatch({ type: BLOG_CATEGORY_DELETE_SUCCESS });
  } catch (error) {
    dispatch({
      type: BLOG_CATEGORY_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getCategoryById = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: BLOG_CATEGORY_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `https://backend.btekup.com/api/blogs/categories/${id}/edit`,
      config
    );

    dispatch({ type: BLOG_CATEGORY_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: BLOG_CATEGORY_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateCategory = (category) => async (dispatch, getState) => {
  try {
    dispatch({ type: BLOG_CATEGORY_UPDATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
    };
    await axios.put(
      `https://backend.btekup.com/api/blogs/categories/${category.id}/edit`,
      category,
      config
    );

    dispatch({ type: BLOG_CATEGORY_UPDATE_SUCCESS });
  } catch (error) {
    dispatch({
      type: BLOG_CATEGORY_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listBlogsByCategory = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: BLOGS_LIST_BY_CATEGORY_REQUEST });

    const { data } = await axios.get(
      `https://backend.btekup.com/api/blogs/categories/${id}`
    );

    dispatch({ type: BLOGS_LIST_BY_CATEGORY_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: BLOGS_LIST_BY_CATEGORY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
