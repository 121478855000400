import React, { useEffect, useState } from "react";
import styles from "./Blog.module.scss";
import BlogHeader from "./components/BlogHeader/BlogHeader";
import Card from "./components/Card/Card";
import { useSelector, useDispatch } from "react-redux";
import { listBlogs, listBlogsByCategory } from "../state/actions/blogActions";
import Loader from "../admin/components/Loader";
import Message from "../admin/components/Message";
import Aos from "aos";
import "aos/dist/aos.css";
import BlogDetails from "./BlogDetails";
import { useHistory, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const Blog = ({ darkMode }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();

  const [category, setCategory] = useState("");
  const [moreBlogs, setMoreBlogs] = useState("");
  const [showDetails, setShowDetails] = useState(false);
  const [id, setId] = useState(0);

  const [more, setMore] = useState(false);

  const clickHandler = (id, title) => {
    setId(id);
    let newtitle = title.replaceAll(" ", "-");
    // setShowDetails((prevState) => !prevState);
    // history.push(`/?blog=true&loading=false&details=true&id=${id}`);
    history.push(`/blog/${newtitle}?id=${id}`);
  };

  const moreHandler = () => {
    if (!more) {
      setMoreBlogs(blogs.slice(6, blogs.length));
    }
    setMore((prevState) => !prevState);
  };

  const blogList = useSelector((state) => state.blogList);
  const { loading, error, blogs } = blogList;

  const blogListByCategory = useSelector((state) => state.blogListByCategory);
  const {
    loading: loadingByCat,
    error: errorByCat,
    blogs: blogsByCat,
  } = blogListByCategory;

  useEffect(() => {
    if (!category) {
      dispatch(listBlogs());
    } else if (category) {
      dispatch(listBlogsByCategory(category));
    }
    Aos.init({ duration: 1000 });
  }, [dispatch, category]);

  return (
    <>
      <Helmet>
        <title>Blogs</title>
        <meta name="description" content="blogs posted at btekup" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <div className={`${styles.overflow} ${darkMode ? styles.darkMode : ""}`}>
        {query.get("details") !== "true" && (
          <BlogHeader
            setCategory={setCategory}
            category={category}
            darkMode={darkMode}
            setShowDetails={setShowDetails}
          />
        )}
        {query.get("details") !== "true" ? (
          <main className={`${styles.blog} `}>
            {loading ? (
              <Loader />
            ) : error ? (
              <Message>{error}</Message>
            ) : (
              !category && (
                <>
                  {blogs.length < 6 && (
                    <div className={styles.mb}>
                      {blogs.map((blog) => (
                        <div
                          // data-aos="fade-in"
                          key={blog._id}
                          onClick={() => clickHandler(blog._id, blog.title)}
                        >
                          <Card
                            id={blog._id}
                            type="card"
                            title={blog.title}
                            category={blog.category.name}
                            img={blog.image}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                  {blogs.length >= 6 && (
                    <div className={`${styles.grid} ${styles.container}`}>
                      <div
                        className={styles.card}
                        data-aos="slide-right"
                        onClick={() =>
                          clickHandler(blogs[0]._id, blogs[0].title)
                        }
                      >
                        {" "}
                        <Card
                          key={blogs[0]._id}
                          id={blogs[0]._id}
                          type="widecard"
                          title={blogs[0].title}
                          category={blogs[0].category.name}
                          img={blogs[0].image}
                        />
                      </div>
                      <div
                        className={styles.card}
                        data-aos="slide-down"
                        onClick={() =>
                          clickHandler(blogs[1]._id, blogs[1].title)
                        }
                      >
                        <Card
                          key={blogs[1]._id}
                          id={blogs[1]._id}
                          type="card"
                          title={blogs[1].title}
                          category={blogs[1].category.name}
                          img={blogs[1].image}
                        />
                      </div>
                      <div
                        className={styles.card}
                        data-aos="slide-left"
                        onClick={() =>
                          clickHandler(blogs[2]._id, blogs[2].title)
                        }
                      >
                        <Card
                          key={blogs[2]._id}
                          id={blogs[2]._id}
                          type="card"
                          title={blogs[2].title}
                          category={blogs[2].category.name}
                          img={blogs[2].image}
                        />
                      </div>
                      <div
                        className={styles.card}
                        data-aos="slide-right"
                        onClick={() =>
                          clickHandler(blogs[3]._id, blogs[3].title)
                        }
                      >
                        <Card
                          key={blogs[3]._id}
                          id={blogs[3]._id}
                          type="card"
                          title={blogs[3].title}
                          category={blogs[3].category.name}
                          img={blogs[3].image}
                        />
                      </div>
                      <div
                        className={styles.card}
                        data-aos="slide-up"
                        onClick={() =>
                          clickHandler(blogs[4]._id, blogs[4].title)
                        }
                      >
                        <Card
                          key={blogs[4]._id}
                          id={blogs[4]._id}
                          type="card"
                          title={blogs[4].title}
                          category={blogs[4].category.name}
                          img={blogs[4].image}
                        />
                      </div>
                      <div
                        className={styles.card}
                        data-aos="slide-up"
                        onClick={() =>
                          clickHandler(blogs[5]._id, blogs[5].title)
                        }
                      >
                        <Card
                          key={blogs[5]._id}
                          id={blogs[5]._id}
                          type="widecard"
                          title={blogs[5].title}
                          category={blogs[5].category.name}
                          img={blogs[5].image}
                        />
                      </div>
                    </div>
                  )}
                  {blogs.length > 6 && (
                    <>
                      {!more && (
                        <div className={styles["show--more"]}>
                          <button onClick={moreHandler}>Show More</button>
                        </div>
                      )}
                      {more && (
                        <>
                          {moreBlogs.length == 6 ? (
                            <div
                              className={`${styles.grid2} ${styles.container}`}
                            >
                              <div
                                className={styles.card}
                                data-aos="slide-left"
                                onClick={() =>
                                  clickHandler(
                                    moreBlogs[0]._id,
                                    moreBlogs[0].title
                                  )
                                }
                              >
                                {" "}
                                <Card
                                  key={moreBlogs[0]._id}
                                  id={moreBlogs[0]._id}
                                  type="widecard"
                                  title={moreBlogs[0].title}
                                  category={moreBlogs[0].category.name}
                                  img={moreBlogs[0].image}
                                />
                              </div>
                              <div
                                className={styles.card}
                                data-aos="slide-right"
                                onClick={() =>
                                  clickHandler(
                                    moreBlogs[1]._id,
                                    moreBlogs[1].title
                                  )
                                }
                              >
                                <Card
                                  key={moreBlogs[1]._id}
                                  id={moreBlogs[1]._id}
                                  type="card"
                                  title={moreBlogs[1].title}
                                  category={moreBlogs[1].category.name}
                                  img={moreBlogs[1].image}
                                />
                              </div>
                              <div
                                className={styles.card}
                                data-aos="slide-up"
                                onClick={() =>
                                  clickHandler(
                                    moreBlogs[2]._id,
                                    moreBlogs[2].title
                                  )
                                }
                              >
                                <Card
                                  key={moreBlogs[2]._id}
                                  id={moreBlogs[2]._id}
                                  type="card"
                                  title={moreBlogs[2].title}
                                  category={moreBlogs[2].category.name}
                                  img={moreBlogs[2].image}
                                />
                              </div>
                              <div
                                className={styles.card}
                                data-aos="slide-up"
                                onClick={() =>
                                  clickHandler(
                                    moreBlogs[3]._id,
                                    moreBlogs[3].title
                                  )
                                }
                              >
                                <Card
                                  key={moreBlogs[3]._id}
                                  id={moreBlogs[3]._id}
                                  type="card"
                                  title={moreBlogs[3].title}
                                  category={moreBlogs[3].category.name}
                                  img={moreBlogs[3].image}
                                />
                              </div>
                              <div
                                className={styles.card}
                                data-aos="slide-left"
                                onClick={() =>
                                  clickHandler(
                                    moreBlogs[4]._id,
                                    moreBlogs[4].title
                                  )
                                }
                              >
                                <Card
                                  key={moreBlogs[4]._id}
                                  id={moreBlogs[4]._id}
                                  type="card"
                                  title={moreBlogs[4].title}
                                  category={moreBlogs[4].category.name}
                                  img={moreBlogs[4].image}
                                />
                              </div>
                              <div
                                className={styles.card}
                                data-aos="slide-right"
                                onClick={() =>
                                  clickHandler(
                                    moreBlogs[5]._id,
                                    moreBlogs[5].title
                                  )
                                }
                              >
                                <Card
                                  key={moreBlogs[5]._id}
                                  id={moreBlogs[5]._id}
                                  type="widecard"
                                  title={moreBlogs[5].title}
                                  category={moreBlogs[5].category.name}
                                  img={moreBlogs[5].image}
                                />
                              </div>
                            </div>
                          ) : (
                            <div className={styles["more--cards"]}>
                              {moreBlogs.map((blog) => (
                                <div
                                  key={blog._id}
                                  data-aos="fade-in"
                                  onClick={() =>
                                    clickHandler(blog._id, blog.title)
                                  }
                                >
                                  <Card
                                    id={blog._id}
                                    type="card"
                                    title={blog.title}
                                    category={blog.category.name}
                                    img={blog.image}
                                  />
                                </div>
                              ))}
                            </div>
                          )}
                          <div className={styles["show--more"]}>
                            <button onClick={moreHandler}>Show Less</button>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </>
              )
            )}
            {category &&
              (loadingByCat ? (
                <Loader />
              ) : errorByCat ? (
                <Message>{errorByCat}</Message>
              ) : blogsByCat.length == 6 ? (
                <div className={`${styles.grid} ${styles.container}`}>
                  <div
                    className={styles.card}
                    data-aos="slide-right"
                    onClick={() =>
                      clickHandler(blogsByCat[0]._id, blogsByCat[0].title)
                    }
                  >
                    {" "}
                    <Card
                      key={blogsByCat[0]._id}
                      id={blogsByCat[0]._id}
                      type="widecard"
                      title={blogsByCat[0].title}
                      category={blogsByCat[0].category.name}
                      img={blogsByCat[0].image}
                    />
                  </div>
                  <div
                    className={styles.card}
                    data-aos="slide-down"
                    onClick={() =>
                      clickHandler(blogsByCat[1]._id, blogsByCat[1].title)
                    }
                  >
                    <Card
                      key={blogsByCat[1]._id}
                      id={blogsByCat[1]._id}
                      type="card"
                      title={blogsByCat[1].title}
                      category={blogsByCat[1].category.name}
                      img={blogsByCat[1].image}
                    />
                  </div>
                  <div
                    className={styles.card}
                    data-aos="slide-left"
                    onClick={() =>
                      clickHandler(blogsByCat[2]._id, blogsByCat[2].title)
                    }
                  >
                    <Card
                      key={blogsByCat[2]._id}
                      id={blogsByCat[2]._id}
                      type="card"
                      title={blogsByCat[2].title}
                      category={blogsByCat[2].category.name}
                      img={blogsByCat[2].image}
                    />
                  </div>
                  <div
                    className={styles.card}
                    data-aos="slide-right"
                    onClick={() =>
                      clickHandler(blogsByCat[3]._id, blogsByCat[3].title)
                    }
                  >
                    <Card
                      key={blogsByCat[3]._id}
                      id={blogsByCat[3]._id}
                      type="card"
                      title={blogsByCat[3].title}
                      category={blogsByCat[3].category.name}
                      img={blogsByCat[3].image}
                    />
                  </div>
                  <div
                    className={styles.card}
                    data-aos="slide-up"
                    onClick={() =>
                      clickHandler(blogsByCat[4]._id, blogsByCat[4].title)
                    }
                  >
                    <Card
                      key={blogsByCat[4]._id}
                      id={blogsByCat[4]._id}
                      type="card"
                      title={blogsByCat[4].title}
                      category={blogsByCat[4].category.name}
                      img={blogsByCat[4].image}
                    />
                  </div>
                  <div
                    className={styles.card}
                    data-aos="slide-up"
                    onClick={() =>
                      clickHandler(blogsByCat[5]._id, blogsByCat[5].title)
                    }
                  >
                    <Card
                      key={blogsByCat[5]._id}
                      id={blogsByCat[5]._id}
                      type="widecard"
                      title={blogsByCat[5].title}
                      category={blogsByCat[5].category.name}
                      img={blogsByCat[5].image}
                    />
                  </div>
                </div>
              ) : (
                <div className={styles.mb}>
                  {blogsByCat.map((blog) => (
                    <div
                      data-aos="fade-in"
                      key={blog._id}
                      onClick={() => clickHandler(blog._id, blog.title)}
                    >
                      <Card
                        id={blog._id}
                        type="card"
                        title={blog.title}
                        category={blog.category.name}
                        img={blog.image}
                      />
                    </div>
                  ))}
                </div>
              ))}
          </main>
        ) : (
          <BlogDetails id={query.get("id")} darkMode={darkMode} />
        )}
      </div>
    </>
  );
};

export default Blog;
