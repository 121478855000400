import React from "react";
// import "../styles/navbar.css";
import { AppBar, Toolbar, Typography, Button, Box } from "@mui/material";

const Navbar = ({ logout }) => {
  return (
    // <header className="navbar">
    //   <div className="navbar__title navbar__item">Admin</div>
    //   <div className="navbar__item" onClick={() => logout()}>
    //     Logout
    //   </div>
    // </header>

    <AppBar position="static">
      <Toolbar>
        <Typography variant="h5">Admin Site</Typography>
        <Box sx={{ ml: "auto" }}>
          <Button variant="contained" onClick={() => logout()}>
            Logout
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
