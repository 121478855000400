import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Loader from "./components/Loader";
import Message from "./components/Message";
import TableScreen from "./components/TableScreen";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  TextField,
  Grid,
  Link,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  listCategories,
  createCategory,
  deleteCategory,
} from "../state/actions/blogActions";

const BlogCategoryScreen = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [bgImage, setBgImage] = useState("");
  const [uploading, setUploading] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const blogCategoryList = useSelector((state) => state.blogCategoryList);
  const { categories, loading, error } = blogCategoryList;

  const blogCategoryDelete = useSelector((state) => state.blogCategoryDelete);
  const {
    success: successDelete,
    loading: loadingDelete,
    error: errorDelete,
  } = blogCategoryDelete;

  const blogCategoryCreate = useSelector((state) => state.blogCategoryCreate);
  const {
    success,
    loading: loadingCreate,
    error: errorCreate,
  } = blogCategoryCreate;

  useEffect(() => {
    if (userInfo) {
      dispatch(listCategories());
    } else {
      history.push("/admin/login");
    }
  }, [dispatch, history, userInfo, successDelete, success]);

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "pd52bchw");
    setUploading(true);
    try {
      const { data } = await axios.post(
        "https://api.cloudinary.com/v1_1/detmdijki/image/upload",
        formData
      );
      setBgImage(data.url);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(createCategory({ name, bgImage }));
    setName("");
    setBgImage("");
  };

  const deleteHandler = (id) => {
    dispatch(deleteCategory(id));
  };

  const editHandler = (id) => {
    history.push(`/admin/blog/categories/${id}`);
  };

  return (
    <TableScreen>
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <form onSubmit={submitHandler}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              height="28vh"
            >
              <TextField
                type="text"
                variant="outlined"
                required
                label="Name"
                value={name}
                fullWidth={true}
                onChange={(e) => setName(e.target.value)}
              />
              {uploading ? (
                <Loader />
              ) : (
                <>
                  <Button variant="contained" component="label">
                    UPLOAD IMAGE
                    <input
                      type="file"
                      hidden
                      accept="image/*"
                      onChange={uploadFileHandler}
                    />
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                  >
                    ADD
                  </Button>
                </>
              )}
              {errorCreate && <Message>{errorCreate}</Message>}
            </Box>
          </form>
        </Grid>
        <Grid item xs={8}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Category Name</TableCell>
                  <TableCell>Image Link</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>

              {loading ? (
                <Loader />
              ) : error ? (
                <Message>{error}</Message>
              ) : (
                <TableBody>
                  {categories.map((category) => (
                    <TableRow key={category._id}>
                      <TableCell>{category.name}</TableCell>
                      <TableCell>
                        <Link
                          href={category.bgImage}
                          target="_blank"
                          rel="noopener"
                          underline="hover"
                        >
                          link
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => deleteHandler(category._id)}
                        >
                          Delete
                        </Button>
                        {errorDelete && <Message>{errorDelete}</Message>}
                        <Button
                          sx={{ ml: 1 }}
                          variant="outlined"
                          onClick={() => editHandler(category._id)}
                        >
                          Edit
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </TableScreen>
  );
};

export default BlogCategoryScreen;
