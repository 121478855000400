import React from "react";
import styles from "./Card.module.scss";
// import { useHistory, Link } from "react-router-dom";

const Card = ({ type, title, category, id, img }) => {
  // const history = useHistory();

  return (
    // <Link target="_blank" rel="noopener noreferrer" to={`/blog/${id}`}>
    <div
      className={styles[`${type}`]}
      // onClick={() => {
      //   history.push(`/blog/${id}`);
      // }}
    >
      <figure className={styles.card__thumbnail}>
        <img src={img} alt={`thumbnail image of ${title}`} />
        <div className={styles.overlay}></div>
        <div className={styles.text}>
          <p className={styles.card__category}>{category}</p>
          <p className={styles.card__title}>{title}</p>
        </div>
      </figure>
    </div>
    // </Link>
  );
};

export default Card;
