import React from "react";
import Loading from "./components/Loading/Loading";
import NavBar from "./components/NavBar/NavBar";
import StickyNavBar from "./components/NavBar/StickyNavBar";
import MobileNavMenu from "./components/NavBar/MobileNavMenu";
import Home from "./components/Home/Home";
import WhoWeAre from "./components/WhoWeAre/Whoweare";
import WhatWeDo from "./components/WhatWeDo/WhatWeDo";
import Skills from "./components/Skills/Skills";
import Portfolio from "./components/Portfolio/Portfolio";
import ContactUs from "./components/ContactUs/ContactUs";
import JoinOurTeam from "./components/JoinOurTeam/JoinOurTeam";
import Footer from "./components/Footer/Footer";
// import ChatBot from "./components/ChatBot/ChatBot.jsx";
import isInViewPort from "./components/Functions/isInViewPort";
import "./index.scss";
import Sectors from "./components/Sectors/Sectors";
// import { useLocation } from "react-router-dom";
import LeaderSection from "./screens/LeaderSection";
import BlogSection from "./screens/BlogSection";
import { Helmet } from "react-helmet-async";

/*
  IMPORTANT NOTES:
  In REACT, a component gets updated and rerendered whenever one or 
  more value of its states or props is changed.
  Functions can be passed as props to the children to enable them from
  controlling parent states.
  Parent control his children by passing values props.
 */
// This is the root component that contains all components
class Website extends React.Component {
  // The Global variables of our website are defined here to be accessible for all child components
  // This practice will prevent collisions between components
  // For example, the 2 NavBars must always have the same selected section
  state = {
    darkMode: false, // true when DarkMode On, light mode by default
    currentSection: "HOME", // The current selected section, HOME on load
    visibleForm: "Send a Message",
    isMenuVisible: false, // Mobile Nav Menu, invisible on load
    blog: false,
    leader: false,
  };
  constructor(props) {
    super(props);
    this.stickyNavBarRef = React.createRef(null); // Reference will be assigned to StickyNavBar
    this.currentSectionRef = React.createRef(null);
  }

  toggleLeaderAndBlog = () => {
    this.setState({ blog: false });
    this.setState({ leader: false });
  };

  toggleBlog = () => {
    this.setState((prevState) => ({
      leader: false,
    }));
  };

  toggleLeader = () => {
    this.setState((prevState) => ({
      leader: !prevState.leader,
    }));
  };
  // Toggle DarkMode from on to off and vice-versa
  toggleDarkMode = () => {
    this.setState({ darkMode: !this.state.darkMode });
    if (!this.state.darkMode) {
      document.getElementById("root").classList.add("dark-mode");
    } else {
      document.getElementById("root").classList.remove("dark-mode");
    }
  };
  // Set the currentSection as the given title then scroll
  setCurrentSection = (title) => {
    this.setState({ currentSection: title }, () => {
      this.scrollToCurrentSection(); // Scroll to new CurrentSection
    });
  };
  // Set the currentSection without scrolling to it to prevent scroll on scroll
  setCurrentSectionOnScroll = (title) => {
    this.setState({ currentSection: title });
  };
  // Set the visibleForm
  setVisibleForm = (newVisibleForm) => {
    this.setState({ visibleForm: newVisibleForm });
  };
  // Set isMenuVisible as the given boolean value
  setIsMenuVisible = (isVisible) => {
    this.setState({ isMenuVisible: isVisible });
  };
  // Test the scroll and decide wether to show the sticky nav or not
  stickyNavBar = () => {
    let blog = new URLSearchParams(this.props.location.search).get("blog");
    if (window.scrollY > 70 || blog === "true" || this.state.leader == true) {
      this.stickyNavBarRef.current.classList.add("active-sticky-nav-bar");
    } else {
      this.stickyNavBarRef.current.classList.remove("active-sticky-nav-bar");
    }
  };
  // Scroll to the current active section
  scrollToCurrentSection = () => {
    this.currentSectionRef.current.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  };
  // Updates the navbar according to the current section when scrolling
  updateNavOnScroll = () => {
    if (this.state.currentSection !== "HOME") {
      if (isInViewPort("home-section")) {
        this.setCurrentSectionOnScroll("HOME");
      }
    }
    if (this.state.currentSection !== "WHO WE ARE") {
      if (isInViewPort("whoSection")) {
        this.setCurrentSectionOnScroll("WHO WE ARE");
      }
    }
    if (this.state.currentSection !== "WHAT WE DO") {
      if (isInViewPort("whatwedosection")) {
        this.setCurrentSectionOnScroll("WHAT WE DO");
      }
    }
    if (this.state.currentSection !== "SKILLS") {
      if (isInViewPort("skillsSection")) {
        this.setCurrentSectionOnScroll("SKILLS");
      }
    }
    if (this.state.currentSection !== "PORTFOLIO") {
      if (isInViewPort("PortfolioSection")) {
        this.setCurrentSectionOnScroll("PORTFOLIO");
      }
    }
    if (this.state.currentSection !== "CONTACT US") {
      if (isInViewPort("contact-us-container")) {
        this.setCurrentSectionOnScroll("CONTACT US");
      }
    }
    if (this.state.currentSection !== "CAREERS") {
      if (isInViewPort("join-us")) {
        this.setCurrentSectionOnScroll("CAREERS");
      }
    }
    if (this.state.currentSection !== "LEADER") {
      if (isInViewPort("leader-section")) {
        this.setCurrentSectionOnScroll("LEADER");
      }
    }
    if (this.state.currentSection !== "BLOG") {
      if (isInViewPort("blog-section")) {
        this.setCurrentSectionOnScroll("BLOG");
      }
    }
  };
  // Called once when this component loads to add event listeners
  componentDidMount() {
    window.addEventListener("scroll", () => {
      this.stickyNavBar();
    });
    window.addEventListener("mousewheel", () => {
      // this.updateNavOnScroll();
    });
  }
  componentDidUpdate() {
    this.stickyNavBar();
  }
  // Render this component to the DOM
  // Every section has a different component to isolate sections
  render() {
    let query = new URLSearchParams(this.props.location.search).get("loading");
    let blog = new URLSearchParams(this.props.location.search).get("blog");
    return (
      <>
        <Helmet>
          <title>BtekUp</title>
          <meta
            name="description"
            content={
              "btekup is a technology, communication and multimedia agency. providing our clients with solutions that are 100% optimizable, scalable, and measurable to meet their technological needs. Our team of experts in innovation and digital transformation is dedicated to ensuring that your brand achieves a distinctive online presence through the selection of digital tools and the precise application of innovation strategies.."
            }
          />
          <link rel="canonical" href={window.location.href} />
        </Helmet>
        {query === "false" ? <></> : <Loading />}
        {blog !== "true" && (
          <NavBar
            darkMode={this.state.darkMode}
            toggleDarkMode={this.toggleDarkMode}
            currentSection={this.state.currentSection}
            setCurrentSection={this.setCurrentSection}
            setVisibleForm={this.setVisibleForm}
            setIsMenuVisible={this.setIsMenuVisible}
            toggleBlog={this.toggleBlog}
            toggleLeader={this.toggleLeader}
            toggleLeaderAndBlog={this.toggleLeaderAndBlog}
          />
        )}
        <StickyNavBar
          darkMode={this.state.darkMode}
          toggleDarkMode={this.toggleDarkMode}
          currentSection={this.state.currentSection}
          setCurrentSection={this.setCurrentSection}
          setVisibleForm={this.setVisibleForm}
          setIsMenuVisible={this.setIsMenuVisible}
          stickyNavBarRef={this.stickyNavBarRef}
          toggleBlog={this.toggleBlog}
          toggleLeader={this.toggleLeader}
          toggleLeaderAndBlog={this.toggleLeaderAndBlog}
        />
        <MobileNavMenu
          darkMode={this.state.darkMode}
          currentSection={this.state.currentSection}
          setCurrentSection={this.setCurrentSection}
          setVisibleForm={this.setVisibleForm}
          isMenuVisible={this.state.isMenuVisible}
          setIsMenuVisible={this.setIsMenuVisible}
          toggleBlog={this.toggleBlog}
          toggleLeader={this.toggleLeader}
          toggleLeaderAndBlog={this.toggleLeaderAndBlog}
        />
        {blog !== "true" && !this.state.leader && (
          <>
            <Home
              darkMode={this.state.darkMode}
              currentSection={this.state.currentSection}
              currentSectionRef={this.currentSectionRef}
              setCurrentSection={this.setCurrentSection}
              setVisibleForm={this.setVisibleForm}
            />
            <WhoWeAre
              darkMode={this.state.darkMode}
              currentSection={this.state.currentSection}
              currentSectionRef={this.currentSectionRef}
            />
            <WhatWeDo
              darkMode={this.state.darkMode}
              currentSection={this.state.currentSection}
              currentSectionRef={this.currentSectionRef}
            />
            <Skills
              darkMode={this.state.darkMode}
              currentSection={this.state.currentSection}
              currentSectionRef={this.currentSectionRef}
            />
            <Sectors
              darkMode={this.state.darkMode}
              currentSection={this.state.currentSection}
              currentSectionRef={this.currentSectionRef}
            />
            <Portfolio
              darkMode={this.state.darkMode}
              currentSection={this.state.currentSection}
              currentSectionRef={this.currentSectionRef}
            />
            <ContactUs
              form={this.state.visibleForm}
              darkMode={this.state.darkMode}
              currentSection={this.state.currentSection}
              currentSectionRef={this.currentSectionRef}
            />
            <JoinOurTeam
              darkMode={this.state.darkMode}
              currentSection={this.state.currentSection}
              currentSectionRef={this.currentSectionRef}
            />
          </>
        )}
        {blog === "true" && (
          <BlogSection
            darkMode={this.state.darkMode}
            currentSection={this.state.currentSection}
            currentSectionRef={this.currentSectionRef}
          />
        )}
        {this.state.leader && (
          <LeaderSection
            darkMode={this.state.darkMode}
            currentSection={this.state.currentSection}
            currentSectionRef={this.currentSectionRef}
          />
        )}
        <Footer darkMode={this.state.darkMode} />
        {/* <ChatBot /> */}
      </>
    );
  }
}
export default Website;
