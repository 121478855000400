// This is the dynamic data of the carousel
// "images" folder is located in the "public" folder to be able to fetch
// them dynamically on page load
// From here you can control the carousel data by changing the values
// This file should be fetched from the data base
export const  CarouselData  = [
  { index:0,
    name: "carousel1.jpg", // name+extension of the image inside public/images
    alt: "Laptop",
    text: "WELCOME",
  },
  { index:1 ,
    name: "carousel2.jpg",
    alt: "Mobile Phone",
    text: "",
  },
  {
    index:2,
    name: "carousel3.jpg",
    alt: "Graph",
    text: "Start Development",
  }
];
