import React, { Component } from "react";
import "./Skillssection.scss";
class Skillsimage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fill1: "#015866",
      fill2: "#015866",
      fill3: "#05949B",
      fill4: "#015866",
      ovver: "visible",
      color1: "#1BBED0",
      color2: "#04B6C7",
      color3: "#018493",
      color4: "#036574",
      color5: "#015866",

      darkMode: this.props.darkMode,
    };
  }

  updatecolor1 = () => {
    this.setState({ fill1: "#05949B" });

    this.setState({ fill2: "#015866" });
    this.setState({ fill3: "#015866" });
    this.setState({ fill4: "#015866" });
  };
  updatecolor2 = () => {
    this.setState({ fill2: "#05949B" });

    this.setState({ fill1: "#015866" });
    this.setState({ fill3: "#015866" });
    this.setState({ fill4: "#015866" });
  };
  updatecolor3 = () => {
    this.setState({ fill3: "#05949B" });

    this.setState({ fill1: "#015866" });
    this.setState({ fill2: "#015866" });
    this.setState({ fill4: "#015866" });
  };
  updatecolor4 = () => {
    this.setState({ fill4: "#05949B" });

    this.setState({ fill1: "#015866" });
    this.setState({ fill2: "#015866" });
    this.setState({ fill3: "#015866" });
  };

  render() {
    return (
      <svg
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 569.4 576"
        className="skillsimage1"
      >
        <g>
          <path
            fill={this.state.fill1}
            d="M193.2,416.8l45.5-63.5c-44.3-6.1-79.4-40.8-86.2-84.9L75.1,213c-0.5-0.3-0.9-0.4-1.2-0.4
            c-0.1,0-0.3,0.1-0.7,0.3L0,264.4c5.8,128.3,107.3,231.7,234.8,240.7L193,445.6l-0.1-0.2c-2.8-4.4-4.2-9-4.2-13.8
            c0-5,1.4-9.9,4.2-14.2L193.2,416.8z"
            onMouseOver={this.props.change1}
            onClick={this.props.change1}
          />

          <path
            fill={this.state.fill2}
            d="M292.9,73.8c0,0,0-0.2-0.3-0.6L241,0C112.7,5.8,9.3,107.3,0.4,234.8l59.5-41.9l0.2-0.1c4.4-2.8,9-4.2,13.8-4.2
            c5,0,9.9,1.4,14.2,4.2l0.6,0.4l63.5,45.5c6.1-44.3,40.9-79.4,84.9-86.2l55.4-77.3c0.3-0.5,0.4-0.9,0.4-1.2
            C292.9,73.8,292.9,73.8,292.9,73.8"
            onMouseOver={this.props.change2}
            onClick={this.props.change2}
          />
          <path
            fill={this.state.fill3}
            d="M270.6,0.4l41.9,59.5l0.1,0.2c2.8,4.4,4.2,9,4.2,13.9c0,5-1.4,9.9-4.2,14.2l-0.4,0.6l-45.5,63.5
            c44.3,6.1,79.4,40.8,86.2,84.9l77.3,55.4c0.5,0.3,0.9,0.4,1.2,0.4c0.1,0,0.1,0,0.1,0s0.2,0,0.6-0.3l73.2-51.5
            C499.6,112.7,398.2,9.3,270.6,0.4"
            onMouseOver={this.props.change3}
            onClick={this.props.change3}
          />
          <path
            fill={this.state.fill4}
            d="M445.4,312.6c-4.4,2.8-9,4.2-13.9,4.2c-5,0-9.9-1.4-14.2-4.2l-0.6-0.4l-63.5-45.5
            c-6.1,44.3-40.8,79.4-84.9,86.2L213,430.4c-0.3,0.5-0.4,0.9-0.4,1.2c0,0.1,0,0.1,0,0.1s0,0.2,0.3,0.6l51.5,73.2
            c128.3-5.8,231.7-107.3,240.7-234.8l-59.5,41.9H445.4z"
            onMouseOver={this.props.change4}
            onClick={this.props.change4}
          />
          <path
            class="st22"
            d="M127.2,334.4c-0.6-1.6-2.1-2.8-3.9-2.8H122v-2.7h1.4c2.3,0,4.1-1.8,4.1-4.1v-11c0-2.3-1.8-4.1-4.1-4.1H74.1
            c-2.3,0-4.1,1.8-4.1,4.1v11c0,2.3,1.8,4.1,4.1,4.1h1.4v2.7h-1.4c-2.3,0-4.1,1.8-4.1,4.1v11c0,2.3,1.8,4.1,4.1,4.1h1.4v2.7h-1.4
            c-2.3,0-4.1,1.8-4.1,4.1v11c0,2.3,1.8,4.1,4.1,4.1h26v11c0,5.4,13.1,8.2,26,8.2s26-2.8,26-8.2v-41.1
            C152.1,337.4,139.8,334.6,127.2,334.4 M74.1,326.2c-0.8,0-1.4-0.6-1.4-1.4v-11c0-0.8,0.6-1.4,1.4-1.4h49.3c0.8,0,1.4,0.6,1.4,1.4
            v11c0,0.8-0.6,1.4-1.4,1.4H74.1z M119.2,331.6h-5.5v-2.7h5.5V331.6z M111,331.6H86.4v-2.7H111V331.6z M83.6,331.6h-5.5v-2.7h5.5
            V331.6z M100.1,370h-26c-0.8,0-1.4-0.6-1.4-1.4v-11c0-0.8,0.6-1.4,1.4-1.4h26V370z M78.2,350.8h5.5v2.7h-5.5V350.8z M100.1,353.6
            H86.4v-2.7h13.7V353.6z M100.1,342.6v5.5h-26c-0.8,0-1.4-0.6-1.4-1.4v-11c0-0.8,0.6-1.4,1.4-1.4h49.3c0.1,0,0.1,0,0.2,0
            C111.5,334.8,100.1,337.6,100.1,342.6 M149.4,383.7c0,1.9-8.2,5.5-23.3,5.5s-23.3-3.6-23.3-5.5v-9.8c4.5,2.9,13.9,4.4,23.3,4.4
            s18.8-1.5,23.3-4.4V383.7z M149.4,370c0,1.9-8.2,5.5-23.3,5.5s-23.3-3.6-23.3-5.5v-9.8c4.5,2.9,13.9,4.4,23.3,4.4
            s18.8-1.5,23.3-4.4V370z M149.4,356.3c0,1.9-8.2,5.5-23.3,5.5s-23.3-3.6-23.3-5.5v-9.8c4.5,2.9,13.9,4.4,23.3,4.4
            s18.8-1.5,23.3-4.4V356.3z M126.1,348.1c-15.1,0-23.3-3.6-23.3-5.5s8.2-5.5,23.3-5.5s23.3,3.6,23.3,5.5
            C149.4,344.5,141.1,348.1,126.1,348.1"
          />
          <rect x="124.7" y="354.9" class="st22" width="2.7" height="2.7" />
          <rect x="124.7" y="370" class="st22" width="2.7" height="2.7" />
          <rect x="124.7" y="382.3" class="st22" width="2.7" height="2.7" />
          <path
            class="st22"
            d="M82.3,337.1h-5.5c-0.8,0-1.4,0.6-1.4,1.4v5.5c0,0.8,0.6,1.4,1.4,1.4h5.5c0.8,0,1.4-0.6,1.4-1.4v-5.5
            C83.6,337.7,83,337.1,82.3,337.1 M80.9,342.6h-2.7v-2.7h2.7V342.6z"
          />
          <path
            class="st22"
            d="M93.2,337.1h-5.5c-0.8,0-1.4,0.6-1.4,1.4v5.5c0,0.8,0.6,1.4,1.4,1.4h5.5c0.8,0,1.4-0.6,1.4-1.4v-5.5
            C94.6,337.7,94,337.1,93.2,337.1 M91.9,342.6h-2.7v-2.7h2.7V342.6z"
          />
          <path
            class="st22"
            d="M82.3,315.2h-5.5c-0.8,0-1.4,0.6-1.4,1.4v5.5c0,0.8,0.6,1.4,1.4,1.4h5.5c0.8,0,1.4-0.6,1.4-1.4v-5.5
            C83.6,315.8,83,315.2,82.3,315.2 M80.9,320.7h-2.7V318h2.7V320.7z"
          />
          <path
            class="st22"
            d="M93.2,315.2h-5.5c-0.8,0-1.4,0.6-1.4,1.4v5.5c0,0.8,0.6,1.4,1.4,1.4h5.5c0.8,0,1.4-0.6,1.4-1.4v-5.5
            C94.6,315.8,94,315.2,93.2,315.2 M91.9,320.7h-2.7V318h2.7V320.7z"
          />
          <rect x="97.3" y="315.2" class="st22" width="19.2" height="2.7" />
          <rect x="119.2" y="315.2" class="st22" width="2.7" height="2.7" />
          <rect x="97.3" y="320.7" class="st22" width="19.2" height="2.7" />
          <rect x="119.2" y="320.7" class="st22" width="2.7" height="2.7" />
          <path
            class="st22"
            d="M82.3,359h-5.5c-0.8,0-1.4,0.6-1.4,1.4v5.5c0,0.8,0.6,1.4,1.4,1.4h5.5c0.8,0,1.4-0.6,1.4-1.4v-5.5
            C83.6,359.6,83,359,82.3,359 M80.9,364.5h-2.7v-2.7h2.7V364.5z"
          />
          <path
            class="st22"
            d="M93.2,359h-5.5c-0.8,0-1.4,0.6-1.4,1.4v5.5c0,0.8,0.6,1.4,1.4,1.4h5.5c0.8,0,1.4-0.6,1.4-1.4v-5.5
            C94.6,359.6,94,359,93.2,359 M91.9,364.5h-2.7v-2.7h2.7V364.5z"
          />
          <path
            class="st22"
            d="M388.7,358.6h-75.8c-3.1,0-5.6,2.5-5.6,5.6V412c0,3.1,2.5,5.6,5.6,5.6h28.9l-1.9,5.6h-4.6
            c-0.8,0-1.4,0.6-1.4,1.4v2.8h-12.6c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4H383c0.8,0,1.4-0.6,1.4-1.4s-0.6-1.4-1.4-1.4h-15.4v-2.8
            c0-0.8-0.6-1.4-1.4-1.4h-4.6l-1.9-5.6h28.9c3.1,0,5.6-2.5,5.6-5.6v-47.7C394.3,361.2,391.8,358.7,388.7,358.6 M364.9,427.4h-28.1
            V426h28.1V427.4z M343,423.2l1.9-5.6h12l1.9,5.6H343z M391.5,412c0,1.5-1.3,2.8-2.8,2.8h-75.8c-1.5,0-2.8-1.3-2.8-2.8v-2.8h81.4
            L391.5,412L391.5,412z M331.1,393.7l-0.5,12.6H329l-0.5-12.6H331.1z M328.6,390.9l0.9-5.6h0.4l0.9,5.6H328.6z M329.8,382.5
            c-1.5,0-2.8-1.9-2.8-4.2c0-1.4,1.5-3.5,2.8-5c1.2,1.3,2.8,3.5,2.8,5C332.6,380.6,331.3,382.5,329.8,382.5 M345,379.7l0.2,0.3v1.1
            h-5.6V380l0.2-0.3H345z M341.2,376.9l1.2-2.5l1.2,2.5H341.2z M345.2,383.9v22.3c0,0.1,0,0.1,0,0.2h-5.6c0-0.1,0-0.1,0-0.2v-22.3
            H345.2z M357.8,378.3h4.2v2.8h-1.4c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4h1.4v2.8h-4.2c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4
            h4.2v2.8h-1.4c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4h1.4v2.8h-4.2c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4h4.2v5.6h-9.8v-33.7
            h9.8v2.8h-4.2c-0.8,0-1.4,0.6-1.4,1.4S357.1,378.3,357.8,378.3 M374.7,395.1v11c0,0.1,0,0.1,0,0.2h-5.6c0-0.1,0-0.1,0-0.2v-11
            H374.7z M369.1,392.3v-1.1l2.8-5.6l2.8,5.6v1.1H369.1z M391.5,406.4h-14c0-0.1,0-0.1,0-0.2v-15.3c0-0.2-0.1-0.4-0.1-0.6l-4.2-8.4
            c-0.3-0.7-1.2-1-1.9-0.6c-0.3,0.1-0.5,0.4-0.6,0.6l-4.2,8.4c-0.1,0.2-0.1,0.4-0.1,0.6v15.3c0,0.1,0,0.1,0,0.2H365v-35.1
            c0-0.8-0.6-1.4-1.4-1.4H351c-0.8,0-1.4,0.6-1.4,1.4v35.1H348c0-0.1,0-0.1,0-0.2v-26.5c0-0.2-0.1-0.4-0.1-0.6l-4.2-8.4
            c-0.3-0.7-1.2-1-1.9-0.6c-0.3,0.1-0.5,0.4-0.6,0.6l-4.2,8.4c-0.1,0.2-0.1,0.4-0.1,0.6v26.5c0,0.1,0,0.1,0,0.2h-3.4l0.6-14v-0.1l0,0
            v-0.1l0,0l-1.3-7.8c1.6-1.2,2.7-3.5,2.7-6c0-3.6-4.2-7.6-4.7-8c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.4-4.7,4.4-4.7,8c0,2.5,1.1,4.8,2.7,6
            l-1.3,7.8l0,0v0.1l0,0v0.1l0.6,14h-16v-42.1c0-1.5,1.3-2.8,2.8-2.8h75.8c1.5,0,2.8,1.3,2.8,2.8L391.5,406.4L391.5,406.4z"
          />
          <path
            class="st22"
            d="M322.8,410.6h-8.4c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4h8.4c0.8,0,1.4-0.6,1.4-1.4S323.5,410.6,322.8,410.6
            "
          />
          <path
            class="st22"
            d="M328.4,410.6H327c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4h1.4c0.8,0,1.4-0.6,1.4-1.4S329.2,410.6,328.4,410.6"
          />
          <path
            class="st22"
            d="M342.4,385.3c-0.8,0-1.4,0.6-1.4,1.4v15.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4v-15.4
            C343.8,385.9,343.2,385.3,342.4,385.3"
          />
          <path
            class="st22"
            d="M371.9,396.5c-0.8,0-1.4,0.6-1.4,1.4v4.2c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4v-4.2
            C373.3,397.2,372.6,396.5,371.9,396.5"
          />
          <path
            class="st22"
            d="M314.3,367.1h11.2c0.8,0,1.4-0.6,1.4-1.4s-0.6-1.4-1.4-1.4h-11.2c-0.8,0-1.4,0.6-1.4,1.4
            S313.6,367.1,314.3,367.1"
          />
          <path
            class="st22"
            d="M314.3,371.3h2.8c0.8,0,1.4-0.6,1.4-1.4s-0.6-1.4-1.4-1.4h-2.8c-0.8,0-1.4,0.6-1.4,1.4
            S313.6,371.3,314.3,371.3"
          />
          <path
            class="st22"
            d="M381.7,403.5h5.6c0.8,0,1.4-0.6,1.4-1.4s-0.6-1.4-1.4-1.4h-5.6c-0.8,0-1.4,0.6-1.4,1.4
            S380.9,403.5,381.7,403.5"
          />
          <path
            class="st22"
            d="M385.9,399.3h1.4c0.8,0,1.4-0.6,1.4-1.4s-0.6-1.4-1.4-1.4h-1.4c-0.8,0-1.4,0.6-1.4,1.4
            S385.1,399.3,385.9,399.3"
          />
          <path
            class="st22"
            d="M175.8,108h13.6c0.7,1.7,2.6,2.5,4.3,1.8s2.5-2.6,1.8-4.3s-2.6-2.5-4.3-1.8c-0.8,0.3-1.5,1-1.8,1.8h-13.6v-6
            h8.8c0.7,0,1.2-0.5,1.2-1.2v-8h3.6c0.7,1.7,2.6,2.5,4.3,1.8s2.5-2.6,1.8-4.3c-0.7-1.7-2.6-2.5-4.3-1.8c-0.8,0.3-1.5,1-1.8,1.8h-4.8
            c-0.7,0-1.2,0.5-1.2,1.2v8h-7.5v-6.1c0-0.7-0.5-1.2-1.2-1.2h-6.1v-7.5h8c0.7,0,1.2-0.5,1.2-1.2v-4.8c1.7-0.7,2.5-2.6,1.8-4.3
            c-0.7-1.7-2.6-2.5-4.3-1.8s-2.5,2.6-1.8,4.3c0.3,0.8,1,1.5,1.8,1.8v3.6h-8c-0.7,0-1.2,0.5-1.2,1.2v8.8h-6V76.3
            c1.7-0.7,2.5-2.6,1.8-4.3c-0.7-1.7-2.6-2.5-4.3-1.8s-2.5,2.6-1.8,4.3c0.3,0.8,1,1.5,1.8,1.8v13.6h-5.9V76.3
            c1.7-0.7,2.5-2.6,1.8-4.3c-0.7-1.7-2.6-2.5-4.3-1.8s-2.5,2.6-1.8,4.3c0.3,0.8,1,1.5,1.8,1.8v13.6h-6v-8.8c0-0.7-0.5-1.2-1.2-1.2h-8
            v-3.6c1.7-0.7,2.5-2.6,1.8-4.3c-0.7-1.7-2.6-2.5-4.3-1.8s-2.5,2.6-1.8,4.3c0.3,0.8,1,1.5,1.8,1.8v4.8c0,0.7,0.5,1.2,1.2,1.2h8v7.5
            h-6.1c-0.7,0-1.2,0.5-1.2,1.2v6.1h-7.5v-8c0-0.7-0.5-1.2-1.2-1.2h-4.8c-0.7-1.7-2.6-2.5-4.3-1.8c-1.7,0.7-2.5,2.6-1.8,4.3
            c0.7,1.7,2.6,2.5,4.3,1.8c0.8-0.3,1.5-1,1.8-1.8h3.6v8c0,0.7,0.5,1.2,1.2,1.2h8.8v6h-13.6c-0.7-1.7-2.6-2.5-4.3-1.8
            c-1.7,0.7-2.5,2.6-1.8,4.3c0.7,1.7,2.6,2.5,4.3,1.8c0.8-0.3,1.5-1,1.8-1.8h13.6v5.9h-13.6c-0.7-1.7-2.6-2.5-4.3-1.8
            c-1.7,0.7-2.5,2.6-1.8,4.3c0.7,1.7,2.6,2.5,4.3,1.8c0.8-0.3,1.5-1,1.8-1.8h13.6v6h-8.8c-0.7,0-1.2,0.5-1.2,1.2v8h-3.6
            c-0.7-1.7-2.6-2.5-4.3-1.8c-1.7,0.7-2.5,2.6-1.8,4.3s2.6,2.5,4.3,1.8c0.8-0.3,1.5-1,1.8-1.8h4.8c0.7,0,1.2-0.5,1.2-1.2v-8h7.5v6.1
            c0,0.7,0.5,1.2,1.2,1.2h6.1v7.5h-8c-0.7,0-1.2,0.5-1.2,1.2v4.8c-1.7,0.7-2.5,2.6-1.8,4.3s2.6,2.5,4.3,1.8s2.5-2.6,1.8-4.3
            c-0.3-0.8-1-1.5-1.8-1.8v-3.6h8c0.7,0,1.2-0.5,1.2-1.2v-8.8h6v13.6c-1.7,0.7-2.5,2.6-1.8,4.3s2.6,2.5,4.3,1.8s2.5-2.6,1.8-4.3
            c-0.3-0.8-1-1.5-1.8-1.8v-13.6h5.9v13.6c-1.7,0.7-2.5,2.6-1.8,4.3s2.6,2.5,4.3,1.8s2.5-2.6,1.8-4.3c-0.3-0.8-1-1.5-1.8-1.8v-13.6h6
            v8.8c0,0.7,0.5,1.2,1.2,1.2h8v3.6c-1.7,0.7-2.5,2.6-1.8,4.3s2.6,2.5,4.3,1.8s2.5-2.6,1.8-4.3c-0.3-0.8-1-1.5-1.8-1.8V141
            c0-0.7-0.5-1.2-1.2-1.2h-8v-7.5h6.1c0.7,0,1.2-0.5,1.2-1.2V125h7.5v8c0,0.7,0.5,1.2,1.2,1.2h4.8c0.7,1.7,2.6,2.5,4.3,1.8
            s2.5-2.6,1.8-4.3s-2.6-2.5-4.3-1.8c-0.8,0.3-1.5,1-1.8,1.8h-3.6v-8c0-0.7-0.5-1.2-1.2-1.2h-8.8v-6h13.6c0.7,1.7,2.6,2.5,4.3,1.8
            s2.5-2.6,1.8-4.3c-0.7-1.7-2.6-2.5-4.3-1.8c-0.8,0.3-1.5,1-1.8,1.8h-13.6L175.8,108L175.8,108z M192.4,106c0.5,0,0.9,0.4,0.9,0.9
            s-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9C191.5,106.3,191.9,106,192.4,106L192.4,106z M192.4,88.4c0.5,0,0.9,0.4,0.9,0.9
            s-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9S191.9,88.4,192.4,88.4L192.4,88.4z M176.4,72.4c0.5,0,0.9,0.4,0.9,0.9s-0.4,0.9-0.9,0.9
            s-0.9-0.4-0.9-0.9S175.9,72.4,176.4,72.4 M158.8,72.4c0.5,0,0.9,0.4,0.9,0.9s-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9
            C158,72.8,158.3,72.4,158.8,72.4 M150.4,72.4c0.5,0,0.9,0.4,0.9,0.9s-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9
            C149.6,72.8,150,72.4,150.4,72.4 M132.9,72.4c0.5,0,0.9,0.4,0.9,0.9s-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9S132.4,72.4,132.9,72.4
             M116.9,90.1c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9C117.7,89.7,117.3,90.1,116.9,90.1 M116.9,107.7
            c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9C117.7,107.3,117.3,107.7,116.9,107.7 M116.9,116.1
            c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9C117.7,115.7,117.3,116.1,116.9,116.1 M116.9,133.6
            c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9C117.7,133.3,117.3,133.6,116.9,133.6 M132.9,149.7
            c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9C133.7,149.3,133.3,149.7,132.9,149.7 M150.4,149.7
            c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9C151.3,149.3,150.9,149.7,150.4,149.7 M158.8,149.7
            c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9S159.3,149.7,158.8,149.7 M176.4,149.7c-0.5,0-0.9-0.4-0.9-0.9
            s0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9C177.3,149.3,176.9,149.7,176.4,149.7 M192.4,131.9c0.5,0,0.9,0.4,0.9,0.9s-0.4,0.9-0.9,0.9
            s-0.9-0.4-0.9-0.9C191.5,132.3,191.9,131.9,192.4,131.9 M192.4,114.3c0.5,0,0.9,0.4,0.9,0.9s-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9
            C191.5,114.7,191.9,114.3,192.4,114.3 M173.3,129.7h-37.4V92.3h37.4L173.3,129.7L173.3,129.7z"
          />
          <path
            class="st22"
            d="M168.3,96.1h-27.4c-0.7,0-1.2,0.5-1.2,1.2v27.4c0,0.7,0.5,1.2,1.2,1.2h27.4c0.7,0,1.2-0.5,1.2-1.2V97.3
            C169.5,96.6,169,96.1,168.3,96.1 M167.1,123.5h-25v-25h25V123.5z"
          />
          <path
            class="st22"
            d="M164.2,112.8c0.7,0,1.2-0.5,1.2-1.2v-6.8c0-0.7-0.5-1.2-1.2-1.2s-1.2,0.5-1.2,1.2v6.8
            C162.9,112.3,163.5,112.8,164.2,112.8"
          />
          <path
            class="st22"
            d="M392,172.3c-0.4,0-0.8,0.2-1.1,0.5c-0.3,0.3-0.5,0.7-0.5,1.1c0,0.4,0.2,0.8,0.5,1.1s0.7,0.5,1.1,0.5
            s0.8-0.2,1.1-0.5c0.3-0.3,0.5-0.7,0.5-1.1c0-0.4-0.2-0.8-0.5-1.1C392.8,172.4,392.4,172.3,392,172.3"
          />
          <path
            class="st22"
            d="M422.9,167.4c-0.4,0-0.8,0.2-1.1,0.5c-0.3,0.3-0.5,0.7-0.5,1.1c0,0.4,0.2,0.8,0.5,1.1s0.7,0.5,1.1,0.5
            s0.8-0.2,1.1-0.5c0.3-0.3,0.5-0.7,0.5-1.1c0-0.4-0.2-0.8-0.5-1.1C423.7,167.6,423.3,167.4,422.9,167.4"
          />
          <path
            class="st22"
            d="M375,159.6c-0.4,0-0.8,0.2-1.1,0.5c-0.3,0.3-0.5,0.7-0.5,1.1c0,0.4,0.2,0.8,0.5,1.1s0.7,0.5,1.1,0.5
            s0.8-0.2,1.1-0.5c0.3-0.3,0.5-0.7,0.5-1.1c0-0.4-0.2-0.8-0.5-1.1C375.9,159.8,375.4,159.6,375,159.6"
          />
          <path
            class="st22"
            d="M435.9,130.7c0-0.9-0.7-1.6-1.6-1.6h-2.5c-0.3-1.1-0.8-2.2-1.4-3.3l1.8-1.8c0.6-0.6,0.6-1.7,0-2.3l-4.9-4.9
            c-0.6-0.6-1.7-0.6-2.3,0l-1.8,1.8c-1-0.6-2.1-1-3.3-1.4v-2.5c0-0.9-0.7-1.6-1.6-1.6h-6.9c-0.9,0-1.6,0.7-1.6,1.6v2.5
            c-1.1,0.3-2.2,0.8-3.3,1.4l-1.8-1.8c-0.6-0.6-1.7-0.6-2.3,0l-4.9,4.9c-0.4,0.4-0.5,0.9-0.5,1.4h-37.6c-3.6,0-6.5,2.9-6.5,6.5v44.7
            c0,3.6,2.9,6.5,6.5,6.5h25.7v5.5h-3.9c-3.2,0-5.8,2.6-5.8,5.8v2.3c0,0.9,0.7,1.6,1.6,1.6h29.8c0.9,0,1.6-0.7,1.6-1.6v-2.3
            c0-3.2-2.6-5.8-5.8-5.8h-3.9v-5.5h25.7c3.6,0,6.5-2.9,6.5-6.5v-26.5l1.2-1.2c0.3-0.3,0.5-0.7,0.5-1.1c0-0.4-0.2-0.8-0.5-1.1
            l-1.8-1.8c0.6-1,1-2.1,1.4-3.3h2.5c0.4,0,0.8-0.2,1.1-0.5c0.3-0.3,0.5-0.7,0.5-1.1L435.9,130.7z M405.3,192.2v0.6h-26.5v-0.6
            c0-1.4,1.2-2.6,2.6-2.6h21.4C404.1,189.6,405.3,190.7,405.3,192.2 M395.6,186.3h-7.1v-5.5h7.1V186.3z M427.7,174.4
            c0,1.8-1.5,3.2-3.2,3.2h-65c-1.8,0-3.2-1.5-3.2-3.2v-3.7h60.2c0.9,0,1.6-0.7,1.6-1.6c0-0.9-0.7-1.6-1.6-1.6h-60.2v-4.6h12.4
            c0.9,0,1.6-0.7,1.6-1.6s-0.7-1.6-1.6-1.6h-12.4v-29.9c0-1.8,1.5-3.2,3.2-3.2H399c-0.4,0.9-0.8,1.8-1,2.7h-2.5
            c-0.4,0-0.8,0.2-1.1,0.5c-0.3,0.3-0.5,0.7-0.5,1.1v6.9c0,0.9,0.7,1.6,1.6,1.6h2.5c0.3,1.1,0.8,2.2,1.4,3.3l-1.8,1.8
            c-0.6,0.6-0.6,1.7,0,2.3l4.9,4.9c0.6,0.6,1.7,0.6,2.3,0l1.8-1.8c1,0.6,2.1,1,3.3,1.4v2.5c0,0.9,0.7,1.6,1.6,1.6h6.9
            c0.9,0,1.6-0.7,1.6-1.6v-2.5c1.1-0.3,2.2-0.8,3.3-1.4l1.8,1.8c0.3,0.3,0.7,0.5,1.1,0.5s0.8-0.2,1.1-0.5l0.5-0.5L427.7,174.4
            L427.7,174.4z M432.6,136.1h-2.1c-0.8,0-1.4,0.5-1.6,1.3c-0.4,1.6-1,3.2-1.9,4.6c-0.4,0.6-0.3,1.5,0.2,2l1.5,1.5l-2.6,2.6l-1.5-1.5
            c-0.5-0.5-1.2-0.6-1.9-0.3l-0.1,0.1c-1.4,0.9-3,1.5-4.6,1.9c-0.7,0.2-1.3,0.8-1.3,1.6v2.1H413v-2.1c0-0.8-0.5-1.4-1.3-1.6
            c-1.6-0.4-3.2-1-4.6-1.9c-0.3-0.2-0.6-0.3-0.9-0.3c-0.4,0-0.8,0.2-1.1,0.5l-1.5,1.5l-2.6-2.6l1.5-1.5c0.5-0.5,0.6-1.4,0.2-2
            c-0.9-1.4-1.5-3-1.9-4.6c-0.2-0.7-0.8-1.3-1.6-1.3H397v-3.7h2.1c0.8,0,1.4-0.5,1.6-1.3c0.4-1.6,1-3.2,1.9-4.6
            c0.4-0.6,0.3-1.5-0.2-2l-1.5-1.5l2.6-2.6l1.5,1.5c0.6,0.6,1.4,0.6,2,0.2c1.4-0.9,3-1.5,4.6-1.9c0.7-0.2,1.3-0.8,1.3-1.6v-2.1h3.7
            v2.1c0,0.8,0.5,1.4,1.3,1.6c1.6,0.4,3.2,1,4.6,1.9l0.1,0.1c0.6,0.3,1.4,0.2,1.9-0.3l1.5-1.5l2.6,2.6l-1.5,1.5
            c-0.1,0.1-0.1,0.2-0.2,0.2c-0.4,0.5-0.4,1.2,0,1.8c0.9,1.4,1.6,3,1.9,4.6c0.2,0.7,0.8,1.3,1.6,1.3h2.1v3.7H432.6z"
          />
          <path
            class="st22"
            d="M414.8,124.2c-5.5,0-10,4.5-10,10s4.5,10,10,10s10-4.5,10-10C424.9,128.7,420.4,124.2,414.8,124.2 M414.8,141
            c-3.7,0-6.8-3-6.8-6.8c0-3.7,3-6.8,6.8-6.8c3.7,0,6.8,3,6.8,6.8C421.6,138,418.6,141,414.8,141"
          />
          <path
            class="st22"
            d="M379.5,143.5c-0.4,0-0.8,0.2-1.1,0.5c-0.3,0.3-0.5,0.7-0.5,1.1c0,0.4,0.2,0.8,0.5,1.1s0.7,0.5,1.1,0.5
            s0.8-0.2,1.1-0.5c0.3-0.3,0.5-0.7,0.5-1.1c0-0.4-0.2-0.8-0.5-1.1C380.3,143.7,379.9,143.5,379.5,143.5"
          />
          <path
            class="st22"
            d="M392.1,142.3c0.1-0.4,0.1-0.9-0.2-1.2l-2.7-4.7c-0.4-0.8-1.4-1-2.2-0.6l-1.6,0.9c-0.5-0.4-1.1-0.7-1.6-0.9V134
            c0-0.9-0.7-1.6-1.6-1.6h-5.5c-0.9,0-1.6,0.7-1.6,1.6v1.8c-0.6,0.3-1.1,0.6-1.6,0.9l-1.6-0.9c-0.4-0.2-0.8-0.3-1.2-0.2
            c-0.4,0.1-0.8,0.4-1,0.8l-2.7,4.7c-0.4,0.8-0.2,1.8,0.6,2.2l1.6,0.9c0,0.3,0,0.6,0,0.9s0,0.6,0,0.9l-1.6,0.9
            c-0.8,0.4-1,1.4-0.6,2.2l2.7,4.7c0.2,0.4,0.6,0.6,1,0.8c0.4,0.1,0.9,0.1,1.2-0.2l1.6-0.9c0.5,0.4,1.1,0.7,1.6,0.9v1.8
            c0,0.9,0.7,1.6,1.6,1.6h5.5c0.9,0,1.6-0.7,1.6-1.6v-1.8c0.6-0.3,1.1-0.6,1.6-0.9l1.6,0.9c0.8,0.4,1.8,0.2,2.2-0.6l2.7-4.7
            c0.2-0.4,0.3-0.8,0.2-1.2c-0.1-0.4-0.4-0.8-0.8-1l-1.6-0.9c0-0.3,0-0.6,0-0.9s0-0.6,0-0.9l1.6-0.9
            C391.7,143.1,392,142.7,392.1,142.3 M386.5,143.6c0.1,0.5,0.1,1,0.1,1.5s0,1-0.1,1.5c-0.1,0.7,0.2,1.4,0.8,1.7l1.1,0.6l-1.1,1.9
            l-1.1-0.6c-0.6-0.4-1.4-0.3-1.9,0.2c-0.7,0.7-1.6,1.2-2.5,1.5c-0.7,0.2-1.1,0.8-1.1,1.5v1.3h-2.2v-1.3c0-0.7-0.4-1.3-1.1-1.5
            c-0.9-0.3-1.8-0.8-2.5-1.5c-0.5-0.5-1.3-0.5-1.9-0.2l-1.1,0.6l-1.1-1.9l1.1-0.6c0.6-0.3,0.9-1,0.8-1.7c-0.1-0.5-0.1-1-0.1-1.5
            s0-1,0.1-1.5c0.1-0.7-0.2-1.4-0.8-1.7l-1.1-0.6l1.1-1.9l1.1,0.6c0.6,0.4,1.4,0.3,1.9-0.2c0.7-0.7,1.6-1.1,2.5-1.5
            c0.7-0.2,1.1-0.8,1.1-1.5v-1.3h2.2v1.3c0,0.7,0.4,1.3,1.1,1.5c0.9,0.3,1.8,0.8,2.5,1.5c0.5,0.5,1.3,0.5,1.9,0.2l1.1-0.6l1.1,1.9
            l-1.1,0.6C386.6,142.3,386.3,143,386.5,143.6"
          />
        </g>
        <path
          id="st3"
          class={`${this.props.darkMode ? "dark-mode" : ""}`}
          d="M278,247.4c3,3,4.5,6.7,4.5,10.9c0,4.2-1.5,7.9-4.5,10.9c-3.1,3.1-6.7,4.6-10.9,4.6c-4.7,0-8.5-1.8-11.7-5.4
        c0-0.1-0.2-0.1-0.4-0.1c-0.1,0.1-0.1,0.1-0.1,0.2v15h-3.2v-25.4c0-4.2,1.5-7.8,4.5-10.7c3-3,6.7-4.5,10.9-4.5
        C271.3,242.9,274.9,244.4,278,247.4 M258.4,249.7c-2.4,2.4-3.5,5.3-3.5,8.6c0,3.4,1.2,6.2,3.5,8.6c2.4,2.4,5.3,3.5,8.6,3.5
        c3.4,0,6.3-1.1,8.6-3.5c2.4-2.4,3.6-5.2,3.6-8.6s-1.2-6.2-3.6-8.6c-2.4-2.4-5.3-3.5-8.6-3.5C263.7,246.2,260.8,247.3,258.4,249.7"
        />
        <path
          class={`${this.props.darkMode ? "dark-mode" : ""}`}
          id="st3"
          d="M244.1,233.5v28.3c0,2.4-0.8,4.4-2.5,6.1s-3.7,2.5-6.1,2.5c-2.4,0-4.4-0.8-6.1-2.5c-1.7-1.7-2.5-3.7-2.5-6.1
        v-18.3h-3.2v18.3c0,3.3,1.1,6.1,3.5,8.5c2.3,2.4,5.2,3.5,8.4,3.5c3.3,0,6.1-1.2,8.4-3.5c2.3-2.3,3.5-5.1,3.5-8.5v-28.3L244.1,233.5
        L244.1,233.5z"
        />
        <g>
          <g>
            <defs>
              <path
                id="SVGID_1_"
                d="M286.6,243.5l9.4,13.3c0.3,0.5,0.5,1,0.5,1.5c0,0.6-0.2,1.1-0.5,1.6l-9.5,13.4h4.1l8.2-11.5
                    c0.7-1,1.1-2.2,1.1-3.5s-0.4-2.5-1.1-3.5l-8.1-11.4h-4.1V243.5z"
              />
            </defs>
            <clipPath id="SVGID_2_">
              <use xlinkHref="#SVGID_1_" overflow={this.state.ovver} />
            </clipPath>

            <linearGradient
              id="SVGID_3_"
              gradientUnits="userSpaceOnUse"
              x1="-702.8361"
              y1="-6504.1099"
              x2="-701.8361"
              y2="-6504.1099"
              gradientTransform="matrix(13.3268 0 0 13.3268 9653.0557 86937.375)"
            >
              <stop offset="0" stopColor={this.state.color1} />
              <stop offset="7.290000e-02" stopColor={this.state.color2} />
              <stop offset="0.5024" stopColor={this.state.color3} />
              <stop offset="0.8243" stopColor={this.state.color4} />
              <stop offset="1" stopColor={this.state.color5} />
            </linearGradient>
            <rect x="286.5" y="243.5" class="st4" width="13.3" height="29.8" />
          </g>
        </g>
        <g>
          <g>
            <defs>
              <path
                id="SVGID_4_"
                d="M215.1,243.5L207,255c-0.7,1-1.1,2.2-1.1,3.5s0.4,2.5,1.1,3.5l8.1,11.4h4l-9.4-13.3
                    c-0.3-0.5-0.5-1-0.5-1.5c0-0.6,0.2-1.1,0.5-1.6l9.5-13.4h-4.1V243.5z"
              />
            </defs>
            <clipPath id="SVGID_5_">
              <use xlinkHref="#SVGID_4_" overflow={this.state.ovver} />
            </clipPath>

            <linearGradient
              id="SVGID_6_"
              gradientUnits="userSpaceOnUse"
              x1="-754.0253"
              y1="-6494.9111"
              x2="-753.0251"
              y2="-6494.9111"
              gradientTransform="matrix(-13.3273 0 0 -13.3273 -9829.9639 -86301.2266)"
            >
              <stop offset="0" stopColor={this.state.color1} />
              <stop offset="7.290000e-02" stopColor={this.state.color2} />
              <stop offset="0.5024" stopColor={this.state.color3} />
              <stop offset="0.8243" stopColor={this.state.color4} />
              <stop offset="1" stopColor={this.state.color5} />
            </linearGradient>
            <rect x="205.8" y="243.5" class="st5" width="13.3" height="29.8" />
          </g>
        </g>
      </svg>
    );
  }
}

export default Skillsimage;
