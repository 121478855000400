import {
  WHAT_WE_DO_CREATE_FAIL,
  WHAT_WE_DO_CREATE_REQUEST,
  WHAT_WE_DO_CREATE_SUCCESS,
  WHAT_WE_DO_DELETE_FAIL,
  WHAT_WE_DO_DELETE_REQUEST,
  WHAT_WE_DO_DELETE_SUCCESS,
  WHAT_WE_DO_DETAIL_FAIL,
  WHAT_WE_DO_DETAIL_REQUEST,
  WHAT_WE_DO_DETAIL_SUCCESS,
  WHAT_WE_DO_LIST_FAIL,
  WHAT_WE_DO_LIST_REQUEST,
  WHAT_WE_DO_LIST_SUCCESS,
  WHAT_WE_DO_UPDATE_FAIL,
  WHAT_WE_DO_UPDATE_REQUEST,
  WHAT_WE_DO_UPDATE_RESET,
  WHAT_WE_DO_UPDATE_SUCCESS,
} from "../../constants/whatWeDoConstant";

export const whatWeDoCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case WHAT_WE_DO_CREATE_REQUEST:
      return { loading: true };
    case WHAT_WE_DO_CREATE_SUCCESS:
      return { loading: false, success: true };
    case WHAT_WE_DO_CREATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const whatWeDoListReducer = (state = { whatWeDos: [] }, action) => {
  switch (action.type) {
    case WHAT_WE_DO_LIST_REQUEST:
      return { ...state, loading: true };
    case WHAT_WE_DO_LIST_SUCCESS:
      return { loading: false, whatWeDos: action.payload };
    case WHAT_WE_DO_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const WhatWeDoEditScreenReducer = (state = { whatWeDo: {} }, action) => {
  switch (action.type) {
    case WHAT_WE_DO_UPDATE_REQUEST:
      return { ...state, loading: true };
    case WHAT_WE_DO_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case WHAT_WE_DO_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case WHAT_WE_DO_UPDATE_RESET:
      return { whatWeDo: {} };
    default:
      return state;
  }
};

export const whatWeDoDetailsReducer = (state = { whatWeDo: {} }, action) => {
  switch (action.type) {
    case WHAT_WE_DO_DETAIL_REQUEST:
      return { ...state, loading: true };
    case WHAT_WE_DO_DETAIL_SUCCESS:
      return { loading: false, success: true, whatWeDo: action.payload };
    case WHAT_WE_DO_DETAIL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const whatWeDoDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case WHAT_WE_DO_DELETE_REQUEST:
      return { loading: true };
    case WHAT_WE_DO_DELETE_SUCCESS:
      return { loading: false, success: true };
    case WHAT_WE_DO_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
