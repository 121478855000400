import { useEffect, useState } from "react";
import axios from "axios";
import {
  Button,
  Box,
  Typography,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link,
} from "@mui/material";
import TableScreen from "./components/TableScreen";
import Loader from "./components/Loader";
import Message from "./components/Message";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getBlogDetails,
  updateBlog,
  listCategories,
} from "../state/actions/blogActions";
import { BLOG_DETAILS_RESET } from "../constants/blogConstants";
import { Image } from "cloudinary-react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { listAuthors } from "../state/actions/authorActions";
import QuillEditor from "./components/QuillEditor";

const BlogEditScreen = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  let { id } = useParams();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const blogDetails = useSelector((state) => state.blogDetails);
  const { loading, error, blog } = blogDetails;

  const blogEdit = useSelector((state) => state.blogEdit);
  const { loading: loadingEdit, error: errorEdit, success } = blogEdit;

  const blogCategoryList = useSelector((state) => state.blogCategoryList);
  const {
    categories,
    loading: loadingCategories,
    error: errorCategories,
  } = blogCategoryList;

  const authorList = useSelector((state) => state.authorList);
  const { authors, loading: loadingAuthors, error: errorAuthors } = authorList;

  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [body, setBody] = useState([]);
  const [category, setCategory] = useState("");
  const [image, setImage] = useState("");
  const [keywords, setkeywords] = useState([]);
  const [keyword, setKeyword] = useState("");

  const [files, setFiles] = useState([]);

  const [link, setLink] = useState([]);

  const [singleBody, setSingleBody] = useState("");
  const [showEditor, setShowEditor] = useState(false);
  const [updateIndex, setUpdateIndex] = useState();
  const [singleLink, setSingleLink] = useState("");
  const [showInput, setShowInput] = useState(false);

  const [newBody, setNewBody] = useState(false);
  const [newLink, setNewLink] = useState(false);

  const onEditorChange = (value) => {
    setSingleBody(value);
  };

  const onFilesChange = (files) => {
    setFiles(files);
  };

  const [cat, setCat] = useState({});
  const [auth, setAuth] = useState({});

  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (userInfo) {
      if (success) {
        history.push("/admin/blog");
        dispatch({ type: BLOG_DETAILS_RESET });
      } else {
        if (!blog || blog._id !== id) {
          dispatch(getBlogDetails(id));
        } else {
          dispatch(listAuthors());
          dispatch(listCategories());
          setTitle(blog.title);
          setAuthor(blog.author._id);
          setBody(blog.body);
          setCat(blog.category);
          setAuth(blog.author);
          setImage(blog.image);
          setCategory(blog.category._id);
          setkeywords(blog.keywords);
          setLink(blog.link);
        }
      }
    } else {
      history.push("/admin/login");
    }
  }, [history, userInfo, success, dispatch, blog, id]);

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "pd52bchw");
    setUploading(true);
    try {
      const { data } = await axios.post(
        "https://api.cloudinary.com/v1_1/detmdijki/image/upload",
        formData
      );
      setImage(data.url);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateBlog({
        id,
        title,
        author,
        category,
        body,
        image,
        keywords,
        link,
      })
    );
  };

  const deleteHandler = (index) => {
    setkeywords([
      ...keywords.slice(0, index),
      ...keywords.slice(index + 1, keywords.length),
    ]);
  };

  const addHandler = () => {
    setkeywords((prevKeywords) => [...prevKeywords, keyword]);
    setKeyword("");
  };

  const linkHandler = (l, i) => {};

  return (
    <TableScreen>
      <Box>
        <form onSubmit={submitHandler}>
          <Typography variant="h5" textAlign="center" sx={{ mb: 3 }}>
            Update new Blog
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={1} />
            <Grid item xs={4}>
              <Image
                style={{ width: 355 }}
                cloudName="bwiy0n2g"
                publicId={image}
              />
              <Button
                fullWidth={true}
                variant="contained"
                component="label"
                sx={{ my: 1 }}
              >
                CHANGE IMAGE
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={uploadFileHandler}
                />
              </Button>
            </Grid>
            {loading ? (
              <Loader />
            ) : (
              <Grid item xs={3}>
                <TextField
                  fullWidth={true}
                  type="text"
                  id="outlined-basic"
                  label="Title"
                  variant="outlined"
                  required
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  sx={{ mb: 5 }}
                />
                {loadingAuthors ? (
                  <Loader />
                ) : errorAuthors ? (
                  <Message>{errorAuthors}</Message>
                ) : (
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel id="label">Author</InputLabel>
                    <Select
                      label="Author"
                      labelId="label"
                      value={auth._id}
                      onChange={(e) => setAuthor(e.target.value)}
                    >
                      {authors.map((a) => (
                        <MenuItem key={a._id} value={a._id}>
                          {a.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                {loadingCategories ? (
                  <Loader />
                ) : errorCategories ? (
                  <Message>{errorCategories}</Message>
                ) : (
                  <FormControl fullWidth>
                    <InputLabel id="label">Category</InputLabel>
                    <Select
                      label="Category"
                      labelId="label"
                      value={cat._id}
                      onChange={(e) => setCategory(e.target.value)}
                    >
                      {categories.map((c) => (
                        <MenuItem key={c._id} value={c._id}>
                          {c.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Grid>
            )}
            <Grid item xs={3}>
              <TableContainer component={Paper} sx={{ mb: 7 }}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Keyword</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {keywords.map((k, i) => (
                      <TableRow key={i}>
                        <TableCell
                          dangerouslySetInnerHTML={{ __html: k }}
                        ></TableCell>
                        <TableCell>
                          <Button
                            onClick={() => deleteHandler(i)}
                            variant="outlined"
                            color="error"
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <ReactQuill theme="snow" value={keyword} onChange={setKeyword} />
              <Button
                sx={{ mt: 3 }}
                variant="contained"
                color="primary"
                size="large"
                fullWidth={true}
                onClick={() => addHandler()}
              >
                ADD KEYWORD
              </Button>
            </Grid>
            <Grid item xs={1} />
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={1} />
            <Grid item xs={10}>
              <Box sx={{ mt: 2 }}>
                <TableContainer component={Paper} sx={{ mb: 2 }}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Body</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {body.length !== 0 &&
                        body.map((b, i) => {
                          return (
                            <TableRow key={i}>
                              <TableCell
                                dangerouslySetInnerHTML={{ __html: b }}
                              ></TableCell>
                              <TableCell>
                                <Button
                                  sx={{ my: 2 }}
                                  variant="outlined"
                                  color="error"
                                  onClick={() => {
                                    setSingleBody(b);
                                    setNewBody(false);
                                    setShowEditor(true);
                                    setUpdateIndex(i);
                                  }}
                                >
                                  Edit
                                </Button>
                                <Button
                                  sx={{ my: 2 }}
                                  variant="outlined"
                                  color="error"
                                  onClick={() => {
                                    console.log("delete");
                                    setBody(body.splice(i, 1));
                                  }}
                                >
                                  Delete
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                {showEditor && (
                  <>
                    <QuillEditor
                      placeholder={"Start Posting Something"}
                      onEditorChange={onEditorChange}
                      onFilesChange={onFilesChange}
                      value={singleBody}
                    />
                    <Box display="flex">
                      <Button
                        sx={{ my: 4 }}
                        onClick={() => {
                          body[updateIndex] = singleBody;
                          setShowEditor(false);
                        }}
                      >
                        Update
                      </Button>
                      <Button
                        color="secondary"
                        sx={{ my: 4 }}
                        onClick={() => {
                          setSingleBody("");
                          setShowEditor(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </>
                )}
              </Box>
              <Box sx={{ mt: 2 }}>
                <TableContainer component={Paper} sx={{ mb: 2 }}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Social Media Links</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {link.length !== 0 &&
                        link.map((l, i) => {
                          return (
                            <TableRow key={i}>
                              <TableCell
                                style={{
                                  overflow: "auto",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                                sx={{ maxWidth: 200 }}
                              >
                                <Link
                                  href={l}
                                  target="_blank"
                                  rel="noopener"
                                  style={{ cursor: "pointer" }}
                                >
                                  {l}
                                </Link>
                              </TableCell>
                              <TableCell>
                                <Button
                                  variant="outlined"
                                  color="error"
                                  onClick={() => {
                                    setSingleLink(l);
                                    setNewLink(false);
                                    setShowInput(true);
                                    setUpdateIndex(i);
                                  }}
                                >
                                  Edit
                                </Button>
                                <Button
                                  sx={{ ml: 2 }}
                                  variant="outlined"
                                  color="error"
                                  onClick={() => {
                                    link.length === 1
                                      ? setLink([])
                                      : setLink(link.splice(i, 1));
                                  }}
                                >
                                  Delete
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                {showInput && (
                  <>
                    <TextField
                      sx={{ my: 2 }}
                      type="text"
                      id="outlined-basic"
                      variant="outlined"
                      fullWidth
                      value={singleLink}
                      onChange={(e) => setSingleLink(e.target.value)}
                    />
                    <Box display="flex">
                      <Button
                        onClick={() => {
                          link[updateIndex] = singleLink;
                          setShowInput(false);
                        }}
                      >
                        Update
                      </Button>
                      <Button
                        color="secondary"
                        sx={{ my: 4 }}
                        onClick={() => {
                          setSingleLink("");
                          setShowInput(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </>
                )}
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                mt={4}
                mb={3}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth={true}
                  onClick={() => {
                    setShowEditor(false);
                    setSingleBody("");
                    setNewBody(true);
                  }}
                >
                  Add New Body
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth={true}
                  sx={{ ml: 5 }}
                  onClick={() => {
                    setShowInput(false);
                    setSingleLink("");
                    setNewLink(true);
                  }}
                >
                  Add New Embeded Post
                </Button>
              </Box>
              {newBody && (
                <>
                  <QuillEditor
                    placeholder={"Start Posting Something"}
                    onEditorChange={onEditorChange}
                    onFilesChange={onFilesChange}
                    value={singleBody}
                  />
                  <Box display="flex">
                    <Button
                      onClick={() => {
                        setBody((prevBody) => [...prevBody, singleBody]);
                        setNewBody(false);
                      }}
                    >
                      Update
                    </Button>
                    <Button
                      color="secondary"
                      sx={{ my: 4 }}
                      onClick={() => {
                        setNewBody(false);
                        setSingleBody("");
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </>
              )}
              {newLink && (
                <>
                  <TextField
                    sx={{ mt: 4 }}
                    type="text"
                    id="outlined-basic"
                    label="Social Media Link (Not required)"
                    variant="outlined"
                    fullWidth
                    value={singleLink}
                    onChange={(e) => setSingleLink(e.target.value)}
                  />
                  <Box display="flex">
                    <Button
                      onClick={() => {
                        setLink((prevLink) => [...prevLink, singleLink]);
                        setNewLink(false);
                      }}
                    >
                      Update
                    </Button>
                    <Button
                      color="secondary"
                      sx={{ my: 4 }}
                      onClick={() => {
                        setNewLink(false);
                        setSingleLink("");
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </>
              )}
              {uploading ? (
                <Loader />
              ) : (
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  sx={{ my: 4 }}
                >
                  Save Changes
                </Button>
              )}
              {errorEdit && <Message>{errorEdit}</Message>}
            </Grid>
            <Grid item xs={1} />
          </Grid>
          <></>
        </form>
      </Box>
    </TableScreen>
  );
};

export default BlogEditScreen;
