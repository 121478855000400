export const WHAT_WE_DO_CREATE_REQUEST = "WHAT_WE_DO_CREATE_REQUEST";
export const WHAT_WE_DO_CREATE_SUCCESS = "WHAT_WE_DO_CREATE_SUCCESS";
export const WHAT_WE_DO_CREATE_FAIL = "WHAT_WE_DO_CREATE_FAIL";

export const WHAT_WE_DO_LIST_REQUEST = "WHAT_WE_DO_LIST_REQUEST";
export const WHAT_WE_DO_LIST_SUCCESS = "WHAT_WE_DO_LIST_SUCCESS";
export const WHAT_WE_DO_LIST_FAIL = "WHAT_WE_DO_LIST_FAIL";

export const WHAT_WE_DO_UPDATE_REQUEST = "WHAT_WE_DO_UPDATE_REQUEST";
export const WHAT_WE_DO_UPDATE_SUCCESS = "WHAT_WE_DO_UPDATE_SUCCESS";
export const WHAT_WE_DO_UPDATE_FAIL = "WHAT_WE_DO_UPDATE_FAIL";
export const WHAT_WE_DO_UPDATE_RESET = "WHAT_WE_DO_UPDATE_RESET";

export const WHAT_WE_DO_DETAIL_REQUEST = "WHAT_WE_DO_DETAIL_REQUEST";
export const WHAT_WE_DO_DETAIL_SUCCESS = "WHAT_WE_DO_DETAIL_SUCCESS";
export const WHAT_WE_DO_DETAIL_FAIL = "WHAT_WE_DO_DETAIL_FAIL";

export const WHAT_WE_DO_DELETE_REQUEST = "WHAT_WE_DO_DELETE_REQUEST";
export const WHAT_WE_DO_DELETE_SUCCESS = "WHAT_WE_DO_DELETE_SUCCESS";
export const WHAT_WE_DO_DELETE_FAIL = "WHAT_WE_DO_DELETE_FAIL";
