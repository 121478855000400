import React, { Component } from "react";
import Mobile from "../Mobile/mobile";

class SmWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      darkMode: this.props.darkMode,
    };
  }
  render() {
    return <Mobile darkMode={this.props.darkMode} />;
  }
}
export default SmWrapper;
