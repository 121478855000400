import React, { useState, useEffect } from "react";
import TableScreen from "./components/TableScreen";
import Message from "./components/Message";
import Loader from "./components/Loader";
import { Button, TextField, Typography, Box } from "@mui/material";
import { useHistory, useParams } from "react-router-dom";
import {
  getCategoryById,
  updateCategory,
} from "../state/actions/categoryActions";
import { useDispatch, useSelector } from "react-redux";
import { Image } from "cloudinary-react";
import axios from "axios";
import {
  CATEGORY_DETAILS_RESET,
  CATEGORY_UPDATE_RESET,
} from "../constants/categoryConstants";

const PortfolioCategoryEdit = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  let { id } = useParams();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const categoryDetails = useSelector((state) => state.categoryDetails);
  const { loading, error, category } = categoryDetails;

  const categoryUpdate = useSelector((state) => state.categoryUpdate);
  const {
    success,
    loading: loadingUpdate,
    error: errorUpdate,
  } = categoryUpdate;

  const [name, setName] = useState();
  const [image, setImage] = useState();
  const [selectedImage, setselectedImage] = useState();

  useEffect(() => {
    if (userInfo) {
      if (success) {
        dispatch({ type: CATEGORY_DETAILS_RESET });
        dispatch({ type: CATEGORY_UPDATE_RESET });
        history.push("/admin/portfolio/categories");
      } else {
        if (!category || category._id !== id) {
          dispatch(getCategoryById(id));
        } else {
          setName(category.name);
          setImage(category.bgImage);
        }
      }
    } else {
      history.push("/admin/login");
    }
  }, [history, userInfo, id, category, dispatch, success]);

  async function upload(selectedImage) {
    const formData = new FormData();
    formData.append("file", selectedImage);
    formData.append("upload_preset", "bwiy0n2g");
    const { data } = await axios.post(
      "https://api.cloudinary.com/v1_1/detmdijki/image/upload",
      formData
    );
    return data.url;
  }

  const submitHandler = async (e) => {
    e.preventDefault();
    if (selectedImage) {
      let bgImage = await upload(selectedImage);
      setTimeout(() => {
        dispatch(updateCategory({ id, name, bgImage }));
      }, 3000);
    } else {
      let bgImage = image;
      dispatch(updateCategory({ id, name, bgImage }));
    }
  };

  return (
    <TableScreen>
      <Box display="flex" justifyContent="center">
        <form onSubmit={submitHandler}>
          <Box
            display="flex"
            flexDirection="column"
            height="60vh"
            sx={{
              justifyContent: "space-between",
            }}
          >
            {error && <Message>{error}</Message>}
            <Typography variant="h5">Edit Category</Typography>
            <TextField
              type="text"
              id="outlined-basic"
              variant="outlined"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {selectedImage ? (
              <img
                src={URL.createObjectURL(selectedImage)}
                alt=""
                width="220px"
              />
            ) : (
              <Image
                style={{ width: 220 }}
                cloudName="bwiy0n2g"
                publicId={image}
              />
            )}
            <Button
              size="large"
              variant="contained"
              color="info"
              onClick={() => {
                dispatch({ type: CATEGORY_DETAILS_RESET });
                history.push("/admin/portfolio/categories");
              }}
            >
              Cancel
            </Button>
            <Button variant="contained" component="label">
              Change Image
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={(e) => setselectedImage(e.target.files[0])}
              />
            </Button>
            <Button
              type="submit"
              size="large"
              variant="contained"
              color="primary"
            >
              Update
            </Button>
            {errorUpdate && <Message>{errorUpdate}</Message>}
          </Box>
        </form>
      </Box>
    </TableScreen>
  );
};

export default PortfolioCategoryEdit;
