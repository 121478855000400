import React, { Component } from "react";
import Desktop from "../Desktop/desktop";

class DesWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      darkMode: this.props.darkMode,
    };
  }
  render() {
    return <Desktop darkMode={this.props.darkMode} />;
  }
}

export default DesWrapper;
