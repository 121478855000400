import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  joinTeamList,
  deleteJoinTeam,
} from "../state/actions/joinOurTeamActions";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link,
} from "@mui/material";
import TableScreen from "./components/TableScreen";
import Message from "./components/Message";
import Loader from "./components/Loader";

const JoinOurTeamScreen = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const joinOurTeamList = useSelector((state) => state.joinOurTeamList);
  const { joinTeams, loading, error } = joinOurTeamList;

  const joinOurTeamDelete = useSelector((state) => state.joinOurTeamDelete);
  const {
    success,
    loading: loadingDelete,
    error: errorDelete,
  } = joinOurTeamDelete;

  useEffect(() => {
    if (!userInfo) {
      history.push("/admin/login");
    }
    dispatch(joinTeamList());
  }, [history, userInfo, dispatch, success]);

  const deleteHandler = (id) => {
    dispatch(deleteJoinTeam(id));
  };

  return (
    <TableScreen>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Message</TableCell>
              <TableCell>CV Link</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <Loader />
            ) : error ? (
              <Message>{error}</Message>
            ) : (
              joinTeams.map((joinTeam) => (
                <TableRow key={joinTeam._id}>
                  <TableCell>{`${joinTeam.fname} ${joinTeam.lname}`}</TableCell>
                  <TableCell>{joinTeam.email}</TableCell>
                  <TableCell>{joinTeam.message}</TableCell>
                  <TableCell>
                    <Link
                      underline="hover"
                      href={joinTeam.cv_link}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {joinTeam.cv_link}
                    </Link>
                  </TableCell>
                  <TableCell>
                    {loadingDelete ? (
                      <Loader />
                    ) : errorDelete ? (
                      <Message>{errorDelete}</Message>
                    ) : (
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => deleteHandler(joinTeam._id)}
                      >
                        Delete
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </TableScreen>
  );
};

export default JoinOurTeamScreen;
