import React from "react";
import { useHistory } from "react-router";
import { ReactComponent as Mouse } from "../../svgs/mouse.svg";

const ScrollBtn = () => {
  const history = useHistory();
  return (
    <div
      className="scroll-btn"
      onClick={() => {
        history.push("/#who-we-are");
      }}
    >
      <Mouse />
    </div>
  );
};

export default ScrollBtn;
