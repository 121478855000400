import React, { useState, useEffect } from "react";
import NavLogo from "../../../components/NavBar/NavLogo";
import styles from "./BlogHeader.module.scss";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { listCategories } from "../../../state/actions/blogActions";
import { BLOG_DETAILS_RESET } from "../../../constants/blogConstants";

const BlogHeader = ({ setCategory, category, darkMode, setShowDetails }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [toggleMenu, setToggleMenu] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const blogCategoryList = useSelector((state) => state.blogCategoryList);
  const { loading, error, categories } = blogCategoryList;

  const toggleNav = () => {
    setToggleMenu(!toggleMenu);
  };

  useEffect(() => {
    dispatch(listCategories());

    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, [dispatch]);

  const clickHanlder = () => {
    if (setCategory) {
      setCategory("");
    } else {
      dispatch({ type: BLOG_DETAILS_RESET });
      history.push("/blog");
    }
  };

  return (
    <nav className={`${styles.navbar} ${darkMode ? styles.darkMode : ""}`}>
      {/* {(toggleMenu || screenWidth > 600) && ( */}
      <ul className={`${styles.list} ${darkMode ? styles.darkMode : ""}`}>
        <li
          className={`${styles.items} ${darkMode ? styles.darkMode : ""}`}
          onClick={() => {
            setShowDetails(false);
            clickHanlder();
            toggleNav();
          }}
          style={{ backgroundColor: category == "" ? "#d5eef7" : "" }}
        >
          All
        </li>
        {loading ? (
          <p>Loading....</p>
        ) : error ? (
          <p>{error}</p>
        ) : (
          categories.map((cat) => (
            <li
              key={cat._id}
              className={`${styles.items} ${darkMode ? styles.darkMode : ""}`}
              onClick={() => {
                setShowDetails(false);
                setCategory(cat._id);
                toggleNav();
              }}
              style={{
                backgroundColor: category == cat._id ? "#d5eef7" : "",
              }}
            >
              {cat.name}
            </li>
          ))
        )}
      </ul>
      {/* )} */}
      <div
        className={styles["logo--small"]}
        onClick={() => history.push("/?loading=false")}
      >
        <NavLogo />
      </div>
      <button onClick={toggleNav} className={styles.btn}>
        <i className="fa fa-bars" aria-hidden="true"></i>
      </button>
    </nav>
  );
};

export default BlogHeader;
