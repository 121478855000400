import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  startProjectList,
  deleteStartProject,
} from "../state/actions/startYourProjectActions";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import TableScreen from "./components/TableScreen";
import Loader from "./components/Loader";
import Message from "./components/Message";

const StartYourProjecScreen = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const startYourProjectList = useSelector(
    (state) => state.startYourProjectList
  );
  const { projects, loading, error } = startYourProjectList;

  const startYourProjectDelete = useSelector(
    (state) => state.startYourProjectDelete
  );
  const {
    success,
    loading: loadingDelete,
    error: errorDelete,
  } = startYourProjectDelete;

  useEffect(() => {
    if (!userInfo) {
      history.push("/admin/login");
    }
    dispatch(startProjectList());
  }, [history, userInfo, dispatch, success]);

  const detailsHandler = (id) => {
    history.push(`/admin/start-your-project/${id}`);
  };

  const deleteHandler = (id) => {
    dispatch(deleteStartProject(id));
  };

  return (
    <TableScreen>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Company Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Budget</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <Loader />
            ) : error ? (
              <Message>{error}</Message>
            ) : (
              projects.map((project) => (
                <TableRow key={project._id}>
                  <TableCell>{project.full_name}</TableCell>
                  <TableCell>{project.company_name}</TableCell>
                  <TableCell>{project.email}</TableCell>
                  <TableCell>{project.phone_number}</TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      onClick={() => detailsHandler(project._id)}
                    >
                      View Details
                    </Button>
                    {errorDelete ? (
                      <Message>{errorDelete}</Message>
                    ) : loadingDelete ? (
                      <Loader />
                    ) : (
                      <Button
                        sx={{ ml: 1 }}
                        variant="outlined"
                        color="error"
                        onClick={() => deleteHandler(project._id)}
                      >
                        Delete
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </TableScreen>
  );
};

export default StartYourProjecScreen;
