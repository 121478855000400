import React from "react";
import { useHistory } from "react-router-dom";
import NavLogo from "../../../components/NavBar/NavLogo";
import { ReactComponent as ArrowDown } from "../../../svgs/down-bracket.svg";
import ModeBtn from "../../../components/NavBar/ModeBtn";
import ShowNavBtn from "../../../components/NavBar/ShowNavBtn";

const NewNavBar = ({ darkMode, toggleDarkMode, setIsMenuVisible }) => {
  const history = useHistory();
  return (
    <nav className="nav-bar welcome-nav-bar">
      <ul>
        <li className="nav-btn">
          <button onClick={() => history.push("/#home")}>HOME</button>
        </li>
        <li className="nav-btn">
          <button onClick={() => history.push("/#who-we-are")}>
            WHO WE ARE
          </button>
        </li>
        <li className="nav-btn">
          <button onClick={() => history.push("/leader")}>LEADER</button>
        </li>
        <li className="nav-btn">
          <button onClick={() => history.push("/#what-we-do")}>
            WHAT WE DO
          </button>
        </li>
        <li className="nav-btn">
          <button onClick={() => history.push("/#skills")}>SKILLS</button>
        </li>
        <li onClick={() => history.push("/#home")}>
          <NavLogo type="dark" />
        </li>
        <li className="nav-btn">
          <button onClick={() => history.push("/#portfolio")}>PORTFOLIO</button>
        </li>
        {/* dropdown */}
        <li className="nav-btn nav-drop-btn">
          <button>
            CONTACT US <ArrowDown />
          </button>
          <ul className="drop-down-menu">
            <li>
              <button
                className="drop-down-btn"
                onClick={() => history.push("/#send-a-message")}
              >
                Send a Message
              </button>
            </li>
            <li>
              <button
                className="drop-down-btn"
                onClick={() => history.push("/#send-a-message")}
              >
                Start a Project
              </button>
            </li>
          </ul>
        </li>
        {/* end dropdown */}
        <li className="nav-btn">
          <button onClick={() => history.push("/#careers")}>CAREERS</button>
        </li>
        <li className="nav-btn">
          <button onClick={() => history.push("/blog")}>Blog</button>
        </li>
        <li className="active-slider"></li>
        <div className="right-container">
          <ModeBtn darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
          <ShowNavBtn setIsMenuVisible={setIsMenuVisible} />
        </div>
      </ul>
    </nav>
  );
};

export default NewNavBar;
